import React, { useEffect, useState } from "react";
import { IconAdd, IconDelete, IconEdit, IconGear } from "../../../icons/Icons";
import EditableField from "../../input/EditableField";
import PropertyButton from "./PropertyButton";
import PropertyDetails from "./PropertyDetails";
import "./PartProperties.scss";
import PartTransformNode from "../../device-viewport/classes/PartTransforNode";
import {
  ComponentType,
  GrabComponent,
  MoveComponent,
  RotateComponent,
  RotationDirection,
  TranslationDirection,
} from "../../../interfaces/Component";
import { v4 as uuidv4 } from "uuid";
import ConfirmDialog from "../../ConfirmDialog";
import GlobalState from "../../device-viewport/classes/GlobalState";
import { GizmoType } from "../../device-viewport/classes/GizmoController";
import PropertySelection from "./PropertySelection";
import * as BABYLON from "@babylonjs/core";

interface PartPropertiesProps {
  selectedPart: PartTransformNode | null;
  updateCurrentDevice: () => void;
  canCreatePart: () => boolean;
  createPart: () => void;
  deletePart: () => void;
  existingPartNames: string[];
  setExistingPartNames: (names: string[]) => void;
}

const PartProperties: React.FC<PartPropertiesProps> = ({
  selectedPart,
  updateCurrentDevice,
  canCreatePart,
  createPart,
  deletePart,
  existingPartNames,
  setExistingPartNames,
}) => {
  const [selectedComponentId, setSelectedComponentId] = useState<string | null>(null);
  const [showPartAlreadyExists, setShowPartAlreadyExists] = useState(false);
  const [isAddingProperty, setIsAddingProperty] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onNameChange = (value: string) => {
    if (!selectedPart) {
      return;
    }

    if (existingPartNames.includes(value)) {
      setShowPartAlreadyExists(true);
      return;
    }

    const oldName = selectedPart.name;
    const index = existingPartNames.indexOf(oldName);
    if (index !== -1) {
      existingPartNames[index] = value;
      setExistingPartNames(existingPartNames);
    }

    selectedPart.name = value;
    updateCurrentDevice();
  };

  const addProperty = (type: ComponentType) => {
    if (!selectedPart) {
      return;
    }

    const newId = uuidv4().toString();

    switch (type) {
      case ComponentType.InteractionGrabComponent:
        const grabComponent: GrabComponent = {
          id: newId,
          value: {
            component_name: "",
            component_class: ComponentType.InteractionGrabComponent,
            attach_socket: "",
            attach_offset: "()",
            component_data: {},
          },
          children: [],
        };
        selectedPart.addInteractionComponent(grabComponent);
        break;
      case ComponentType.InteractionMoveComponent:
        const moveComponent: MoveComponent = {
          id: newId,
          value: {
            component_name: "",
            component_class: ComponentType.InteractionMoveComponent,
            attach_socket: "",
            attach_offset: "()",
            component_data: {
              axis_of_translation: BABYLON.Vector3.Zero(),
              translation_direction: TranslationDirection.PositiveOnly,
              is_translation_limited: false,
              max_translation: 0,
              min_translation: 0,
            },
          },
          children: [],
        };
        selectedPart.addInteractionComponent(moveComponent);
        break;
      case ComponentType.InteractionRotateComponent:
        const rotateComponent: RotateComponent = {
          id: newId,
          value: {
            component_name: "",
            component_class: ComponentType.InteractionRotateComponent,
            attach_socket: "",
            attach_offset: "()",
            component_data: {
              axis_of_rotation: BABYLON.Vector3.Zero(),
              rotation_direction: RotationDirection.ClockwiseOnly,
              is_rotation_limited: false,
              max_clockwise_rotation_angle: 0,
              max_anticlockwise_rotation_angle: 0,
            },
          },
          children: [],
        };
        selectedPart.addInteractionComponent(rotateComponent);
        break;
      default:
        throw new Error("Invalid component type");
    }

    updateCurrentDevice();
  };

  const deleteProperty = (componentId: string) => {
    if (!selectedPart) {
      return;
    }

    const component = selectedPart.getInteractionComponents().find((component) => component.id === componentId);

    if (!component) {
      return;
    }

    selectedPart.removeInteractionComponent(component);
    updateCurrentDevice();
  };

  useEffect(() => {
    GlobalState.getInstance().onAttachGizmoRequested.emit({ node: null, gizmoType: GizmoType.Position });
  }, [selectedComponentId]);

  useEffect(() => {
    setSelectedComponentId(null);
  }, [selectedPart]);

  return (
    <div className="part-context-menu">      
      {selectedPart ? (
        <div className="property-overview">

        {showDeleteConfirmation && (
          <ConfirmDialog
            title="New Project"
            message={`Are you sure you want to delete ${selectedPart.name}?`}
            onConfirm={() => {
              deletePart();
              setShowDeleteConfirmation(false);
            }}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}

          <div className="part-title">
            <IconGear fill="var(--light-primary-1-5)" />
            <EditableField multiple={false} reference="" value={selectedPart.name} onSave={onNameChange} />
            <div className="icons">
              <button onClick={() => setShowDeleteConfirmation(true)}>
                {<IconDelete fill="var(--light-primary-1-5)" />}
              </button>
            </div>
          </div>

          {!selectedComponentId ? (
            <div className="property-list">
              {selectedPart.getInteractionComponents().length > 0 ? (
                selectedPart
                  .getInteractionComponents()
                  .map((component) => (
                    <PropertyButton
                      component={component}
                      setSelectedComponentId={setSelectedComponentId}
                      deleteProperty={deleteProperty}
                      updateCurrentDevice={updateCurrentDevice}
                    />
                  ))
              ) : (
                <h3>No properties added</h3>
              )}
            </div>
          ) : (
            <PropertyDetails
              part={selectedPart}
              component={
                selectedPart.getInteractionComponents().find((component) => component.id === selectedComponentId)!
              }
              setSelectedComponentId={setSelectedComponentId}
              updateCurrentDevice={updateCurrentDevice}
            />
          )}

          {!selectedComponentId && (
            <button className="primary" onClick={() => setIsAddingProperty(true)}>
              Add Property
              <IconAdd fill="var(--light-secondary-l-white, #fff)" />
            </button>
          )}
        </div>
      ) : (
        <div className="create-part-container">
          <button onClick={createPart} className="primary">
            <IconAdd fill="var(--light-secondary-l-white, #fff)" />
          </button>
          <h3>{canCreatePart() ? "New Part from selection" : "New Part without mesh"}</h3>
        </div>
      )}

      {isAddingProperty && (
        <PropertySelection
          addProperty={(type) => {
            addProperty(type);
            setIsAddingProperty(false);
          }}
          onCancel={() => setIsAddingProperty(false)}
        />
      )}

      {showPartAlreadyExists && (
        <ConfirmDialog
          title={"Part already exists"}
          message={"A part with the same name already exists! Please choose a different name."}
          onConfirm={() => {
            setShowPartAlreadyExists(false);
          }}
        />
      )}
    </div>
  );
};

export default PartProperties;
