import React, { useEffect, useState } from "react";
import * as BABYLON from "@babylonjs/core";
import * as SCENEHELPER from "../scene-hierarchy/SceneHierarchyHelper";
import * as HIERARCHYHELPER from "../../DraggableHierarchy/helpers/DraggableHierarchyHelper";
import GlobalState from "../../device-viewport/classes/GlobalState";
import PartProperties from "../part-properties/PartProperties";
import SceneHierarchy from "../scene-hierarchy/SceneHierarchy";
import { DeviceContext } from "../../../App";
import "./DeviceEditor.scss";
import PartTransformNode from "../../device-viewport/classes/PartTransforNode";
import { GizmoType } from "../../device-viewport/classes/GizmoController";
import { Component, ComponentType } from "../../../interfaces/Component";
import SceneManager from "../../device-viewport/classes/SceneManager";

interface DeviceEditorProps {
  device: DeviceContext;
  rootTransformNode: BABYLON.TransformNode;
  reserializeDevice: () => void;
}

const DeviceEditor: React.FC<DeviceEditorProps> = ({ device, rootTransformNode, reserializeDevice }) => {
  /**
   * The scene hierarchy for the device given.
   */
  const [sceneNodes, setSceneNodes] = useState<{ [id: string]: HIERARCHYHELPER.HierarchyNode }>(
    SCENEHELPER.buildHierarchy(rootTransformNode)
  );

  /**
   * The nodes currently selected in the scene hierarchy.
   */
  const [selectedSceneNodeIds, setSelectedSceneNodeIds] = useState<string[]>([]);

  const [selectedPart, setSelectedPart] = useState<PartTransformNode | null>(null);

  const [existingPartNames, setExistingPartNames] = useState<string[]>([]);

  const createPart = () => {
    const newTransformNode = SCENEHELPER.createPart(selectedSceneNodeIds, sceneNodes);
    const newSceneNodes = SCENEHELPER.buildHierarchy(rootTransformNode);
    setSceneNodes(newSceneNodes);
    updateCurrentDevice();

    if (SCENEHELPER.hasMesh(newTransformNode)) {
      GlobalState.getInstance().onUIPartsSelected.emit(
        selectedSceneNodeIds.map((id) => newSceneNodes[id].data.transformNode)
      );
    }

    // set the selected hierarchy node to include the new part node
    setSelectedPart(newTransformNode);
    setSelectedSceneNodeIds([...selectedSceneNodeIds, newTransformNode.id]);
    setExistingPartNames([...existingPartNames, newTransformNode.name]);
  };

  const deletePart = () => {
    if (!selectedPart) {
      return;
    }

    const partParent = selectedPart.parent as BABYLON.TransformNode;
    if (!partParent) {
      return;
    }

    const children = selectedPart.getChildren();

    // add the children to the parent
    for (let child of children) {
      SceneManager.changePartParent(child as BABYLON.TransformNode, partParent);
    }

    // remove the part from the parent
    SceneManager.deleteTransformNode(selectedPart);

    const newSceneNodes = SCENEHELPER.buildHierarchy(rootTransformNode);
    setSceneNodes(newSceneNodes);
    updateCurrentDevice();

    setExistingPartNames(existingPartNames.filter((name) => name !== selectedPart.name));
    setSelectedSceneNodeIds([]);
    setSelectedPart(null);
  };

  const updateCurrentDevice = () => {
    reserializeDevice();
  };

  const onPartsSelectedinUI = (nodeIds: string[]) => {
    setSelectedPart(null);
    setSelectedSceneNodeIds(nodeIds);

    const partNodes = nodeIds
      .map((id) => sceneNodes[id])
      .filter((node) => (node.data as SCENEHELPER.SceneNodeData).transformNode instanceof PartTransformNode);

    // the can only be one part selected at a time
    if (partNodes.length !== 1) {
      return;
    }

    // check if all other nodes are children of that one part
    const partTransformNodeNode = partNodes[0].data.transformNode as PartTransformNode;
    const childrenIds = partTransformNodeNode.getChildren().map((child) => child.id);
    let isValidSelection = true;
    for (let nodeId of nodeIds) {
      if (nodeId === partTransformNodeNode.id) {
        continue;
      }

      if (!childrenIds.includes(nodeId)) {
        isValidSelection = false;
        return;
      }
    }

    if (isValidSelection) {
      setSelectedPart(partTransformNodeNode);
    }
  };

  const canCreatePart = (): boolean => {
    if (selectedSceneNodeIds.length === 0) {
      return false;
    }

    if (selectedSceneNodeIds.length === 1 && selectedSceneNodeIds[0] === "__root_") {
      return false;
    }

    for (let nodeId of selectedSceneNodeIds) {
      const node = sceneNodes[nodeId];
      if (!node) {
        continue;
      }

      // if any of the selected nodes is a part then we cannot create a part
      const transformNode = (node.data as SCENEHELPER.SceneNodeData).transformNode;
      if (transformNode instanceof PartTransformNode) {
        return false;
      }

      // if the node is a child of a part then we cannot create a part
      const parent = transformNode.parent;
      if (parent instanceof PartTransformNode) {
        return false;
      }
    }

    return true;
  };

  // ====================================================================================================

  /**
   * Listening to the 3D viewport having a mesh loaded.
   */
  useEffect(() => {
    const globalState = GlobalState.getInstance();

    const onTransformNodesSelectedIn3D = (transformNodes: BABYLON.TransformNode[]) => {
      // check if the transformNodes parent is a part
      for (let transformNode of transformNodes) {
        const parent = transformNode.parent;
        if (!parent) {
          continue;
        }

        // set the selected part to the first part selected
        if (parent instanceof PartTransformNode) {
          setSelectedPart(parent);
          return;
        }
      }
    };
    globalState.onPartSelected.on("DeiceEditorOnPartSelected", onTransformNodesSelectedIn3D);

    return () => {
      globalState.onPartSelected.off("DeiceEditorOnPartSelected");
    };
  }, [sceneNodes, device]);

  useEffect(() => {
    GlobalState.getInstance().onAttachGizmoRequested.emit({ node: null, gizmoType: GizmoType.Position });
  }, [selectedPart]);

  // ====================================================================================================

  return (
    <div className="device-editor">
      <PartProperties
        selectedPart={selectedPart}
        updateCurrentDevice={updateCurrentDevice}
        canCreatePart={canCreatePart}
        createPart={createPart}
        deletePart={deletePart}
        existingPartNames={existingPartNames}
        setExistingPartNames={setExistingPartNames}
      />

      <SceneHierarchy
        isPlaceholder={device.isPlaceholder}
        sceneNodes={sceneNodes}
        setSceneNodes={setSceneNodes}
        selectedSceneNodeIds={selectedSceneNodeIds}
        setSelectedSceneNodeIds={onPartsSelectedinUI}
        updateCurrentDevice={updateCurrentDevice}
      />
    </div>
  );
};

export default DeviceEditor;
