import { HierarchyNode } from "../../DraggableHierarchy/helpers/DraggableHierarchyHelper";
import * as BABYLON from "@babylonjs/core";
import * as HIERARCHYHELPER from "../../DraggableHierarchy/helpers/DraggableHierarchyHelper";
import SceneManager from "../../device-viewport/classes/SceneManager";
import { v4 as uuidv4 } from "uuid";
import PartTransformNode from "../../device-viewport/classes/PartTransforNode";

/**
 * Checks if the given transform node has a mesh.
 * @param transformNode The transform node to check.
 * @returns True if the transform node has a mesh, false otherwise.
 */
export const hasMesh = (transformNode: BABYLON.TransformNode) => {
  return transformNode instanceof BABYLON.AbstractMesh && transformNode.getTotalVertices() > 0;
};

/**
 * Checks if the given transform node is valid. Branches without any meshes down to the leaves are considered invalid.
 * @param transformNode The transform node to check.
 * @returns True if the transform node is valid, false otherwise.
 */
const isValidNode = (transformNode: BABYLON.TransformNode): boolean => {
  // if this node has a mesh, it is valid
  if (hasMesh(transformNode)) {
    return true;
  }

  // if any of the children has a mesh, this node is valid
  let isValid = false;
  transformNode.getChildTransformNodes(true).forEach((child) => {
    isValid ||= isValidNode(child);
  });

  return isValid;
};

export enum SceneNodeType {
  Empty,
  Mesh,
  Part,
}

export interface SceneNodeData {
  transformNode: BABYLON.TransformNode;
  type: SceneNodeType;
}

/**
 * Adds the given transform node to the scene hierarchy in a new node to a given parent.
 * @param parent The parent node to add the transform node to.
 * @param transformNode The transform node to add.
 * @param sceneNodes The scene hierarchy to add the transform node to.
 * @returns The updated scene hierarchy.
 */
const addNodesForTransformNode = (
  parent: HierarchyNode | null,
  transformNode: BABYLON.TransformNode,
  sceneNodes: { [id: string]: HierarchyNode } | null = null
): { [id: string]: HierarchyNode } => {
  if (!sceneNodes) {
    sceneNodes = {};
  }

  const isPlaceholder = transformNode instanceof PartTransformNode
    && transformNode.parent
    && transformNode.parent.id === "__root__";

  // ignore empties without meshes (unless its an empty part)
  if (!isValidNode(transformNode) && !isPlaceholder) {
    SceneManager.deleteTransformNode(transformNode);
    return sceneNodes;
  }

  // create a new hierarchy node for this transform node
  const newNode = HIERARCHYHELPER.createNode();
  newNode.id = transformNode.id;
  const data: SceneNodeData = {
    transformNode: transformNode,
    type:
      transformNode instanceof PartTransformNode
        ? SceneNodeType.Part
        : hasMesh(transformNode)
        ? SceneNodeType.Mesh
        : SceneNodeType.Empty,
  };
  newNode.data = data;
  newNode.isCollapsed = transformNode instanceof PartTransformNode;

  // add the new node to the parent or create a new root node
  sceneNodes = HIERARCHYHELPER.addNode(parent ? parent.id : null, newNode, sceneNodes);

  // add the children
  transformNode.getChildTransformNodes(true).forEach((child) => {
    sceneNodes = addNodesForTransformNode(newNode, child, sceneNodes);
  });

  return sceneNodes;
};

/**
 * Adds the given mesh to the scene hierarchy in a new node to a given parent.
 * @param sceneNodes The scene hierarchy to add the mesh to.
 * @param transformNode The transformNode to add.
 * @returns The updated scene hierarchy.
 */
export function buildHierarchy(transformNode: BABYLON.TransformNode): { [id: string]: HierarchyNode } {
  return addNodesForTransformNode(null, transformNode);
}

export const findNewUniqueName = (prefix: string, existing: string[]): string => {
  let i = 1;
  while (true) {
    const name = `${prefix} ${i}`;
    let isUnique = true;
    existing.forEach((existingName) => {
      if (existingName === name) {
        isUnique = false;
      }
    });

    if (isUnique) {
      return name;
    }

    i++;
  }
};

const getTransformNodesWithMeshes = (
  selectedIds: string[],
  sceneNodes: { [id: string]: HierarchyNode }
): BABYLON.TransformNode[] => {
  const result: BABYLON.TransformNode[] = [];
  selectedIds.forEach((id) => {
    const node = sceneNodes[id];
    if (!node) {
      return;
    }

    const transformNode = (node.data as SceneNodeData).transformNode;

    if (hasMesh(transformNode)) {
      result.push(transformNode);
    }
  });

  return result;
};

export function createPart(selectedIds: string[], sceneNodes: { [id: string]: HierarchyNode }): PartTransformNode {
  const partChildren = getTransformNodesWithMeshes(selectedIds, sceneNodes);

  let partParent: BABYLON.TransformNode | null = null;
  let partPosition: BABYLON.Vector3 | null = null;

  if (partChildren.length === 1) {
    // get parent of the selected node as the parent for the new part
    // use the selected node's position as the position for the new part

    const onlyNode = partChildren[0];

    partPosition = onlyNode.getAbsolutePosition();
    partParent = onlyNode.parent as BABYLON.TransformNode;
  } else if (partChildren.length === 0) {
    // attach to root
    partParent = sceneNodes[Object.keys(sceneNodes)[0]].data.transformNode as BABYLON.TransformNode;
    partPosition = partParent.getAbsolutePosition();
  } else {
    // check the depth for all selected nodes
    // if they are all the same, use their parent as the parent for the new part and use their bounding box center as the position for the new part

    // if they are not all the same, find the node that is closest to the root in the hierarchy, use its parent as the parts parent
    // and use its position as the position for the new part

    const depths = partChildren.map((partChild) => HIERARCHYHELPER.getNodeDepth(sceneNodes[partChild.id], sceneNodes));

    // check if all depths are the same
    const allHaveSameDepth = depths.every((depth) => depth === depths[0]);

    if (allHaveSameDepth) {
      partPosition = partChildren[0].getAbsolutePosition();
      partParent = partChildren[0].parent as BABYLON.TransformNode;
    } else {
      let bestNode: BABYLON.TransformNode | null = null;
      let bestDepth = Number.MAX_SAFE_INTEGER;
      for (let i = 0; i < depths.length; i++) {
        const currentDepth = depths[i];
        const currentChild = partChildren[i];

        if (currentDepth < bestDepth) {
          bestDepth = currentDepth;
          bestNode = currentChild;
        }
      }

      if (!bestNode) {
        throw "No best node found.";
      }

      partPosition = bestNode.getAbsolutePosition();
      partParent = bestNode.parent as BABYLON.TransformNode;
    }
  }

  if (!partParent || !partPosition) {
    throw "No parent or position found.";
  }

  // create the new part
  const newPart = SceneManager.createTransformNode(
    partParent,
    findNewUniqueName(
      "New Part",
      Object.values(sceneNodes).map((node) => node.data.transformNode.name)
    )
  );

  newPart.setAbsolutePosition(partPosition);
  newPart.id = uuidv4();

  // move the meshes to the children of the new part
  partChildren.forEach((partChild) => {
    SceneManager.changePartParent(partChild, newPart);
  });

  return newPart;
}
