import React, { useState } from "react";
import { HierarchyNode } from "../DraggableHierarchy/helpers/DraggableHierarchyHelper";
import { IconAdd, IconDelete } from "../../icons/Icons";
import "./StorylineHierarchyNode.scss";
import { NodeType, StorylineData } from "./StorylineEditor/storyline-builder-steps/StorylineDataProperties";
import ConfirmDialog from "../ConfirmDialog";

interface StorylineHierarchyNodeProps {
  node: HierarchyNode;
  selectedNodeIds: string[];
  hoveredNodeId: string | null;
  addChild: (node: HierarchyNode) => void;
  deleteNode: (node: HierarchyNode) => void;
}

const StorylineHierarchyNode: React.FC<StorylineHierarchyNodeProps> = ({
  node,
  selectedNodeIds,
  hoveredNodeId,
  addChild,
  deleteNode,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  /**
   * Function to determine if a child can be added to a node.
   * @param node The node to check if a child can be added to.
   * @returns True if a child can be added to the node. False otherwise.
   */
  const canAddChild = (node: HierarchyNode): boolean => {
    return !node ? false : (node.data as StorylineData).type !== NodeType.Interaction;
  };

  return (
    <div
      className="storyline-node-content"
      style={{
        color: selectedNodeIds.indexOf(node.id) !== -1 ? "var(--light-primary-1-5)" : "var(--light-text-light-theme-0)",
      }}
    >
      {showDeleteConfirmation && (
        <ConfirmDialog
          title="Delete Storyline Node"
          message={`Are you sure you want to delete ${(node.data as StorylineData).title}?`}
          onConfirm={() => {
            deleteNode(node)
            setShowDeleteConfirmation(false);
          }}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}

      <div className="content-title">
        {(node.data as StorylineData).type === NodeType.Root ? "Experience Storyline" : (node.data as StorylineData).title}
      </div>

      <div className="node-buttons">
        {hoveredNodeId === node.id && (node.data as StorylineData).type !== NodeType.Root ? (
          <button
            onClick={(event) => {
              setShowDeleteConfirmation(true);
              event.stopPropagation();
            }}
          >
            <IconDelete fill="var(--light-text-light-theme-0)" />
          </button>
        ) : (
          <div style={{ width: "50px" }} />
        )}

        {hoveredNodeId === node.id && canAddChild(node) && (
          <button
            onClick={(event) => {
              addChild(node);
              event.stopPropagation();
            }}
          >
            <IconAdd fill="var(--light-text-light-theme-0)" />
          </button>
        )}
      </div>
    </div>
  );
};

export default StorylineHierarchyNode;
