import React, { useState, useEffect, useRef } from "react";
import "./SceneEditorPage.scss";
import * as STORAGE from "../helpers/StorageHelper";
import { IconAdd, IconBox, IconDownload, IconEdit, IconMesh } from "../icons/Icons";
import DeviceViewport from "../components/device-viewport";
import GlobalState from "../components/device-viewport/classes/GlobalState";
import DeviceEditor from "../components/part-editor/device-editor/DeviceEditor";
import { DeviceContext } from "../App";
import * as BABYLON from "@babylonjs/core";
import { v4 as uuidv4 } from "uuid";
import { Device } from "../interfaces/Device";
import SceneManager from "../components/device-viewport/classes/SceneManager";
import * as SCENEHELPER from "../components/part-editor/scene-hierarchy/SceneHierarchyHelper";
import DeviceProperties from "../components/part-editor/DeviceProperties";
import { ComponentType } from "../interfaces/Component";

interface SceneEditorPageProps {
  devices: { [id: string]: DeviceContext };
  addDevice: (id: string, device: DeviceContext) => void;
  updateDevice: (id: string, device: DeviceContext) => void;
  removeDevice: (id: string) => void;
}

const SceneEditorPage: React.FC<SceneEditorPageProps> = ({ devices, addDevice, updateDevice, removeDevice }) => {
  const [deviceUrl, setDeviceUrl] = useState<string | null>(() => {
    const savedActiveDeviceId = STORAGE.loadFromLocalStorage("active_device_id");

    if (savedActiveDeviceId) {
      if (!devices[savedActiveDeviceId]) {
        return null;
      }

      return devices[savedActiveDeviceId].fileUrl;
    }

    return null;
  });

  const [newDeviceLoaded, setNewDeviceLoaded] = useState<boolean>(false);

  const [serializedDevice, setSerializedDevice] = useState<Device | null>(() => {
    const savedActiveDeviceId = STORAGE.loadFromLocalStorage("active_device_id");

    if (savedActiveDeviceId) {
      if (!devices[savedActiveDeviceId]) {
        return null;
      }

      return devices[savedActiveDeviceId].device;
    }

    return null;
  });

  const [activeDeviceId, setActiveDeviceId] = useState<string | null>(() => {
    const savedActiveDeviceId = STORAGE.loadFromLocalStorage("active_device_id");
    return savedActiveDeviceId ? savedActiveDeviceId : null;
  });

  const [editedDeviceId, setEditedDeviceId] = useState<string | null>(null);
  const [rootTransformNode, setRootTransformNode] = useState<BABYLON.TransformNode | null>(null);

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    const url = URL.createObjectURL(file);
    if (!url) {
      return;
    }

    setDeviceUrl(url);
    setNewDeviceLoaded(true);
    event.target.value = "";
  };

  const createPlaceholder = () => {
    setDeviceUrl(process.env.PUBLIC_URL + "placeholder.glb");
    setNewDeviceLoaded(true);

    const emptyDevice: Device = {
      name: "Placeholder",
      id: "",
      has_custom_root: false,
      parent_class: "",
      class_data: {},
      component_hierachy: {
        root: {
          id: "",
          value: {
            component_name: "DefaultSceneRoot",
            component_class: ComponentType.DefaultSceneRoot,
            attach_socket: "",
            attach_offset: "()",
            component_data: {}
          },
          children: []
        }
      },
      actor_components: []
    };
    setSerializedDevice(emptyDevice);
  }

  const switchActiveDevice = (deviceId: string | null) => {
    if (deviceId === activeDeviceId) {
      return;
    }

    setDeviceUrl(deviceId ? devices[deviceId].fileUrl : null);
    setSerializedDevice(deviceId ? devices[deviceId].device : null);
    setActiveDeviceId(deviceId);
    setRootTransformNode(null);
  };

  const reserializeDevice = () => {
    if (!rootTransformNode) {
      return;
    }

    const deviceId = activeDeviceId;
    if (!deviceId) {
      return;
    }

    const device = SceneManager.serializeAs(deviceId, devices[deviceId].name, rootTransformNode);
    updateDevice(deviceId, {
      ...devices[deviceId],
      device: device,
    });
  };

  useEffect(() => {
    if (!deviceUrl) {
      return;
    }

    const globalState = GlobalState.getInstance();
    const onMeshLoaded = (transformNode: BABYLON.TransformNode) => {

      // if the device does not exist yet, create it
      if (newDeviceLoaded) {
        const deviceId = uuidv4();
        
        const isPlaceholder = deviceUrl === "placeholder.glb";
        const existingDeviceNames = Object.values(devices).map((device) => device.name);
        const uniqueName = SCENEHELPER.findNewUniqueName(isPlaceholder ? "Placeholder" : "New Device", existingDeviceNames);

        const device = SceneManager.serializeAs(deviceId, uniqueName, transformNode);

        addDevice(deviceId, {
          name: uniqueName,
          fileUrl: deviceUrl,
          fileName: deviceUrl.split("/").pop() as string, // TODO: nope! use source file name
          device: device,
          isPlaceholder: isPlaceholder,
        });
        
        setActiveDeviceId(deviceId);
      }

      setRootTransformNode(transformNode);
      setNewDeviceLoaded(false);
    };

    globalState.onMeshLoaded.on("SceneEditorPageOnMeshLoaded", onMeshLoaded);

    return () => {
      globalState.onMeshLoaded.off("SceneEditorPageOnMeshLoaded");
    };
  }, [deviceUrl, newDeviceLoaded]);

  useEffect(() => {
    STORAGE.saveToLocalStorage("active_device_id", activeDeviceId);
  }, [activeDeviceId]);

  /**
   * Used to open the file upload dialog when the import button is clicked.
   */
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const onUploadClick = () => {
    if (!fileInputRef.current) {
      return;
    }

    fileInputRef.current.click();
  };

  // ====================================================================================================

  return (
    <div className="scene-editor-grid">
      <div className="sidepanel">
        <h3>Scene Hierarchy</h3>

        <div className="device-list">
          {Object.keys(devices).map((deviceId) => {
            const device = devices[deviceId];
            return (
              <div className="device-list-entry" key={`device-list-entry${deviceId}`}>
                <button
                  key={`device-list-button-${deviceId}`}
                  className="secondary"
                  onClick={(event) => {
                    switchActiveDevice(deviceId);
                    event.stopPropagation();
                  }}
                  style={
                    deviceId === activeDeviceId
                      ? {
                          backgroundColor: "var(--light-primary-1-5)",
                          color: "var(--light-secondary-l-white, #fff)",
                          boxShadow: "none",
                        }
                      : {}
                  }
                >
                  {device.isPlaceholder ? (
                    <IconMesh
                      key={`device-list-icon-${deviceId}`}
                      fill={
                        deviceId === activeDeviceId
                          ? "var(--light-secondary-l-white, #fff)"
                          : "var(--light-primary-1-5)"
                      }
                    />
                  ) : (
                    <IconBox
                      key={`device-list-icon-${deviceId}`}
                      fill={
                        deviceId === activeDeviceId
                          ? "var(--light-secondary-l-white, #fff)"
                          : "var(--light-primary-1-5)"
                      }
                    />
                  )}
                  <div className="device-name">
                    {`${device.name}${device.isPlaceholder ? " (Placeholder)" : ""}`}
                  </div>

                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditedDeviceId(deviceId);
                    }}
                  >
                    <IconEdit
                      fill={
                        deviceId === activeDeviceId
                          ? "var(--light-secondary-l-white, #fff)"
                          : "var(--light-primary-1-5)"
                      }
                    />
                  </button>
                </button>
                {deviceId === activeDeviceId && rootTransformNode && (
                  <DeviceEditor
                    key={`device-editor-${activeDeviceId}`}
                    device={devices[activeDeviceId]}
                    rootTransformNode={rootTransformNode}
                    reserializeDevice={reserializeDevice}
                  />
                )}
              </div>
            );
          })}
        </div>

        <div className="upload-button">
          <button className="primary" onClick={onUploadClick}>
            Import 3D Model
            <IconDownload fill="var(--light-secondary-l-white, #fff)" />
          </button>
        </div>

        <div className="upload-button">
          <button className="primary" onClick={createPlaceholder}>
            <IconAdd fill="var(--light-secondary-l-white, #fff)" />
            New Placeholder Device
          </button>
        </div>

        <input ref={fileInputRef} type="file" accept=".glb" onChange={onUpload} style={{ display: "none" }} />
      </div>

      <DeviceViewport
        meshURL={deviceUrl ? deviceUrl : undefined}
        deviceData={serializedDevice ? serializedDevice : undefined}
      />

      {editedDeviceId && (
        <DeviceProperties
          deviceContext={devices[editedDeviceId]}
          removeDevice={removeDevice}
          switchActiveDevice={switchActiveDevice}
          closeProperties={() => setEditedDeviceId(null)}
          updateDevice={updateDevice}
        />
      )}
    </div>
  );
};

export default SceneEditorPage;
