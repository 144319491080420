import React from "react";
import "./StorylineEditor.scss";
import { HierarchyNode, findPosition } from "../../DraggableHierarchy/helpers/DraggableHierarchyHelper";
import { createStorylineShortName, NodeType, StorylineData } from "./storyline-builder-steps/StorylineDataProperties";
import ModuleProperties from "./storyline-builder-steps/ModuleProperties";
import SectionProperties from "./storyline-builder-steps/SectionProperties";
import StepProperties from "./storyline-builder-steps/StepProperties";
import InteractionProperties, { InteractionData } from "./storyline-builder-steps/InteractionProperties";
import { DeviceContext } from "../../../App";

interface StorylineEditorProps {
  node: HierarchyNode;
  nodes: { [id: string]: HierarchyNode };
  devices: { [id: string]: DeviceContext };
  updateNode: (updatedNode: HierarchyNode) => void;
}

const StorylineEditor: React.FC<StorylineEditorProps> = ({ node, nodes, devices, updateNode }) => {
  /**
   *
   * @param property
   * @param newValue
   */
  const onSave = (property: string, newValue: any) => {
    // if the interaction type is changed, clear out all other properties
    if (property === "interactionType") {
      const data = node.data as InteractionData;
      const newData: InteractionData = {
        interactionType: newValue,
        children: data.children,
        title: data.title,
        shortName: createStorylineShortName(data.title),
        type: data.type,
        properties: {},
      };

      node.data = newData;
      return;
    }

    // if title is changed, make sure its not already in use
    if (property === "title") {
      const title = newValue as string;
      
      // dont allow special characters except "-" and "_"
      if (!/^[a-zA-Z0-9-_() ]*$/.test(title)) {
        alert("Special characters (except - and _) are not allowed in node titles.");
        return;
      }
      
      const existingNode = Object.values(nodes).find((n) => {
        const data = n.data as StorylineData;
        
        // the type of the node must match the type of the node being edited
        if (data.type !== (node.data as StorylineData).type) {
          return false;
        }

        // the parent of the node must match the parent of the node being edited
        if (n.parentId !== node.parentId) {
          return false;
        }

        // the title must match the title of the node being edited
        return data.title.replaceAll(' ', '_') === title.replaceAll(' ', '_');
      });
      
      if (existingNode && existingNode.id !== node.id) {
        alert(`A ${(node.data as StorylineData).type} with the title "${title}" already exists.`);
        return;
      }

      // check if the shortName is the same as the current title
      // aka its not been manually changed
      const shortName = createStorylineShortName(node.data.title);
      if (shortName === title) {
        // update shortName to match the new title
        node.data.shortName = shortName;
      }
    }

    node.data[property] = newValue;
    updateNode(node);
  };

  const nodePosition = findPosition(node, Object.values(nodes));

  // TODO: this is a hack! the entire component should not even be referenced anymore.
  // when deleting a node, this component is still rendered and throws an error
  if (!node) {
    return null;
  }

  // ====================================================================================================

  const nodeType = (node.data as StorylineData).type;

  return (
    <div className="node-editor">
      {nodeType !== NodeType.Root && (
        <div className={"node"}>
          <h3>{(node.data as StorylineData).type + " " + nodePosition}</h3>

          {nodeType === NodeType.Module && <ModuleProperties node={node} onSave={onSave} />}
          {nodeType === NodeType.Section && <SectionProperties node={node} onSave={onSave} />}
          {nodeType === NodeType.Step && <StepProperties node={node} onSave={onSave} />}
          {nodeType === NodeType.Interaction && <InteractionProperties node={node} onSave={onSave} devices={devices} />}
        </div>
      )}

      <div className={"node-editor-children"}>
        {node.childrenIds.map((childId, index) => {
          const childNode = nodes[childId];
          return <StorylineEditor key={childId} node={childNode} nodes={nodes} devices={devices} updateNode={updateNode} />;
        })}
      </div>
    </div>
  );
};

export default StorylineEditor;
