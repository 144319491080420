export enum NodeType {
  Root = "Root",
  Module = "Module",
  Section = "Section",
  Step = "Step",
  Interaction = "Interaction",
}

export interface StorylineData {
  title: string;
  shortName: string;
  type: NodeType;
}

export const createStorylineShortName = (name: string) => {
  return name.replace(/ /g, "");
};