import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import {
  IconRWO,
  IconHamburgerMenu,
  IconHandyman,
  IconDownload,
  IconSave,
  IconOpen,
  IconAdd,
  IconLogout,
} from "../icons/Icons";
import "./HeaderContextMenu.scss";
import * as STORAGE from "../helpers/StorageHelper";
import * as IMPORTEXPORT from "../helpers/ImportExport/ImportExportHelper";
import ConfirmDialog from "./ConfirmDialog";
import ProjectConfiguration from "./ProjectConfiguration";
import { getAuth, signOut } from "firebase/auth";

interface HeaderContextMenuProps {}

const HeaderContextMenu: React.FC<HeaderContextMenuProps> = ({}) => {
  /**
   * The name of the project as displayed in the header.
   */
  const [projectName, setProjectName] = useState<string>(() => {
    return STORAGE.loadFromLocalStorage("projectName") || "Untitled Project";
  });

  /**
   * Boolean when the hamburger menu is open. Used to display the menu.
   */
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  /**
   * Boolean to reset the entire project. Used to display a confirmation dialog.
   */
  const [isResetting, setIsResetting] = useState<boolean>(false);

  /**
   * Boolean when the user has opened the configuration panel. Used to display the configuration panel.
   */
  const [isConfigurating, setConfigurating] = useState<boolean>(false);
 
  /**
   * Boolean when the user has tried to expoert the project with missing information. Used to display the warning panel.
   */
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  // ====================================================================================================

  /**
   * Handle when the project name changes.
   * @param event the name changed event
   */
  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
    STORAGE.saveToLocalStorage("projectName", event.target.value);
  };

  /**
   * Handle when the user clicks the new project button.
   */
  const onNewProjectClick = () => {
    setIsResetting(true);
    setIsMenuOpen(false);
  };

  /**
   * Handle when the user confirms the reset.
   */
  const onResettingConfirm = () => {
    // reset localStorage and reload page
    localStorage.clear();
    window.location.reload();
    setIsResetting(false);
    setIsMenuOpen(false);
  };

  /**
   * Handle when the user cancels the reset.
   */
  const onResettingCancel = () => {
    setIsResetting(false);
    setIsMenuOpen(false);
  };

  const onWarningConfirmed = () => {
    STORAGE.saveToLocalStorage("company", "realworld one")
    onExportProjectClick()
    setIsWarningOpen(false)
  }

  const onWarningCanceled = () => {
    setIsWarningOpen(false)
  }

  /**
   * Handle when the user clicks the open project button.
   */
  const onOpenProjectClick = () => {
    if (!fileInputRef.current) {
      return;
    }

    fileInputRef.current.click();
    setIsMenuOpen(false);
  };

  /**
   * Handle when the user clicks the save project button.
   */
  const onSaveProjectClick = () => {
    IMPORTEXPORT.saveProject();
    setIsMenuOpen(false);
  };

  /**
   * Handle when the user clicks the configure project button.
   */
  const onConfigureProjectClick = () => {
    setConfigurating(true);
    setIsMenuOpen(false);
  };

  /**
   * Handle when the user clicks the export project button.
   */
  const onExportProjectClick = () => {
    if (!STORAGE.loadFromLocalStorage("company")) {
      setIsWarningOpen(true)
      return;
    }
    IMPORTEXPORT.exportProject();
    setIsMenuOpen(false);
  };

  const onSignOut = () => {
    const auth = getAuth();
    signOut(auth);
  };

  // ====================================================================================================

  /**
   * after the user selects a file, load it
   */
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    IMPORTEXPORT.openProject(file);
    setIsMenuOpen(false);
  };

  /**
   * This effect handles the menu closing when the user clicks
   * anywhere on screen when the menu is open
   */
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const onClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    // TODO: what is this?
    if (isMenuOpen) {
      document.addEventListener("mousedown", onClickOutside);
    } else {
      document.removeEventListener("mousedown", onClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [isMenuOpen]);

  // ====================================================================================================

  return (
    <>
      {isResetting && (
        <ConfirmDialog
          title="New Project"
          message={"Are you sure you want to create a new project? All unsaved changes will be lost."}
          onConfirm={onResettingConfirm}
          onCancel={onResettingCancel}
        />
      )}

      {isConfigurating && (
        <ProjectConfiguration
          onConfirm={() => {
            setConfigurating(false);
          }}
        />
      )}

      {isWarningOpen && (
        <ConfirmDialog
          title="Missing data for export"
          message={"The associated company has not been set. Do you want to set it to 'realworld one'?"}
          onConfirm={onWarningConfirmed}
          onCancel={onWarningCanceled}
        />
      )}

      <div className="hamburger-menu" ref={menuRef}>
        <div className="horizontal-container">
          <IconRWO />

          <div className="v-line" />

          <input type="text" value={projectName} onChange={onNameChange} />

          <div className="v-line" />

          <button className="menu-toggle-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <span className={`menu-icon${isMenuOpen ? "-open" : ""}`} />
            <IconHamburgerMenu fill="var(--light-primary-1-5)" />
          </button>
        </div>

        {isMenuOpen && (
          <div className="button-container">
            <button onClick={onNewProjectClick}>
              <IconAdd fill="var(--light-primary-1-5)" />
              New Project
            </button>

            <button onClick={onOpenProjectClick}>
              <IconOpen fill="var(--light-primary-1-5)" />
              Open Project
            </button>

            <button onClick={onSaveProjectClick}>
              <IconSave fill="var(--light-primary-1-5)" />
              Save
            </button>

            <button onClick={onConfigureProjectClick}>
              <IconHandyman fill="var(--light-primary-1-5)" />
              Project Configuration
            </button>

            <button onClick={onExportProjectClick}>
              <IconDownload fill="var(--light-primary-1-5)" />
              Export
            </button>

            <button onClick={onSignOut}>
              <IconLogout fill="var(--light-primary-1-5)" />
              Log Out
            </button>
          </div>
        )}

        {/* Add an invisible file input for selecting folders */}
        <input
          type="file"
          ref={fileInputRef}
          accept=".ctproj"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
    </>
  );
};

export default HeaderContextMenu;
