import React from "react";
import "./PropertyButton.scss";
import { ComponentType, InteractionComponent } from "../../../interfaces/Component";
import { IconDelete, IconHand, IconMove, IconRotate } from "../../../icons/Icons";

interface PropertyButtonProps {
  component: InteractionComponent;
  setSelectedComponentId: (componentId: string | null) => void;
  deleteProperty: (componentId: string) => void;
  updateCurrentDevice: () => void;
}

const PropertyButton: React.FC<PropertyButtonProps> = ({
  component,
  setSelectedComponentId,
  deleteProperty,
  updateCurrentDevice,
}) => {
  const label = () => {
    switch (component.value.component_class) {
      case ComponentType.InteractionGrabComponent:
        return "Grab-able";
      case ComponentType.InteractionMoveComponent:
        return "Move-able";
      case ComponentType.InteractionRotateComponent:
        return "Rotate-able";
      default:
        return "";
    }
  };

  return (
    <div className="property-button-container">
      <div className="property-icon">
        {component.value.component_class === ComponentType.InteractionGrabComponent && (
          <IconHand fill="var(--light-primary-1-5)" />
        )}
        {component.value.component_class === ComponentType.InteractionMoveComponent && (
          <IconMove fill="var(--light-primary-1-5)" />
        )}
        {component.value.component_class === ComponentType.InteractionRotateComponent && (
          <IconRotate fill="var(--light-primary-1-5)" />
        )}
      </div>

      <button className="open" onClick={() => setSelectedComponentId(component.id)}>
        <h3>{label()}</h3>
      </button>

      <button className="delete" onClick={() => deleteProperty(component.id)}>
        {<IconDelete fill="var(--light-primary-1-5)" />}
      </button>
    </div>
  );
};

export default PropertyButton;
