import { EventEmitter } from "../../babylon/classes/EventEmiter";
import * as BABYLON from "@babylonjs/core";
import GizmoController, { GizmoType } from "./GizmoController";
import { Device } from "../../../interfaces/Device";
import { VisualizerData } from "./AxisVisualizer";

/**
 * The `GlobalState` class provides a singleton instance to manage and distribute global events and states across the application.
 * @author IgorAPM
 * @example
 * const globalState = GlobalState.getInstance();
 * globalState.onPartSelected.on((part) => {
 *   console.log("Part selected:", part.name);
 * });
 *
 * // Somewhere else in your application
 * const anotherInstance = GlobalState.getInstance();
 * anotherInstance.onPartSelected.emit(myTransformNode);
 * // This will log: "Part selected:", myTransformNode.name
 */
export default class GlobalState {
	/**
	 * EventEmitter for part selection on the viewport. When a part is selected, this event is emitted.
	 */
	public onPartSelected = new EventEmitter<BABYLON.TransformNode[]>();
	/**
	 * EventEmitter triggered when a mesh is loaded
	 */
	public onMeshLoaded = new EventEmitter<BABYLON.TransformNode>();

    /**
	 * EventEmitter for part selection on the UI. When a part is selected, this event is emitted.
	 */
    public onUIPartsSelected = new EventEmitter<BABYLON.TransformNode[]>();

    public onAttachGizmoRequested = new EventEmitter<{node: BABYLON.Nullable<BABYLON.TransformNode> , gizmoType: GizmoType}>();

    public onGizmoUpdate = new EventEmitter();

    public onSceneSerializeRequest = new EventEmitter<{id: string, name: string}>();

    public onSceneSerialized = new EventEmitter<Device>();

    public onMoveRotatePropertyChanged = new EventEmitter<{data: VisualizerData, isRot: boolean }>();

    public onViewportResized =  new EventEmitter();
	
	public onProjectLoaded = new EventEmitter();

	private static instance: GlobalState;

    public highlightLayer!: BABYLON.HighlightLayer;

	/**
	 * Private constructor to prevent creating multiple instances.
	 * @private
	 */
	private constructor() {
		console.log("GlobalState instance has been initialized");
	}

	/**
	 * Get the singleton instance of the GlobalState class.
	 * If the instance does not exist, it will be created.
	 * @returns {GlobalState} The singleton instance.
	 * @static
	 */
    
	static getInstance(): GlobalState {
		if (!GlobalState.instance) {
			GlobalState.instance = new GlobalState();
		}
		return GlobalState.instance;
	}
}
