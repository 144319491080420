import Entity from "../../babylon/classes/Entity";
import * as BABYLON from "@babylonjs/core";
import "@babylonjs/loaders";
import CameraController from "./CameraController";
import GlobalState from "./GlobalState";
import GizmoController from "./GizmoController";
import SceneObject from "./SceneObject";
import PartTransformNode from "./PartTransforNode";
import { Device } from "../../../interfaces/Device";
import { Component, ComponentType, DefaultSceneComponent, EmptyComponent, InteractionComponent, MeshComponent, PartComponent } from "../../../interfaces/Component";
import MeshUtils from "./MeshUtils";

export default class SceneManager extends Entity {
	private mCameraController: CameraController;

	private mGizmoController!: GizmoController;

    private mMeshUrl: string;

    private mDeviceData?: Device;

    private mSceneObject!: SceneObject;
    
	constructor(cameraController: CameraController, meshUrl: string, deviceData?:  Device) {
		super("SceneManager");
		this.mCameraController = cameraController;
        this.mMeshUrl = meshUrl;
        this.mDeviceData = deviceData;
	}

	begin() {
		super.begin();
		this.mGizmoController = this.newEntity(GizmoController);
        this.mGizmoController.parent = this;

		let globalState = GlobalState.getInstance();

        this.mSceneObject = this.newEntity(SceneObject, this.mMeshUrl, this.mDeviceData);

        this.mSceneObject.parent = this;

        globalState.onSceneSerializeRequest.on('SceneManagerOnSerializeRequest', (payload)=>{
            let serializedScene = SceneManager.serializeAs(payload.id, payload.name, this.mSceneObject.meshRoot);

            globalState.onSceneSerialized.emit(serializedScene);
        })
	}

	

	get cameraController(): CameraController {
		return this.mCameraController;
	}

    static changePartParent(child: BABYLON.TransformNode, newParent: BABYLON.TransformNode){
        console.log(`Reparenting ${child.name} to ${newParent.name}`)
        // child.parent = newParent;
        child.setParent(newParent);
    }

    static renameTransformNode(node: BABYLON.TransformNode, newName: string){
        node.name = newName;
    }

	static createTransformNode(parent: BABYLON.TransformNode, name: string): PartTransformNode {
		let part = new PartTransformNode(name);
        
        part.parent = parent;
		part.position = parent.position.clone();
		part.rotation = parent.rotation.clone();
		part.scaling = parent.scaling.clone();
        part.id = MeshUtils.generateFullPathID(part, null);
        
		parent.getScene().addTransformNode(part);

		return part;
	}

    static setNodeisVisible(node: BABYLON.TransformNode, isVisible: boolean){
        if(node instanceof BABYLON.Mesh){
            node.isVisible = isVisible;
        }

        node.getChildMeshes().forEach((mesh) => mesh.isVisible = isVisible);
    }

	static deleteTransformNode(part: BABYLON.TransformNode) {
		part.dispose();
	}

    static getSuccessors(node: BABYLON.TransformNode): BABYLON.TransformNode[] {
        let successors: BABYLON.TransformNode[] = [];

        const addSuccessors = (node: BABYLON.TransformNode) => {
            successors.push(node);
            node.getChildTransformNodes().forEach((child) => addSuccessors(child));
        };

        addSuccessors(node);

        return successors;
    }

    static serializeAs(id: string, name: string, root: BABYLON.TransformNode) : Device {
        const serializeRoot = () : DefaultSceneComponent => {
            return {
                id: root.id,
                value: {
                    component_name: "DefaultSceneRoot",
                    component_class: ComponentType.DefaultSceneRoot,
                    attach_socket: "",
                    attach_offset: "()",
                    component_data: {},
                },
                children: serializeChildren(root),
            };
        }

        const serializeChildren = (node: BABYLON.TransformNode) : Component[] => {
            return node.getChildTransformNodes(true).map((child) => {
                return serializeNode(child);
            });
        }

        const serializeNode = (node: BABYLON.TransformNode) : Component => {
            if (node instanceof BABYLON.Mesh) {
                return serializeMesh(node);
            }
            
            else if (node instanceof PartTransformNode) {
                return serializePart(node);
            }

            return serializeEmptyNode(node);
        }

        const serializeMesh = (mesh: BABYLON.Mesh) : MeshComponent => {
            return {
                id: mesh.id,
                value: {
                    component_name: mesh.name,
                    component_class: ComponentType.StaticMeshComponent,
                    attach_socket: "",
                    attach_offset: "()",
                    component_data: {
                        static_mesh: mesh.name,
                    },
                },
                children: serializeChildren(mesh),
            };
        }

        const serializePart = (part: PartTransformNode) : PartComponent => {
            return {
                id: part.id,
                value: {
                    component_name: part.name,
                    component_class: ComponentType.Part,
                    attach_socket: "",
                    attach_offset: "()",
                    component_data: {},
                },
                children: [...serializeChildren(part), ...serializeInteractionComponents(part)],
            };
        }

        const serializeInteractionComponents = (part: PartTransformNode) : Component[] => {
            return part.getInteractionComponents().map((component) => {
                const type = component.value.component_class;

                switch (type) {
                    case ComponentType.InteractionGrabComponent:
                        return component as InteractionComponent;
                    case ComponentType.InteractionMoveComponent:
                        return component as InteractionComponent;
                    case ComponentType.InteractionRotateComponent:
                        return component as InteractionComponent;
                    default:
                        throw new Error(`Unknown interaction component type: ${type}`);
                }
            });
        }

        const serializeEmptyNode = (node: BABYLON.TransformNode) : EmptyComponent => {
            return {
                id: node.id,
                value: {
                    component_name: node.name,
                    component_class: ComponentType.Empty,
                    attach_socket: "",
                    attach_offset: "()",
                    component_data: {},
                },
                children: serializeChildren(node),
            };
        }
        
        return {
            id: id,
            name: name,
            has_custom_root: false,
            parent_class: "Actor",
            class_data: {},
            component_hierachy: {
                root: serializeRoot(),
            },
            actor_components: [],
        };
    }

    deserialize<Device>(device: Device){
        super.deserialize(device);
        //let meshRoot = this.mSceneObject.meshRoot;

    }

    dispose(): void {
        super.dispose();
        let globalState = GlobalState.getInstance();
        globalState.onSceneSerializeRequest.off('SceneManagerOnSerializeRequest')
    }
}
