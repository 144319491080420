/**
 * Type for event handler functions.
 * @callback Handler
 * @template E - The type of the event.
 * @param {E} event - The event data.
 */
export type Handler<E> = (event: E) => void;

/**
 * EventEmitter is a generic class for managing and dispatching events.
 *
 * @class
 * @template E - The type of the event that this event emitter deals with.
 */
export class EventEmitter<E> {
    /**
     * Stores a map of event handler functions, keyed by handler identifiers.
     * @private
     * @type {Map<string, Handler<E>>}
     */
    private mHandlers: Map<string, Handler<E>> = new Map();

    /**
     * Emits an event to all registered handlers.
     * @param {E} event - The event to be dispatched to all the handlers.
     */
    emit(event: E) {
        this.mHandlers.forEach((handler) => {
            handler(event);
        });
    }

    /**
     * Registers a new event handler.
     * @param {string} handleID - The identifier for the handler.
     * @param {Handler<E>} handler - The event handler function to be registered.
     */
    on(handleID: string, handler: Handler<E>) {
        if (this.mHandlers.has(handleID)) {
            console.error(`Handler already registered with ID: ${handleID}`);
            return;
        }
        this.mHandlers.set(handleID, handler);
    }

    /**
     * Unregisters an event handler.
     * @param {string} handleID - The identifier for the handler to be removed.
     */
    off(handleID: string) {
        this.mHandlers.delete(handleID);
    }

    /**
     * Clears all registered event handlers.
     */
    clearAllHandlers() {
        this.mHandlers.clear();
    }

    /**
     * Logs all registered event handlers for debugging purposes.
     */
    debugListeners() {
        this.mHandlers.forEach((handler, id) => {
            console.log(`Handler ID: ${id}, Event: ${handler}`);
        });
    }
}

/**
*Example usage of EventEmitter.
*
*const stringEmitter = new EventEmitter<string>();
*
* Defining a handler function
* const printHandler = (event: string) => {
*    console.log(event);
* };
*
* Registering the handler
* stringEmitter.on('PrintHandlerID', printHandler);
*
* Emitting an event
* stringEmitter.emit('Hello, World!');  // Output: Hello, World!
*/