import React, { useEffect, useState } from "react";
import Slider from "react-input-slider";
import EditableDropdownField from "../../../input/EditableDropdownField";
import { RotateComponent, RotationDirection } from "../../../../interfaces/Component";
import "./RotateComponentProperties.scss";
import ComponenentPropertyContainer from "./ComponentPropertyContainer";
import PartTransformNode from "../../../device-viewport/classes/PartTransforNode";
import * as BABYLON from "@babylonjs/core";
import GlobalState from "../../../device-viewport/classes/GlobalState";
import { VisualizerData } from "../../../device-viewport/classes/AxisVisualizer";
import { GizmoType } from "../../../device-viewport/classes/GizmoController";
import MeshUtils from "../../../device-viewport/classes/MeshUtils";
import EditableField from "../../../input/EditableField";
import * as MATHHELPER from "../../../../helpers/MathExtension";

interface RotateComponenentPropertiesProps {
  part: PartTransformNode;
  component: RotateComponent;
  updateCurrentDevice: () => void;
}

const RotateComponenentProperties: React.FC<RotateComponenentPropertiesProps> = ({
  part,
  component,
  updateCurrentDevice,
}) => {
  const data = component.value.component_data;
  const [neutralRotation, setNeutralRotation] = useState<BABYLON.Vector3 | null>(null);
  const [limitSliderX, setLimitSliderX] = useState(0);

  const showGizmo = () => {
    setNeutralRotation(part.rotation.clone());

    const globalState = GlobalState.getInstance();
    globalState.onAttachGizmoRequested.emit({ node: part, gizmoType: GizmoType.Rotation });
    updateGizmoFromUi();
  };

  const hideGizmo = () => {
    setNeutralRotation(null);

    const globalState = GlobalState.getInstance();
    globalState.onAttachGizmoRequested.emit({ node: null, gizmoType: GizmoType.Rotation });
  };

  const updateGizmoFromUi = () => {
    const visualizerData: VisualizerData = {
      axis: data.axis_of_rotation,
      min: data.max_anticlockwise_rotation_angle,
      max: data.max_clockwise_rotation_angle,
    };
    GlobalState.getInstance().onMoveRotatePropertyChanged.emit({ data: visualizerData, isRot: true });
  };

  const updateMinRotation = (value: string) => {
    data.max_anticlockwise_rotation_angle = parseFloat(value);
    updateGizmoFromUi();
    updateCurrentDevice();
  };

  const updateMaxRotation = (value: string) => {
    data.max_clockwise_rotation_angle = parseFloat(value);
    updateGizmoFromUi();
    updateCurrentDevice();
  };

  const numberToString = (value: number): string => {
    return value.toPrecision(3).toString();
  };

  const stringToNumber = (value: string): number => {
    return Math.min(180, Math.max(-180, parseFloat(value)));
  };

  useEffect(() => {
    const onMouseMoved = (event: MouseEvent) => {
      showGizmo();
      document.removeEventListener("mousemove", onMouseMoved);
    };
    document.addEventListener("mousemove", onMouseMoved);
  }, []);

  useEffect(() => {
    return () => {
      hideGizmo();
    };
  }, []);

  useEffect(() => {
    const updateUiFrom3D = () => {
      if (!neutralRotation) {
        return;
      }

      const newRotation = part.rotation;
      data.axis_of_rotation = MeshUtils.getRotationAxis(neutralRotation, newRotation);
      updateCurrentDevice();
      updateGizmoFromUi();
    };

    const globalState = GlobalState.getInstance();
    globalState.onGizmoUpdate.on("RotateComponentPropertyOnGizmoUpdate", updateUiFrom3D);

    return () => {
      globalState.onGizmoUpdate.off("RotateComponentPropertyOnGizmoUpdate");
    };
  }, [neutralRotation]);

  const remapLimit = (value: number): number => {
    return MATHHELPER.remap(-1, 1, data.max_anticlockwise_rotation_angle, data.max_clockwise_rotation_angle, value)
  };

  useEffect(() => {
    if (!neutralRotation) {
      return;
    }

    part.rotation = neutralRotation;
    part.rotate(data.axis_of_rotation, remapLimit(limitSliderX) * Math.PI / 180);
  }, [limitSliderX]);

  return (
    <div className="property-container">
      <ComponenentPropertyContainer label="Axis of Rotation">
        Defines along which axis the part rotates around.
        <div className="direction-vector">
          x:
          <EditableField
          multiple={false} 
            value={numberToString(data.axis_of_rotation.x)}
            reference=""
            onSave={(value) => {
              data.axis_of_rotation.x = stringToNumber(value);
              updateGizmoFromUi();
              updateCurrentDevice();
            }}
          />
          y:
          <EditableField
          multiple={false} 
            value={numberToString(data.axis_of_rotation.y)}
            reference=""
            onSave={(value) => {
              data.axis_of_rotation.y = stringToNumber(value);
              updateGizmoFromUi();
              updateCurrentDevice();
            }}
          />
          z:
          <EditableField
          multiple={false} 
            value={numberToString(data.axis_of_rotation.z)}
            reference=""
            onSave={(value) => {
              data.axis_of_rotation.z = stringToNumber(value);
              updateGizmoFromUi();
              updateCurrentDevice();
            }}
          />
        </div>
      </ComponenentPropertyContainer>

      <ComponenentPropertyContainer label="Rotation Limits" disabled={data.axis_of_rotation === BABYLON.Vector3.Zero()}>
        <div className="limit-checkbox-container">
          Limit Rotation
          <input
            type="checkbox"
            defaultChecked={data.is_rotation_limited}
            onClick={() => {
              data.is_rotation_limited = !data.is_rotation_limited;
              updateCurrentDevice();
            }}
          />
        </div>

        {data.is_rotation_limited && (
          <div className="limit-values">
            <div className="limit-value">
              Min
              <EditableField multiple={false} reference="" value={numberToString(data.max_anticlockwise_rotation_angle)} onSave={updateMinRotation} />
            </div>
            <div className="limit-value">
              {remapLimit(limitSliderX).toPrecision(3)}
              <Slider
                axis="x"
                x={limitSliderX}
                onChange={({ x }) => setLimitSliderX(x)}
                xmin={-1}
                xmax={1}
                xstep={0.01}
              />
            </div>
            <div className="limit-value">
              Max
              <EditableField multiple={false} reference="" value={numberToString(data.max_clockwise_rotation_angle)} onSave={updateMaxRotation} />
            </div>
          </div>
        )}
      </ComponenentPropertyContainer>

      <ComponenentPropertyContainer
        label="Allowed rotation direction"
        disabled={data.axis_of_rotation === BABYLON.Vector3.Zero()}
      >
        Can the part only be moved forward, backward or both?
        <EditableDropdownField
          value={component.value.component_data.rotation_direction}
          reference=""
          options={Object.values(RotationDirection).map((value) => ({
            value,
            label: value,
          }))}
          onSave={(newValue) => {
            component.value.component_data.rotation_direction = newValue as RotationDirection;
            updateCurrentDevice();
          }}
        />
      </ComponenentPropertyContainer>
    </div>
  );
};

export default RotateComponenentProperties;
