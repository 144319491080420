import React from "react";
import ReactDOM from "react-dom";
import "./PropertySelection.scss";
import { IconAdd, IconHand, IconMove, IconRotate } from "../../../icons/Icons";
import { ComponentType } from "../../../interfaces/Component";

interface PropertySelectionProps {
  addProperty: (componentType: ComponentType) => void;
  onCancel: () => void;
}

const portalRoot = document.getElementById("root");

const PropertySelection: React.FC<PropertySelectionProps> = ({ addProperty, onCancel }) => {
  const [componentTypeToAdd, setComponentTypeToAdd] = React.useState<ComponentType | null>(null);

  const options = [
    { value: ComponentType.InteractionGrabComponent, label: "Grab-able" },
    { value: ComponentType.InteractionMoveComponent, label: "Move-able" },
    { value: ComponentType.InteractionRotateComponent, label: "Rotate-able" },
  ];

  const getIconFill = (componentType: ComponentType) => {
    return componentTypeToAdd === componentType ? "var(--light-secondary-l-white, #fff)" : "var(--light-primary-1-5)";
  };

  return (
    portalRoot &&
    ReactDOM.createPortal(
      <div className="propertyselection-dialog-backdrop">
        <div className="propertyselection-dialog-overlay">
          <h1>Select Property</h1>
          <div className="propertyselection-options">
            {options.map((option) => (
              <div
                className="propertyselection-option"
                key={option.value}
                onClick={() => setComponentTypeToAdd(option.value)}
                style={
                  componentTypeToAdd === option.value
                    ? {
                        backgroundColor: "var(--light-primary-1-5)",
                      }
                    : {
                        cursor: "pointer",
                      }
                }
              >
                {option.value === ComponentType.InteractionGrabComponent && <IconHand fill={getIconFill(option.value)} />}
                {option.value === ComponentType.InteractionMoveComponent && <IconMove fill={getIconFill(option.value)} />}
                {option.value === ComponentType.InteractionRotateComponent && <IconRotate fill={getIconFill(option.value)} />}
                <h3
                  style={
                    componentTypeToAdd === option.value
                      ? {
                          color: "var(--light-secondary-l-white, #fff)",
                        }
                      : {}
                  }
                >
                  {option.label}
                </h3>
              </div>
            ))}
          </div>

          <div className="propertyselection-dialog-buttons">
            <button className="secondary" onClick={onCancel}>
              Cancel
            </button>

            <button
              className="primary"
              onClick={() => addProperty(componentTypeToAdd!)}
              disabled={componentTypeToAdd === null}
            >
              Add selected
              <IconAdd fill="var(--light-secondary-l-white, #fff)" />
            </button>
          </div>
        </div>
      </div>,
      portalRoot
    )
  );
};

export default PropertySelection;
