import * as BABYLON from "@babylonjs/core";
import Entity from "../../babylon/classes/Entity";
import GlobalState from "./GlobalState";
import PartTransformNode from "./PartTransforNode";
import MeshGhost from "./MeshGhost";
import AxisVisualizer, { VisualizerData } from "./AxisVisualizer";
import MeshUtils from "./MeshUtils";


export enum GizmoType {
    Position,
    Rotation,
    Scale
}

export default class GizmoController extends Entity {

    private positionGizmoObservableStart!: BABYLON.Nullable<BABYLON.Observer<unknown>>;
    private positionGizmoObservableEnd!: BABYLON.Nullable<BABYLON.Observer<unknown>>;
    private rotationGizmoObservableStart!: BABYLON.Nullable<BABYLON.Observer<unknown>>;
    private rotationGizmoObservableEnd!: BABYLON.Nullable<BABYLON.Observer<unknown>>;
    private gizmoUpdateObsevable!: BABYLON.Nullable<BABYLON.Observer<unknown>>;
    private mAttachedNode!: BABYLON.TransformNode;
    private mAttachedNodeTransform: { position: BABYLON.Vector3, rotation: BABYLON.Vector3 } = { position: BABYLON.Vector3.Zero(), rotation: BABYLON.Vector3.Zero() }
    private mMeshGhost!: MeshGhost;
    private mAxisVisualizer!: AxisVisualizer;
    private mUtilityLayer!: BABYLON.UtilityLayerRenderer;
    private mGizmoManager!: BABYLON.GizmoManager;

    constructor() {
        super('GizmoController');
    }

    begin(): void {
        super.begin();

        this.mUtilityLayer = new BABYLON.UtilityLayerRenderer(this.getScene());
        this.mUtilityLayer.utilityLayerScene.autoClearDepthAndStencil = false;
        this.mUtilityLayer.utilityLayerScene.activeCamera = this.getScene().activeCamera;

        this.mGizmoManager = new BABYLON.GizmoManager(this.getScene());
        this.mGizmoManager.usePointerToAttachGizmos = false;
        this.mGizmoManager.positionGizmoEnabled = true;
        this.mGizmoManager.rotationGizmoEnabled = false;

        

        this.mGizmoManager.onAttachedToNodeObservable.add((node) => {
            if (this.mAttachedNode && node !== this.mAttachedNode) {
                this.mAttachedNode.position = this.mAttachedNodeTransform.position.clone();
                this.mAttachedNode.rotation = this.mAttachedNodeTransform.rotation.clone();
            }

            if (node && node instanceof BABYLON.TransformNode) {
                this.mAttachedNode = node as BABYLON.TransformNode;
                this.mAttachedNodeTransform = {
                    position: node.position.clone(),
                    rotation: node.rotation.clone()
                }
            }
        })

        const globalState = GlobalState.getInstance();

        // globalState.onPartSelected.on('GizmoControllerOnPartSelected',(part) => {
        //     // if (part[0] instanceof PartTransformNode){
        //         if(this.mMeshGhost){
        //             this.mMeshGhost.cleanUpGhost();
        //             this.mMeshGhost.dispose();
        //         }

        //         if(this.mAxisVisualizer){
        //             this.mAxisVisualizer.dispose()
        //         }

        //         if (part.length <= 0) {
        //             this.removeGizmoEventListeners();
        //             this.mGizmoManager.attachToNode(null)
        //             return;

        //         }
        //         this.mAxisVisualizer = this.newEntity(AxisVisualizer, this.mUtilityLayer);
        //         this.mMeshGhost = this.newEntity(MeshGhost, part);
        //         this.mUtilityLayer.utilityLayerScene.addTransformNode(this.mAxisVisualizer);
        //         this.mAxisVisualizer.setAbsolutePosition(part[0].getAbsolutePosition().clone());
        //         this.mGizmoManager.attachToNode(part[0]);
        //         this.addGizmoEventListeners();
        //     // }
        // });

        globalState.onMoveRotatePropertyChanged.on('GizmoControllerMoveRotUpdate', (updateData)=>{
            this.updateVisualizers(updateData.data, updateData.isRot);
        })

        globalState.onAttachGizmoRequested.on('GizmoControllerOnAttachGizmo', (data) => {
            const{node, gizmoType} = data;

            if (this.mMeshGhost) {
                this.mMeshGhost.cleanUpGhost();
                this.mMeshGhost.dispose();
            }

            if(this.mAxisVisualizer){
                this.mAxisVisualizer.dispose()
            }
            
            this.mGizmoManager.positionGizmoEnabled = false;
            this.mGizmoManager.rotationGizmoEnabled = false;
            this.mGizmoManager.scaleGizmoEnabled = false;

            // Enable the selected gizmo type
            switch (gizmoType) {
                case GizmoType.Position:
                    this.mGizmoManager.positionGizmoEnabled = true;
                    break;
                case GizmoType.Rotation:
                    this.mGizmoManager.rotationGizmoEnabled = true;
                    break;
                case GizmoType.Scale:
                    this.mGizmoManager.scaleGizmoEnabled = true;
                    break;
                default:
                    // No gizmo is enabled
                    break;
            }

            this.mGizmoManager.attachToNode(node);
            if (!node) {
                this.removeGizmoEventListeners();
            } else {
                this.mAxisVisualizer = this.newEntity(AxisVisualizer, this.mUtilityLayer);
                this.addGizmoEventListeners();
                this.mMeshGhost = this.newEntity(MeshGhost, node.getChildMeshes());
                this.mAxisVisualizer.setAbsolutePosition(node.getAbsolutePosition().clone());
            }

        });
    }

    private updateVisualizers(data: VisualizerData, isRot: boolean) {
        if(!this.mAxisVisualizer){
            return;
        }

        this.mAxisVisualizer.updateVisualizer(data, isRot);
    }

    private addGizmoEventListeners() {
        const globalState = GlobalState.getInstance();

        const positionGizmo = this.mGizmoManager.gizmos.positionGizmo;
        const rotationGizmo = this.mGizmoManager.gizmos.rotationGizmo;
        

        if (positionGizmo) {
            this.positionGizmoObservableStart = positionGizmo.onDragStartObservable.add((eventData) => {
                console.log('Position Gizmo drag started');
                globalState.onGizmoUpdate.emit(eventData);
                // Handle start of position gizmo drag
            });

            this.positionGizmoObservableEnd = positionGizmo.onDragEndObservable.add((eventData) => {
                console.log('Position Gizmo drag ended');
                globalState.onGizmoUpdate.emit(eventData);
                // Handle end of position gizmo drag
            });

            this.gizmoUpdateObsevable = positionGizmo.onDragObservable.add((eventData) => {
                //console.log('Position Gizmo drag ended');
                globalState.onGizmoUpdate.emit(eventData);
                // Handle end of position gizmo drag
            });
        }

        if (rotationGizmo) {
            this.rotationGizmoObservableStart = rotationGizmo.onDragStartObservable.add((eventData) => {
                console.log('Rotation Gizmo drag started');
                globalState.onGizmoUpdate.emit(eventData);
                // Handle start of position gizmo drag
            });

            this.rotationGizmoObservableEnd = rotationGizmo.onDragEndObservable.add((eventData) => {
                console.log('Rotation Gizmo drag ended');
                globalState.onGizmoUpdate.emit(eventData);
                // Handle end of position gizmo drag
            });

            this.gizmoUpdateObsevable = rotationGizmo.onDragObservable.add((eventData) => {
                //console.log('Position Gizmo drag ended');
                globalState.onGizmoUpdate.emit(eventData);
                // Handle end of position gizmo drag
            });
        }

    }

    private removeGizmoEventListeners() {
        const positionGizmo = this.mGizmoManager.gizmos.positionGizmo;
        const rotationGizmo = this.mGizmoManager.gizmos.rotationGizmo;

        if (positionGizmo) {
            positionGizmo.onDragStartObservable.remove(this.positionGizmoObservableStart);
            positionGizmo.onDragEndObservable.remove(this.positionGizmoObservableEnd);
            positionGizmo.onDragObservable.remove(this.gizmoUpdateObsevable);
        }

        if (rotationGizmo) {
            rotationGizmo.onDragStartObservable.remove(this.rotationGizmoObservableStart);
            rotationGizmo.onDragEndObservable.remove(this.rotationGizmoObservableEnd);
            rotationGizmo.onDragObservable.remove(this.gizmoUpdateObsevable);
        }

    }

    dispose(): void {
        super.dispose()

        const globalState = GlobalState.getInstance();
        globalState.onPartSelected.emit([]);
        globalState.onPartSelected.off('GizmoControllerOnPartSelected');
        globalState.onAttachGizmoRequested.off('GizmoControllerOnAttachGizmo');
        globalState.onMoveRotatePropertyChanged.off('GizmoControllerMoveRotUpdate');

        if (this.mMeshGhost) {

            this.mMeshGhost.cleanUpGhost();
            this.mMeshGhost.dispose();
        }
    }
}