import React from "react";
import { HierarchyNode } from "../../DraggableHierarchy/helpers/DraggableHierarchyHelper";
import { IconGear, IconVisibilityOff, IconVisibilityOn } from "../../../icons/Icons";
import "./SceneHierarchyNode.scss";
import { SceneNodeData, SceneNodeType } from "./SceneHierarchyHelper";

interface SceneHierarchyNodeProps {
  node: HierarchyNode;
  selectedNodeIds: string[];
  hoveredNodeId: string | null;
  hiddenNodeIds: string[];
  toggleNodeVisibility: (hiddenNodeId: string) => void;
}

const SceneHierarchyNode: React.FC<SceneHierarchyNodeProps> = ({
  node,
  selectedNodeIds,
  hoveredNodeId,
  hiddenNodeIds,
  toggleNodeVisibility,
}) => {
  const nodeData = node.data as SceneNodeData;
  const transformNode = nodeData.transformNode;

  return (
    <div
      className="scene-hierarchy-node-content"
      style={{
        color: selectedNodeIds.includes(node.id) ? "var(--light-primary-1-5)" : "var(--light-text-light-theme-0)",
      }}
    >
      <div className="content">
        {nodeData.type === SceneNodeType.Part && (
          <div className="part-icon">
            <IconGear fill="var(--light-primary-1-5)" />
          </div>
        )}

        <div
          className="node-name"
          style={
            node.childrenIds.length === 0
              ? {
                  paddingLeft: "25px",
                }
              : {}
          }
        >
          {transformNode.name}
        </div>

        {true && (
          <button
            className="eye-icon-button"
            onClick={(event) => {
              event.stopPropagation();
              toggleNodeVisibility(node.id);
            }}
          >
            {hiddenNodeIds.includes(node.id) ? (
              <IconVisibilityOn fill="var(--light-primary-1-5)" />
            ) : (
              <IconVisibilityOff fill="var(--light-primary-1-5)" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default SceneHierarchyNode;
