import React, { useState, useEffect } from "react";
import * as HIERARCHYHELPER from "./helpers/DraggableHierarchyHelper";
import "./DraggableHierarchy.scss";
import HierarchyEntry from "./HierarchyEntry";

interface DraggableHierarchyProps {
  nodes: { [id: string]: HIERARCHYHELPER.HierarchyNode };
  setNodes: (nodes: { [id: string]: HIERARCHYHELPER.HierarchyNode }) => void;
  selectedNodeIds: string[];
  setSelectedNodeIds: (nodeIds: string[]) => void;
  hoveredNodeId: string | null;
  setHoveredNodeId: (nodeId: string | null) => void;

  canDrop: (draggedNode: HIERARCHYHELPER.HierarchyNode | null, hoverData: HIERARCHYHELPER.DragData) => boolean;
  nodeContentRender?: (node: HIERARCHYHELPER.HierarchyNode) => JSX.Element;
  leafIconOverride?: (node: HIERARCHYHELPER.HierarchyNode) => JSX.Element;
  allowMultiSelect?: boolean;
  onDropped?: (
    draggedNode: HIERARCHYHELPER.HierarchyNode,
    newParent: HIERARCHYHELPER.HierarchyNode,
    nodes: { [id: string]: HIERARCHYHELPER.HierarchyNode }
  ) => { [id: string]: HIERARCHYHELPER.HierarchyNode } | void;
}

const DraggableHierarchy: React.FC<DraggableHierarchyProps> = ({
  nodes,
  setNodes,
  selectedNodeIds,
  setSelectedNodeIds,
  hoveredNodeId,
  setHoveredNodeId,
  canDrop,
  nodeContentRender,
  leafIconOverride,
  allowMultiSelect = true,
  onDropped,
}) => {
  const [draggedNode, setDraggedNode] = useState<HIERARCHYHELPER.HierarchyNode | null>(null);
  const [dragData, setDragData] = useState<HIERARCHYHELPER.DragData>({
    hoveredNode: null,
    heightPercentage: null,
  });

  function handleRelease(
    nodes: { [id: string]: HIERARCHYHELPER.HierarchyNode },
    draggedNode: HIERARCHYHELPER.HierarchyNode,
    hoverData: HIERARCHYHELPER.DragData
  ): { [id: string]: HIERARCHYHELPER.HierarchyNode } {
    const { hoveredNode, heightPercentage } = hoverData;

    if (!hoveredNode) {
      return nodes;
    }

    // check the drop rules set from outside
    if (!canDrop(draggedNode, hoverData)) {
      return nodes;
    }

    // you cannot drop a node onto itself
    if (draggedNode.id === hoveredNode.id) {
      return nodes;
    }

    // you cannot drop a node onto its parent
    if (draggedNode.parentId === hoveredNode.id) {
      return nodes;
    }

    // you cannot drop a node onto one of its successors
    const successors = HIERARCHYHELPER.getSuccessors(draggedNode, nodes);
    if (successors.includes(hoveredNode)) {
      return nodes;
    }

    const isTop = heightPercentage !== null && heightPercentage < 30;
    const isBottom = heightPercentage !== null && heightPercentage > 70;
    const newParentId = isTop || isBottom ? hoveredNode.parentId : hoveredNode.id;

    if (!newParentId || !nodes[newParentId]) {
      return nodes;
    }

    // Remove the step from its old parent's children
    const oldParentId = draggedNode.parentId;
    if (oldParentId) {
      const oldParentChildren = nodes[oldParentId].childrenIds.filter((id) => id !== draggedNode.id);

      const updatedOldParent = {
        ...nodes[oldParentId],
        childrenIds: oldParentChildren,
      };

      nodes = HIERARCHYHELPER.updateNodes(nodes, updatedOldParent);
    }

    let newParentChildren = nodes[newParentId].childrenIds.filter((id) => id !== draggedNode.id);
    const hoverIndex = newParentChildren.indexOf(hoveredNode.id);

    if (isTop) {
      // If dropped at top, add the draggedNode id before the hoveredNode id
      newParentChildren.splice(hoverIndex, 0, draggedNode.id);
    } else if (isBottom) {
      // If dropped at bottom, add the draggedNode id after the hoveredNode id
      newParentChildren.splice(hoverIndex + 1, 0, draggedNode.id);
    } else {
      // If dropped on a section, add the draggedNode id at the end of section's children
      newParentChildren.push(draggedNode.id);
    }

    const updatedParent = {
      ...nodes[newParentId],
      childrenIds: newParentChildren,
    };

    nodes = HIERARCHYHELPER.updateNodes(nodes, updatedParent);

    const updatedDraggedNode = { ...draggedNode, parentId: newParentId };
    nodes = HIERARCHYHELPER.updateNodes(nodes, updatedDraggedNode);

    if (onDropped) {
      const newNodes = onDropped(draggedNode, nodes[newParentId], nodes);
      if (newNodes) {
        return newNodes;
      }
    }

    return nodes;
  }

  useEffect(() => {
    const handleMouseUp = () => {
      if (draggedNode !== null && dragData.hoveredNode !== null && draggedNode.id !== dragData.hoveredNode.id) {
        setNodes(handleRelease(nodes, draggedNode, dragData));
      }
      setDraggedNode(null);
      setDragData({ hoveredNode: null, heightPercentage: null });
    };
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [draggedNode, dragData]);

  const handleNodeMouseDown = (event: React.MouseEvent, node: HIERARCHYHELPER.HierarchyNode) => {
    event.stopPropagation();
    setDraggedNode(node);
  };

  const handleNodeMouseMove = (event: React.MouseEvent, node: HIERARCHYHELPER.HierarchyNode) => {
    event.stopPropagation();
    if (draggedNode !== null) {
      const rect = event.currentTarget.getBoundingClientRect();
      const heightPercentage = ((event.clientY - rect.top) / rect.height) * 100;
      setDragData({
        hoveredNode: node,
        heightPercentage: heightPercentage,
      });
    }
  };

  // get some node from the nodes
  const rootNodes = Object.values(nodes).filter((node) => node.parentId === null);

  if (!rootNodes) {
    return <></>;
  }

  return (
    <>
      {rootNodes.map((rootNode) => (
        <div className="draggable-tree" key={rootNode.id}>
          <HierarchyEntry
            node={rootNode}
            setNodes={setNodes}
            selectedNodeIds={selectedNodeIds}
            setSelectedNodeIds={setSelectedNodeIds}
            hoveredNodeId={hoveredNodeId}
            setHoveredNodeId={setHoveredNodeId}
            nodes={nodes}
            key={rootNode.id}
            draggedNode={draggedNode}
            dragData={dragData}
            handleNodeMouseDown={handleNodeMouseDown}
            handleNodeMouseMove={handleNodeMouseMove}
            canDrop={canDrop}
            nodeContentRender={nodeContentRender}
            leafIconOverride={leafIconOverride}
            allowMultiSelect={allowMultiSelect}
          />
        </div>
      ))}
    </>
  );
};

export default DraggableHierarchy;
