import React, { useEffect, useRef, useState } from "react";
import "./EditableField.scss";

interface EditableFieldProps {
  value: string;
  reference: string;
  multiple: boolean;
  onSave: (value: string, reference: string) => void;
  onFocus?: () => void;
  onLostFocus?: () => void;
}

const EditableField: React.FC<EditableFieldProps> = ({ value, reference, multiple, onSave, onFocus, onLostFocus }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onLocalLostFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onLostFocus) {
      onLostFocus();
    }

    if (currentValue === value) {
      return;
    }

    onSave(currentValue, reference);
  };

  const onLocalLostFocusArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onLostFocus) {
      onLostFocus();
    }

    if (currentValue === value) {
      return;
    }

    onSave(currentValue, reference);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className="custom-field">
      {multiple ? (
        <textarea
          onFocus={onFocus}
          onBlur={onLocalLostFocusArea}
          className="custom-field-input"
          value={currentValue}
          onChange={(e) => setCurrentValue(e.target.value)}
          rows={4} // you can adjust the number of rows as needed
        />
      ) : (
        <input
          onFocus={onFocus}
          onBlur={onLocalLostFocus}
          className="custom-field-input"
          type="text"
          ref={inputRef}
          value={currentValue}
          onChange={(e) => setCurrentValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              inputRef.current?.blur();
            }
          }}
        />
      )}
    </div>
  );
};

export default EditableField;
