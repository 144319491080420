import React from "react";
import DeviceViewport from "../components/device-viewport";

const Playground = () => {
  return (
    <div>
      <DeviceViewport />
    </div>
  );
};

export default Playground;
