import React from "react";
import EditableField from "../../../input/EditableField";
import { createStorylineShortName, NodeType, StorylineData } from "./StorylineDataProperties";
import { HierarchyNode } from "../../../DraggableHierarchy/helpers/DraggableHierarchyHelper";

export interface Section extends StorylineData {}

export const getDefaultSection = (title: string): Section => ({
  title: title,
  shortName: createStorylineShortName(title), // default short name
  type: NodeType.Section,
});

interface SectionPropertiesProps {
  node: HierarchyNode;
  onSave: (property: string, newValue: any) => void;
}

const SectionProperties: React.FC<SectionPropertiesProps> = ({ node, onSave }) => {
  return (
    <div>
      <h4>Title:</h4>
      <EditableField multiple={false}  value={(node.data as Section).title} reference="" onSave={(newValue) => onSave("title", newValue)} />

      <h4>Short Name (optional):</h4>
      <EditableField multiple={false} value={(node.data as Section).shortName} reference="" onSave={(newValue) => onSave("shortName", newValue)} />
    </div>
  );
};

export default SectionProperties;
