import React, { useEffect, useState } from "react";
import EditableDropdownField from "../../../input/EditableDropdownField";
import EditableField from "../../../input/EditableField";
import { createStorylineShortName, NodeType, StorylineData } from "./StorylineDataProperties";
import { HierarchyNode } from "../../../DraggableHierarchy/helpers/DraggableHierarchyHelper";
import { DeviceContext } from "../../../../App";
import { Component, ComponentType, PartComponent } from "../../../../interfaces/Component";
import "./InteractionProperties.scss";
import featureData from "./Interactions/Features.json";
import { InteractionComponent } from "../../../../interfaces/Component";

export interface InteractionData extends StorylineData {
  interactionType: string | null;
  properties: { [key: string]: any }
  children: never[]; // Interactions cannot have children
}

export const getDefaultInteraction = (title: string): InteractionData => ({
  title: title,
  shortName: createStorylineShortName(title), // default short name
  type: NodeType.Interaction,
  interactionType: null,
  properties: {},
  children: [],
});

interface DataItem {
  display_name: string;
  json_name: string;
  type: string;
  default: any;
  required_components?: string[]
  values?: string[];
}

interface InteractionPropertiesProps {
  node: HierarchyNode;
  onSave: (property: string, newValue: any) => void;
  devices: { [id: string]: DeviceContext };
}

const InteractionProperties: React.FC<InteractionPropertiesProps> = ({ node, onSave, devices }) => {
  const interactionData = node.data as InteractionData;
  
  const [interactionType, setInteractionType] = useState<string | null>(interactionData.interactionType);


  useEffect(() => {
    setInteractionType(interactionData.interactionType);
  }, [interactionData])
  
  const createAllPartOptions = (): { value: PartComponent; label: string }[] => {
    let options: { value: PartComponent; label: string }[] = [];

    const createOptionsForComponent = (component: Component, deviceName: string) => {
      const partComponent = component as PartComponent;

      if (partComponent.value.component_class === ComponentType.Part) {
        options.push({
          value: partComponent,
          label: `${deviceName}: ${partComponent.value.component_name}`,
        });
      }

      component.children.forEach((child) => {
        createOptionsForComponent(child, deviceName);
      });
    };

    Object.values(devices).forEach((deviceContext) => {
      createOptionsForComponent(deviceContext.device.component_hierachy.root, deviceContext.name);
    });

    return options;
  };

  
  const getInteractionPartOptions = (requiredComponents: string[]): { value: string; label: string }[] => {
    const interactionParts: { value: string; label: string }[] = [];
    
    const partOptions = createAllPartOptions();
    partOptions.forEach((part) => {
      const partChildren = part.value.children;
      let missingRequirements = [...requiredComponents];
      partChildren.forEach((child) => {
        missingRequirements = missingRequirements.filter(className => className !== (child as InteractionComponent).value.component_class);
      });
      if (missingRequirements.length === 0) {
        interactionParts.push({ value: part.value.value.component_name, label: part.label });
      }
    });

    return interactionParts;
  };

  const extractContextOptions = () => {
    const options = featureData.flatMap(item => 
      item.steps
        .filter(step => step.should_display)  
        .map(step => ({
          value: step.context,
          label: step.context
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')
            .trim()
      }))
    )
    .sort((a, b) => a.label.localeCompare(b.label));
    return options;
  };

  const findPartOptionsByDataItem = (dataItem : DataItem) : { value: string; label: string }[] => {
      return getInteractionPartOptions(dataItem.required_components ?? [])
  }

  const findStepDataByContext = (context : string) => {
    // Assuming there's only one top-level item, adjust as needed if there are more
    const feature = featureData.find(feature => feature.steps.find(step => step.context === context && step.should_display));
    if (feature === undefined) {
      return null;
    }
    const step = feature.steps.find(step => step.context === context && step.should_display);
    if (step === undefined)
      return null;

    const dataArray : DataItem[] = []
    step.data.forEach(element => {
        const dataItem : DataItem = {
        display_name: element.display_name,
        json_name : element.json_name,
        default: element.default,
        type: element.type,
        required_components: element.required_components,
        values: element.values
      };
      dataArray.push(dataItem);
    });

    return dataArray
  };


  const handlePropertyChange = (value: any, reference: string) => {
    interactionData.properties[reference] = value;
    onSave(reference, value);
  };

  const handleTitleChange = (value: string) => {
    // interactionData.title = value;
    onSave("title", value);
  }

  const handleTypeChange = (value: string) => {
    // interactionData.interactionType = value;
    setInteractionType(value);
    onSave("interactionType", value);
  }

  const handleNumberChange = (value: any, reference: string) => {
    const number = Number(value)
    if (Number.isNaN(number))
      return;
    interactionData.properties[reference] = number;
    onSave("properties", interactionData.properties);
  }

  const handleTogglePropertyChange = (value: any, reference: string) => {
    interactionData.properties[reference] = !value;
    onSave("properties", interactionData.properties);
  }

  const getPropertyValue = (reference: string) => {
    if (!(reference in interactionData.properties))
      return null;
    return interactionData.properties[reference];
  }

  const handleEdit = (reference: string, index: number, newValue: any) => {
    const newItems = [...interactionData.properties[reference]];
    newItems[index] = newValue;
    interactionData.properties[reference] = [...newItems]
    onSave("properties", interactionData.properties);
  };

  const handleAdd = (reference: string) => {
    let newItems = interactionData.properties[reference]
    if (newItems === undefined) {
      newItems = []
    }
    const newItem = `Entry ${newItems.length + 1}`;
    newItems.push(newItem)
    interactionData.properties[reference] = [...newItems]
    onSave("properties", interactionData.properties);
  };  

  const handleRemove = (reference: string, index: number) => {
    const newItems = [...interactionData.properties[reference]];
    if (newItems === undefined)
      return
    newItems.splice(index, 1);
    interactionData.properties[reference] = [...newItems]
    onSave("properties", interactionData.properties);
  };

    // TODO: add multi part reference for something like SBP_Shared_Move
  const renderInput = (dataItem : DataItem) => {
    let propertyValue = getPropertyValue(dataItem.json_name);
    if (propertyValue === null) {
      propertyValue = dataItem.default;
    }
    const partInfo = findPartOptionsByDataItem(dataItem)
    switch (dataItem.type) {
      case "PartReference":
        return  <div key={dataItem.json_name} className="input-wrapper">
                  <h4>{dataItem.display_name}</h4>
                  <EditableDropdownField 
                    value={propertyValue}
                    options={partInfo}
                    reference={dataItem.json_name}
                    onSave={handlePropertyChange} />
                </div> // Simple text input for demonstration
      case "Array[String]":
        return <div key={dataItem.json_name} className="input-wrapper">
                  <h4>{dataItem.display_name}</h4>
                    {propertyValue.map((item: string, index: number) => (
                      <div key={`${item}-${index}`}>
                        <EditableField
                        multiple={false} 
                          value={item}
                          reference={dataItem.json_name}
                          onSave={(value, reference) => handleEdit(reference, index, value)}
                        />
                        <button onClick={() => handleRemove(dataItem.json_name, index)}>Remove</button>
                      </div>
                    ))}
                    <button onClick={() => handleAdd(dataItem.json_name)}>Add Item</button>
                </div>
      case "Enumeration":
        if (dataItem.values === undefined)
          return
        const options = dataItem.values.map(value => ({
          value,
          label: value.replace(/([A-Z])/g, ' $1').trim()
        }));
        return (
          <div key={dataItem.json_name} className="input-wrapper">
              <h4>{dataItem.display_name}</h4>
              <EditableDropdownField
                value={propertyValue}
                options={options}
                reference={dataItem.json_name}
                onSave={handlePropertyChange}
              />
          </div>
        );
      case "Float":
        return <div key={dataItem.json_name} className="input-wrapper">
                  <h4>{dataItem.display_name}</h4>
                  <EditableField multiple={false} value={propertyValue} reference={dataItem.json_name} onSave={handleNumberChange} />
                </div>;
      case "Integer":
        return <div key={dataItem.json_name} className="input-wrapper">
                  <h4>{dataItem.display_name}</h4>
                  <EditableField multiple={false} value={propertyValue} reference={dataItem.json_name} onSave={handleNumberChange} />
                </div>;
      case "String":
        return <div key={dataItem.json_name} className="input-wrapper">
                  <h4>{dataItem.display_name}</h4>
                  <EditableField multiple={false} value={propertyValue} reference={dataItem.json_name} onSave={handlePropertyChange} />
                </div>
      case "Multiline":
        return <div key={dataItem.json_name} className="input-wrapper">
                  <h4>{dataItem.display_name}</h4>
                  <EditableField multiple={true} value={propertyValue} reference={dataItem.json_name} onSave={handlePropertyChange} />
                </div>
      case "Boolean":
        propertyValue = propertyValue === "true"
        return <div key={dataItem.json_name} className="input-wrapper">
                  <h4>{dataItem.display_name}</h4>
                  <button onClick={() => handleTogglePropertyChange(propertyValue, dataItem.json_name)}>{propertyValue ? "On" : "Off"}</button>
                </div>
      // Add more cases as needed for different types
      default:
        return  <div key={dataItem.json_name} className="input-wrapper">
                  <h4>{dataItem.display_name}</h4>
                  <EditableField multiple={false} value={propertyValue} reference={dataItem.json_name} onSave={handlePropertyChange} />
                </div>; // Fallback input
    }
  };

  return (
    <div className="interaction-panel">
      <div className="interaction-properties">
        <h4>Interaction Title:</h4>
        <EditableField multiple={false} value={(node.data as InteractionData).title} reference="" onSave={handleTitleChange} />

        <h4>Interaction Type:</h4>
        <EditableDropdownField
          value={interactionType ? interactionType : "Select..."}
          options={extractContextOptions()}
          reference=""
          onSave={handleTypeChange}
        />

        {
          (findStepDataByContext(interactionType as string) || []).map((element, index) => (
            <div key={index}>{renderInput(element)}</div>
          ))
        }
      </div>

      {(node.data as InteractionData).interactionType && (
        <div className="interaction-preview">3D Preview Placeholder</div>
      )}
    </div>
  );
};

export default InteractionProperties;
