import React from "react";
import ReactDOM from "react-dom";
import ConfirmDialog from "../ConfirmDialog";
import { DeviceContext } from "../../App";
import "./DeviceProperties.scss";
import EditableField from "../input/EditableField";
import { IconDelete } from "../../icons/Icons";

interface DevicePropertiesProps {
  deviceContext: DeviceContext;
  removeDevice: (id: string) => void;
  switchActiveDevice: (deviceId: string | null) => void;
  closeProperties: () => void;
  updateDevice: (id: string, device: DeviceContext) => void;
}

const portalRoot = document.getElementById("root");

const DeviceProperties: React.FC<DevicePropertiesProps> = ({
  deviceContext,
  removeDevice,
  switchActiveDevice,
  closeProperties,
  updateDevice,
}) => {
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [newName, setNewName] = React.useState<string>(deviceContext.name);

  const renameDevice = (value: string) => {
    setNewName(value);
  };
  return (
    <>
      {portalRoot &&
        ReactDOM.createPortal(
          <div className="device-properties-backdrop">
            <div className="device-properties-overlay">
              <div className="device-properties">
                <EditableField multiple={false} reference="" value={deviceContext.name} onSave={renameDevice} />
                <button
                  className="secondary"
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  <IconDelete fill="var(--light-primary-1-5)" />
                </button>
              </div>

              <div className="apply-button-container">
                <button
                  className="primary"
                  onClick={() => {
                    deviceContext.name = newName;
                    updateDevice(deviceContext.device.id, deviceContext);
                    closeProperties();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>,
          portalRoot
        )}

      {isDeleting && (
        <ConfirmDialog
          title={`Deleting ${deviceContext.name}`}
          message={`Are you sure you want to delete ${deviceContext.name}?`}
          onCancel={() => setIsDeleting(false)}
          onConfirm={() => {
            removeDevice(deviceContext.device.id);
            switchActiveDevice(null);
            setIsDeleting(false);
            closeProperties();
          }}
        />
      )}
    </>
  );
};

export default DeviceProperties;
