import React from "react";
import BabylonScene, { SceneEventArgs } from "../babylon";
import * as BABYLON from '@babylonjs/core';
import { GizmoManager } from '@babylonjs/core'
import SceneManager from "./classes/SceneManager";
import SelectionController from "./classes/SelectionController";
import Entity from "../babylon/classes/Entity";
import GlobalState from "./classes/GlobalState";
import { Inspector } from "@babylonjs/inspector";
import CameraController from "./classes/CameraController";
import { Device } from "../../interfaces/Device";

export interface DeviceViewportProps {
    meshURL?: string;
    deviceData? : Device;
}

export default class DeviceViewport extends React.Component<DeviceViewportProps, any> {
    private mSceneManager!: SceneManager;
    private mCameraController!: CameraController;
    private mEngine!: BABYLON.Engine;
    private mMeshUrl?: string;
    private mDeviceData?: Device;

    constructor(props: DeviceViewportProps) {
        super(props);

        const { meshURL } = props;
        const { deviceData } = props;

        this.mMeshUrl = meshURL;
        this.mDeviceData = deviceData;
    }

    componentDidMount(): void {
        // console.log("MeshUrl:"+this.mMeshUrl)
        // console.log("DeviceData:"+this.mDeviceData)
        // console.log('Device viewport has been mounted')
    }

    componentDidUpdate(prevProps: Readonly<DeviceViewportProps>, prevState: Readonly<any>, snapshot?: any): void {
        const { meshURL } = this.props;
        const { deviceData } = this.props;

        if(prevProps.meshURL !== this.props.meshURL || prevProps.deviceData !== this.props.deviceData){
            this.mMeshUrl = meshURL;
            this.mDeviceData = deviceData;

            this.startUpSceneStructure();
        }

        // console.log("MeshUrl:"+ meshURL)
        // console.log("DeviceData:"+ deviceData)
        // console.log('Device Viewport Updated')       
    }

    componentWillUnmount(): void {

        // console.log("MeshUrl:"+this.mMeshUrl)
        // console.log("DeviceData:"+this.mDeviceData)
        
        const globalState = GlobalState.getInstance();
        globalState.onPartSelected.off('DeviceViewportOnPartSelected');

        // console.log('Device viewport was unmounted')
    }

    onSceneMount(e: SceneEventArgs) {
        const { canvas, engine, scene } = e;
        const { meshURL } = this.props;
        const { deviceData } = this.props;

        this.mMeshUrl = meshURL;
        this.mDeviceData = deviceData;

        // console.log("MeshUrl:"+this.mMeshUrl)
        // console.log("DeviceData:"+this.mDeviceData)

        this.mEngine = engine;
        scene.clearColor = BABYLON.Color4.FromInts(128, 128, 128, 255);

        const light = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(1, 1, 1), scene);

        const selectionController = new SelectionController(canvas);

        this.mCameraController = new CameraController(canvas);

        const globalState = GlobalState.getInstance();
        
        if(meshURL){
            this.startUpSceneStructure();
        }  

        globalState.onPartSelected.on('DeviceViewportOnPartSelected',this.onPartSelected.bind(this));

        const toggleInspector = () => {
            if (Inspector.IsVisible) {
                Inspector.Hide();
            } else {
                Inspector.Show(scene, {
                    embedMode: true
                });
            }
        }

        window.addEventListener('keydown', (event) => {
            // toggle inspector on ctrl + alt + i
            if (event.ctrlKey && event.altKey && event.key === 'i') {
                toggleInspector();
            }
        });
        console.log("Scene Mounted")

    }

    private onPartSelected(parts: BABYLON.TransformNode[]) {
        console.log("Part selected:", parts);
    }

    private startUpSceneStructure() {
        if (this.mSceneManager){
            this.mSceneManager.dispose();
        }

        if(this.mMeshUrl){
            this.mSceneManager = new SceneManager(this.mCameraController, this.mMeshUrl, this.mDeviceData);
        }
    }

    onSceneTick() {

    }

    render() {
        return (<div style={{ position: 'relative', top: '0', left: '0', right: '0', bottom: '0', width: '100%', height: '100%', overflow: 'hidden' }}>
            <BabylonScene
                onSceneMount={this.onSceneMount.bind(this)}
                onSceneTick={this.onSceneTick.bind(this)}
                context={this.context}></BabylonScene>
        </div>)
    }
}