import * as BABYLON from "@babylonjs/core";

export function saveToLocalStorage(key: string, data: any) {
  window.localStorage.setItem(key, JSON.stringify(data));
}

export function loadFromLocalStorage(key: string): any | null {
  const data = window.localStorage.getItem(key);

  if (!data || data === undefined || data === "undefined" || data === null) {
    return null;
  }

  const parsed = JSON.parse(data);
  // if parsed is object

  if (parsed && typeof parsed === "object") {
    // crawl over entire object and all its recursive children
    const crawl = (parent: any, key: string) => {
      const item = parent[key];
      if (item !== null && typeof item === "object") {
        if (item._x !== undefined && item._y !== undefined && item._z !== undefined) {
          parent[key] = new BABYLON.Vector3(item._x, item._y, item._z);
        } else {
          Object.keys(item).forEach((key) => {
            crawl(item, key);
          });
        }
      }
    };

    Object.keys(parsed).forEach((key) => {
      crawl(parsed, key);
    });
  }

  return parsed;
}
