import React from "react";
import ReactDOM from "react-dom";
import { signInWithMicrosoft } from "../../firebase/firebase.utils";
import "./microsoft-sign-in.styles.scss";
import { IconMicrosoft, IconRWO } from "../../icons/Icons";

interface MicrosoftSignInProps {}

const portalRoot = document.getElementById("root");

const MicrosoftSignIn: React.FC<MicrosoftSignInProps> = ({}) => {
  return (
    portalRoot &&
    ReactDOM.createPortal(
      <div className="sign-in-backdrop">
        <div className="sign-in-overlay">
          <div className="logo">
            <IconRWO />
          </div>

          <div className="title">
            <h1 className="bold">Creator</h1>
            <h1 className="regular">tool</h1>
          </div>

          <button className="secondary" onClick={() => signInWithMicrosoft()}>
            <IconMicrosoft />
            Sign in with Microsoft
          </button>
        </div>
      </div>,
      portalRoot
    )
  );
};

export default MicrosoftSignIn;
