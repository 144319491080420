import React from "react";
import EditableField from "../../../input/EditableField";
import { NodeType, StorylineData, createStorylineShortName } from "./StorylineDataProperties";
import { HierarchyNode } from "../../../DraggableHierarchy/helpers/DraggableHierarchyHelper";

export interface StepData extends StorylineData {
  stepInfo: string;
  stepVO: string;
}

export const getDefaultStep = (title: string): StepData => ({
  title: title,
  shortName: createStorylineShortName(title), // default short name
  type: NodeType.Step,
  stepInfo: "Hand UI info for new step",
  stepVO: "VO for new step",
});

interface StepPropertiesProps {
  node: HierarchyNode;
  onSave: (property: string, newValue: any) => void;
}

const StepProperties: React.FC<StepPropertiesProps> = ({ node, onSave }) => {
  return (
    <div>
      <h4>Step Title:</h4>
      <EditableField value={(node.data as StepData).title} multiple={false} reference="" onSave={(newValue) => onSave("title", newValue)} />

      <h4>Short Name (optional):</h4>
      <EditableField value={(node.data as StepData).shortName} multiple={false} reference="" onSave={(newValue) => onSave("shortName", newValue)} />

      <h4>Hand UI Text:</h4>
      <EditableField value={(node.data as StepData).stepInfo} multiple={true} reference="" onSave={(newValue) => onSave("stepInfo", newValue)} />

      <h4>Voiceover:</h4>
      <EditableField value={(node.data as StepData).stepVO} multiple={false} reference="" onSave={(newValue) => onSave("stepVO", newValue)} />
    </div>
  );
};

export default StepProperties;
