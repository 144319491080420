import { loadFromLocalStorage } from "../StorageHelper";
import * as HIERARCHYHELPER from "../../components/DraggableHierarchy/helpers/DraggableHierarchyHelper";
import { Module } from "../../components/storyline-builder/StorylineEditor/storyline-builder-steps/ModuleProperties";
import { Section } from "../../components/storyline-builder/StorylineEditor/storyline-builder-steps/SectionProperties";
import { StepData } from "../../components/storyline-builder/StorylineEditor/storyline-builder-steps/StepProperties";
import { InteractionData } from "../../components/storyline-builder/StorylineEditor/storyline-builder-steps/InteractionProperties";

export function exportModules() {
  const storyline = loadFromLocalStorage("storylineNodes");
  if (!storyline) return [];
  const tree = HIERARCHYHELPER.constructTree(storyline);
  const root = tree[0];

  // an experience can consist of multiple modules
  return root.children.map((module: any) => {
    const data = module.data as Module;
    return {
      name: data.title,
      short_name: data.shortName,
      display_info: data.displayInfo,
      sections: exportSections(module.children),
      tags: data.tags
    };
  });
}

const exportSections = (sections: any[]) => {
  // any module consists of sections
  return sections.map((section: any) => {
    const data = section.data as Section;
    return {
      name: data.title,
      short_name: data.shortName,
      steps: exportSteps(section.children),
    };
  });
};

const exportSteps = (steps: any[]) => {
  // any section consists of steps
  return steps.map((step: any) => {
    const data = step.data as StepData;
    return {
      name: data.title,
      short_name: data.shortName,
      description: data.stepInfo,
      vo: data.stepVO,
      step_sequence: exportStepSequence(step),
    };
  });
};

const exportStepSequence = (step: any) => {
  const steps = exportInteraction(step);
  const stepConnections = exportStepConnections(step)
  const data = step.data as StepData;

  if (data.stepVO || data.stepVO.trim()) {
    const index = steps.push({
      name: "voiceover",
      data: {
        step_short_name: data.shortName,
        content: data.stepVO,
      },
    });

    stepConnections[0].push({
      target: index - 1,
      type: 1,
      auto_complete_source: true,
      undo_target_if_active: false,
    });
  }
  return {
    steps: steps,
    step_connections: stepConnections,
  };
};

const exportStepConnections = (step: any) => {
  const connections: { [key: string]: object[] } = {};

  const numberOfNodes = step.children.length + 2; // number of interaction plus start and end

  for (let i = 0; i < numberOfNodes - 1; i++) {
    connections[`${i}`] = [
      {
        target: i + 1,
        type: 1,
        auto_complete_source: true,
        undo_target_if_active: false,
      },
    ];
  }

  return connections;
};

const exportInteraction = (step: any) => {
  return [
    {
      name: "group",
      data: {
        name: `Start - ${step.data.title}`,
      },
    },
    ...step.children.map((interaction: any) => {
      const data = interaction.data as InteractionData;
      
      return {
        name: data.interactionType,
        data: { 
          ...data.properties,
          name: data.title
        }
      };
    }),
    {
      name: "complete",
      data: {
        completion_effect: "skip_all_steps",
      },
    },
  ];
};
