import projectTemplate from "./../UnrealEngineCreator/Data/project-template.json";
export type CompanyKey = keyof typeof projectTemplate.company_short_names;

export function AllCompanies(): CompanyKey[] {
  return Object.keys(projectTemplate.company_short_names) as CompanyKey[];
}

export function CompanyShortNameOf(company: CompanyKey): string {
  return projectTemplate.company_short_names[company];
}
