import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, writeBatch } from "firebase/firestore";
import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAK4ie9Dr7SldXUaHHHsca0zShsgr4XkJ4",
  authDomain: "creator-tool-739da.firebaseapp.com",
  projectId: "creator-tool-739da",
  storageBucket: "creator-tool-739da.appspot.com",
  messagingSenderId: "461097765003",
  appId: "1:461097765003:web:32f6e986b721bf0c836c8e",
  measurementId: "G-31BZFY8RBH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app, "europe-west1");

const provider = new OAuthProvider("microsoft.com");
provider.setCustomParameters({
  tenant: "97dd2a9e-2b6e-423f-a761-55d719a94278",
});

export const signInWithMicrosoft = async () => {
  try {
    await signInWithPopup(auth, provider);
  } catch (error) {
    console.error("Login failed", error);
  }
};

// Generic functionality to add data to a collection
export const addCollectionAndDocuments = async (
  collectionKey: string,
  objectsToAdd: any[]
) => {
  const collectionRef = collection(firestore, collectionKey);
  const batchRef = writeBatch(firestore);

  objectsToAdd.forEach((obj: any) => {
    const newDocRef = doc(collectionRef);
    batchRef.set(newDocRef, obj);
  });

  return await batchRef.commit();
};

export enum EFirestoreCollection {
  ENTRIES = "entries",
}
