import { Vector3 } from "@babylonjs/core";

export enum ComponentType {
  Empty = "Empty",
  Part = "Part",
  DefaultSceneRoot = "DefaultSceneRoot",
  StaticMeshComponent = "StaticMeshComponent",
  InteractionGrabComponent = "InteractionGrabComponent",
  InteractionMoveComponent = "InteractionMoveComponent",
  InteractionRotateComponent = "InteractionRotateComponent",
}

export interface Component {
  id: string;
  value: ComponentValue;
  children: Component[];
}

export interface ComponentValue {
    component_name: string;
    component_class: ComponentType;
    attach_socket: "";
    attach_offset: "()";
    component_data: {};
}

export interface EmptyComponent extends Component {
  id: string;
  value: {
    component_name: string;
    component_class: ComponentType.Empty;
    attach_socket: "";
    attach_offset: "()";
    component_data: {};
  };
  children: Component[];
}

export interface PartComponent extends Component {
  id: string;
  value: {
    component_name: string;
    component_class: ComponentType.Part;
    attach_socket: "";
    attach_offset: "()";
    component_data: {};
  };
  children: Component[];
}

export interface DefaultSceneComponent extends Component {
  id: string;
  value: {
    component_name: "DefaultSceneRoot";
    component_class: ComponentType.DefaultSceneRoot;
    attach_socket: "";
    attach_offset: "()";
    component_data: {};
  };
  children: Component[];
}

export interface MeshComponent extends Component {
  id: string;
  value: {
    component_name: string;
    component_class: ComponentType.StaticMeshComponent;
    attach_socket: "";
    attach_offset: "()";
    component_data: {
      static_mesh: string;
    };
  };
  children: Component[];
}

// ==================== Interaction Components ====================

export interface InteractionComponent extends Component {
  id: string;
  value: ComponentValue;
  children: Component[];
}

export interface GrabComponent extends InteractionComponent {
  id: string;
  value: {
    component_name: string;
    component_class: ComponentType.InteractionGrabComponent;
    attach_socket: "";
    attach_offset: "()";
    component_data: {};
  };
  children: Component[];
}

export enum TranslationDirection {
  PositiveOnly = "PositiveOnly",
  NegativeOnly = "NegativeOnly",
  Bidirectional = "Bidirectional",
}
export interface MoveComponent extends InteractionComponent {
  id: string;
  value: {
    component_name: string;
    component_class: ComponentType.InteractionMoveComponent;
    attach_socket: "";
    attach_offset: "()";
    component_data: {
      axis_of_translation: Vector3;
      translation_direction: TranslationDirection;
      is_translation_limited: boolean;
      min_translation: number;
      max_translation: number;
    };
  };
  children: Component[];
}

export enum RotationDirection {
  ClockwiseOnly = "ClockwiseOnly",
  AntiClockwiseOnly = "AntiClockwiseOnly",
  Bidirectional = "Bidirectional",
}
export interface RotateComponent extends InteractionComponent {
  id: string;
  value: {
    component_name: string;
    component_class: ComponentType.InteractionRotateComponent;
    attach_socket: "";
    attach_offset: "()";
    component_data: {
      axis_of_rotation: Vector3;
      rotation_direction: RotationDirection;
      is_rotation_limited: boolean;
      max_clockwise_rotation_angle: number;
      max_anticlockwise_rotation_angle: number;
    };
  };
  children: Component[];
}
