import React, { useEffect, useState } from "react";
import DraggableHierarchy from "../../DraggableHierarchy/DraggableHierarchy";
import * as HIERARCHYHELPER from "../../DraggableHierarchy/helpers/DraggableHierarchyHelper";
import * as BABYLON from "@babylonjs/core";
import * as SCENEHELPER from "../../../components/part-editor/scene-hierarchy/SceneHierarchyHelper";
import GlobalState from "../../../components/device-viewport/classes/GlobalState";
import SceneHierarchyNode from "./SceneHierarchyNode";
import "./SceneHierarchy.scss";
import SceneManager from "../../device-viewport/classes/SceneManager";
import PartTransformNode from "../../device-viewport/classes/PartTransforNode";
import * as STORAGE from "../../../helpers/StorageHelper";

interface SceneHierarchyProps {
  sceneNodes: { [id: string]: HIERARCHYHELPER.HierarchyNode };
  setSceneNodes: (nodes: { [id: string]: HIERARCHYHELPER.HierarchyNode }) => void;
  selectedSceneNodeIds: string[];
  setSelectedSceneNodeIds: (selectedNodeIds: string[]) => void;
  updateCurrentDevice: () => void;
  isPlaceholder: boolean;
}

const SceneHierarchy: React.FC<SceneHierarchyProps> = ({
  sceneNodes,
  setSceneNodes,
  selectedSceneNodeIds,
  setSelectedSceneNodeIds,
  updateCurrentDevice,
  isPlaceholder,
}) => {
  // filter sceneNodes to only include parts and the root
  if (isPlaceholder) {
    const filterFn = (node: HIERARCHYHELPER.HierarchyNode) => {
      return node.data.type === SCENEHELPER.SceneNodeType.Part || node.data.transformNode.id === "__root__";
    };

    const filteredSceneNodes: { [id: string]: HIERARCHYHELPER.HierarchyNode } = {};
    
    Object.keys(sceneNodes).forEach((nodeId) => {
      const node = sceneNodes[nodeId];
      if (filterFn(node)) {
        filteredSceneNodes[nodeId] = node;
      }
    });

    // go through all filtered nodes and look at their children. If the children are not in the filtered nodes, remove them
    Object.keys(filteredSceneNodes).forEach((nodeId) => {
      const node = filteredSceneNodes[nodeId];
      node.childrenIds = node.childrenIds.filter((childId) => filteredSceneNodes[childId]);
    });
    
    sceneNodes = filteredSceneNodes;
  }

  /**
   * The node currently hovered over in the scene hierarchy.
   */
  const [hoveredSceneNodeId, setHoveredSceneNodeId] = useState<string | null>(null);

  /**
   * The node currently hovered over in the scene hierarchy.
   */
  const [hiddenNodeIds, setHiddenNodeIds] = useState<string[]>(() => {
    const hiddenNodeIds: string[] = STORAGE.loadFromLocalStorage("hiddenNodeIds");

    if (hiddenNodeIds) {
      hiddenNodeIds.forEach((nodeId) => {
        const node = sceneNodes[nodeId];
        if (!node) {
          return;
        }

        const transformNode = (node.data as SCENEHELPER.SceneNodeData).transformNode;
        SceneManager.setNodeisVisible(transformNode, false);
      });

      return hiddenNodeIds;
    }

    return [];
  });

  // ====================================================================================================

  /**
   * Determines whether the dragged node can be dropped on the hovered node.
   * @param draggedNode The node being dragged.
   * @param dragData The drag data containing the hovered node and the height percentage.
   * @returns True if the dragged node can be dropped on the hovered node, false otherwise.
   */
  const canDrop = (draggedNode: HIERARCHYHELPER.HierarchyNode | null, dragData: HIERARCHYHELPER.DragData) => {
    if (!draggedNode) {
      return false;
    }

    if (draggedNode.data.type !== SCENEHELPER.SceneNodeType.Part) {
      return false;
    }
    
    const hoveredNode = dragData.hoveredNode;
    if (!hoveredNode) {
      return false;
    }

    // check if above root node
    if (hoveredNode.parentId === null && dragData.heightPercentage! < 30) {
      return false;
    }

    return true;
  };

  /**
   * Handle whenever a part is selected in the scene hierarchy.
   * @param nodeIds The selected node in the scene hierarchy.
   */
  const onPartSelectedInHierarchy = (nodeIds: string[]) => {
    const globalState = GlobalState.getInstance();
    const babylonNodes: BABYLON.TransformNode[] = [];

    nodeIds.forEach((nodeId) => {
      const node = sceneNodes[nodeId];
      if (!node) {
        return;
      }

      const transformNode = (node.data as SCENEHELPER.SceneNodeData).transformNode;

      if (transformNode instanceof PartTransformNode) {
        const partChildren = transformNode.getChildren();
        partChildren.forEach((child) => {
          babylonNodes.push(child as BABYLON.TransformNode);
        });
      } else {
        babylonNodes.push(transformNode);
      }
    });

    globalState.onUIPartsSelected.emit(babylonNodes);

    setSelectedSceneNodeIds(nodeIds);
  };

  const onDropped = (draggedNode: HIERARCHYHELPER.HierarchyNode, newParent: HIERARCHYHELPER.HierarchyNode) => {
    const draggedTrnsformNode = (draggedNode.data as SCENEHELPER.SceneNodeData).transformNode;
    const newParentTransformNode = (newParent.data as SCENEHELPER.SceneNodeData).transformNode;
    SceneManager.changePartParent(draggedTrnsformNode, newParentTransformNode);
    updateCurrentDevice();
  };

  const toggleNodeVisibility = (nodeId: string) => {
    const willBeHidden = hiddenNodeIds.indexOf(nodeId) === -1;

    // update the hidden node ids
    if (willBeHidden) {
      setHiddenNodeIds([...hiddenNodeIds, nodeId]);
    } else {
      setHiddenNodeIds(hiddenNodeIds.filter((id) => id !== nodeId));
    }

    const node = sceneNodes[nodeId];
    if (!node) {
      return;
    }

    // unselect the node if it is selected
    if (willBeHidden && selectedSceneNodeIds.indexOf(nodeId) !== -1) {
      const successors = HIERARCHYHELPER.getSuccessors(node, sceneNodes);
      const newSelectedNodeIds = selectedSceneNodeIds.filter((id) => !successors.includes(sceneNodes[id]));
      onPartSelectedInHierarchy(newSelectedNodeIds);
    }

    // update the visibility of the node in the scene
    const transformNode = (node.data as SCENEHELPER.SceneNodeData).transformNode;
    SceneManager.setNodeisVisible(transformNode, !willBeHidden);
  };

  // ====================================================================================================

  /**
   * Listening to the 3D viewport having a mesh loaded.
   */
  useEffect(() => {
    const globalState = GlobalState.getInstance();

    const onPartsSelectedIn3D = (babylonNodes: BABYLON.TransformNode[]) => {
      const selectedNodeIds: string[] = [];
      babylonNodes.forEach((node) => {
        const sceneNode = sceneNodes[node.id];
        if (!sceneNode) {
          return;
        }

        // check if its a part
        if (sceneNode.parentId) {
          const parent = sceneNodes[sceneNode.parentId];
          if ((parent.data as SCENEHELPER.SceneNodeData).type === SCENEHELPER.SceneNodeType.Part) {
            selectedNodeIds.push(parent.id);
          }
        }

        selectedNodeIds.push(sceneNode.id);
      });

      setSelectedSceneNodeIds(selectedNodeIds);
    };

    globalState.onPartSelected.on("SceneHierarchyOnPartSelected", onPartsSelectedIn3D);

    return () => {
      globalState.onPartSelected.off("SceneHierarchyOnPartSelected");
    };
  }, [sceneNodes]);

  useEffect(() => {
    // save hidden node ids to local storage
    STORAGE.saveToLocalStorage("hiddenNodeIds", hiddenNodeIds);
  }, [hiddenNodeIds]);

  // ====================================================================================================

  return (
    <div className="scene-hierarchy">
      <DraggableHierarchy
        nodes={sceneNodes}
        setNodes={setSceneNodes}
        selectedNodeIds={selectedSceneNodeIds}
        setSelectedNodeIds={onPartSelectedInHierarchy}
        hoveredNodeId={hoveredSceneNodeId}
        setHoveredNodeId={setHoveredSceneNodeId}
        canDrop={canDrop}
        nodeContentRender={(node) => {
          return (
            <SceneHierarchyNode
              node={node}
              selectedNodeIds={selectedSceneNodeIds}
              hoveredNodeId={hoveredSceneNodeId}
              hiddenNodeIds={hiddenNodeIds}
              toggleNodeVisibility={toggleNodeVisibility}
            />
          );
        }}
        leafIconOverride={(node) => {
          return <></>;
        }}
        onDropped={onDropped}
      />
    </div>
  );
};

export default SceneHierarchy;
