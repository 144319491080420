import React from "react";
import "./ConfirmDialog.scss";
import ReactDOM from "react-dom";

interface ConfirmDialogProps {
  title: string;
  message: string;
  onCancel?: () => void;
  onConfirm: () => void;
}

const portalRoot = document.getElementById("root");

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ title, message, onCancel, onConfirm }) => {
  return (
    portalRoot &&
    ReactDOM.createPortal(
      <div className="confirm-dialog-backdrop">
        <div className="confirm-dialog-overlay">
          <h1>{title}</h1>
          <p>{message}</p>
          <div className="confirm-dialog-buttons">
            {onCancel && (
              <button className="secondary" onClick={onCancel}>
                Cancel
              </button>
            )}

            <button className="primary" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </div>,
      portalRoot
    )
  );
};

export default ConfirmDialog;
