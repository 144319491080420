import Entity from "../../babylon/classes/Entity";
import * as BABYLON from "@babylonjs/core";
import GlobalState from "./GlobalState";
import MeshUtils from "./MeshUtils";
import sha256 from 'crypto-js/sha256';
import { Device } from "../../../interfaces/Device";
import { Component, ComponentType, GrabComponent, InteractionComponent, MoveComponent, RotateComponent } from "../../../interfaces/Component";
import PartTransformNode from "./PartTransforNode";

export default class SceneObject extends Entity{

    private mMeshUrl: string;
    private mGlobalState = GlobalState.getInstance();
    private mDeviceData?: Device;
    
    public meshRoot!: BABYLON.TransformNode;
	
    constructor(meshUrl: string, deviceData?: Device) {
		super("SceneObject");
        this.mMeshUrl = meshUrl;
        this.mDeviceData = deviceData;
	}

    begin(): void {
        if(this.mMeshUrl){
            BABYLON.SceneLoader.Append(
                this.mMeshUrl,
                undefined,
                this.getScene(),
                (scene) => {
                    scene.lights.forEach((light) => {
                        light.dispose();
                    });
    
                    scene.cameras.forEach((camera) => {
                        camera.dispose();
                    });
    
                    this.meshRoot = scene.getNodeByName("__root__") as BABYLON.AbstractMesh
    
                    const setupEnvironment = () => {
                        const bgh = this.getScene().getNodeByName("BackgroundHelper");
                        bgh?.dispose();
    
                        const bound = MeshUtils.getMeshSizeAndCenter([this.meshRoot]);
                        const size = bound.meshSize;
                        
                        //Normalizing the scale  of the objects that are imported into the scene
                        const scaleFactor = 1/(bound.radius*2)

                        this.meshRoot.scaling =  new BABYLON.Vector3(scaleFactor * this.meshRoot.scaling._x, scaleFactor * this.meshRoot.scaling._y, scaleFactor * this.meshRoot.scaling._z);
    
                        const helper = scene.createDefaultEnvironment({
                            groundColor: BABYLON.Color3.FromHexString("#ffffff"),
                            createSkybox: false,
                            groundShadowLevel: 0.6,
                            groundSize: Math.max(1, 1, 1),
                        });

                        this.mGlobalState.onViewportResized.emit(null);
    
                        this.getScene().unregisterAfterRender(setupEnvironment);
                    };
    
                    this.getScene().registerAfterRender(setupEnvironment);
                    
                    this.meshRoot.parent = this;
    
                    let deviceHierarchy = this.assignUniqueIDs(this.meshRoot as BABYLON.TransformNode);
    
                    if(this.mDeviceData){
                        this.deserialize();
                    }
                    this.mGlobalState.onViewportResized.emit(null);
                    this.mGlobalState.onMeshLoaded.emit(deviceHierarchy);
                    
                    
                },
                null, // onProgress callback
                null, // onError callback
                ".glb"
            );
        }
    }

    update(delta: number): void {
        
    }

    deserialize(){
        let iterateThroughComponents = (component: Component, parentNode: BABYLON.Nullable<BABYLON.Node>)=>{
            
            let scene = this.getScene();
            let part = parentNode as PartTransformNode;
            
            
            switch (component.value.component_class){
                case ComponentType.Empty:
                    break;
                case ComponentType.Part:
                    console.log("Found part")
                    let partTransformNode = this.newEntity(PartTransformNode, component.value.component_name)
                    partTransformNode.id = component.id;                    
                    for (let child of component.children){
                        let childNode = scene.getNodeById(child.id);
                        if(childNode && childNode instanceof BABYLON.TransformNode){
                            partTransformNode.setAbsolutePosition(childNode.getAbsolutePosition().clone());
                            break;
                        }    
                    }

                    //partTransformNode.parent =  this.meshRoot;

                    break;
                case ComponentType.DefaultSceneRoot:
                    break;
                case ComponentType.StaticMeshComponent:
                    break;
                case ComponentType.InteractionGrabComponent:
                    part.addInteractionComponent(component as GrabComponent);
                    break;
                case ComponentType.InteractionMoveComponent:
                    part.addInteractionComponent(component as MoveComponent);
                    break;
                case ComponentType.InteractionRotateComponent:
                    part.addInteractionComponent(component as RotateComponent);
                    break;
                default:
            }
            let currentNode = scene.getNodeById(component.id);
            
            if(currentNode){
                (currentNode as BABYLON.TransformNode).setParent(parentNode);
            }
            
            component.children.forEach((child: Component)=>{
                iterateThroughComponents(child, currentNode)
            });
        }

        if(this.mDeviceData){
            this.mDeviceData.component_hierachy.root.children.forEach(childComponent => {
                iterateThroughComponents(childComponent, this.meshRoot)
            });
        }
        
    }

    generateUniqueID(transformNode: BABYLON.TransformNode){
        
        const propertiesToInclude: string[] = [
            transformNode.name,
            transformNode.parent? transformNode.parent.name : "",
            transformNode.position.toString(),
            transformNode.rotation.toString(),
            transformNode.scaling.toString(),
        ];

        if (transformNode instanceof BABYLON.AbstractMesh) {
            propertiesToInclude.push(
                transformNode.getTotalVertices().toString(),
                transformNode.getTotalIndices().toString(),
            );
            //transformNode.name
        }
    
    
        const stringToHash = propertiesToInclude.join('|');
        return sha256(stringToHash).toString();
    }

    assignUniqueIDs(transformNode: BABYLON.TransformNode): BABYLON.TransformNode {
        transformNode.id = MeshUtils.generateFullPathID(transformNode, this)
		transformNode.getChildTransformNodes(true).forEach((child) => {
			this.assignUniqueIDs(child);
		});

		return transformNode;
	}
}