import * as BABYLON from '@babylonjs/core';
import Entity from "../../babylon/classes/Entity";
import { Camera } from 'three';
import GlobalState from './GlobalState';

export type VisualizerData = {
    axis: BABYLON.Vector3;
    min: number;
    max: number;
}

export default class AxisVisualizer extends Entity{
    private mVizElements: BABYLON.TransformNode[] = [];
    private mRotationVizMaterial!: BABYLON.StandardMaterial;
    private mTranslationVizMaterial!: BABYLON.StandardMaterial;
    private mUtilityLayer: BABYLON.UtilityLayerRenderer;
    private mTranslationVizRoot!: BABYLON.TransformNode;
    private mRotationVizRoot!: BABYLON.TransformNode;
    
    constructor(utilityLayer: BABYLON.UtilityLayerRenderer){
        super('AxisVisualizer');
        this.mUtilityLayer = utilityLayer;
    }

    begin(): void {

        this.mRotationVizMaterial = new BABYLON.StandardMaterial("rotVisMaterial", this.mUtilityLayer.utilityLayerScene);
        this.mRotationVizMaterial.emissiveColor = new BABYLON.Color3(0.1, 0.5, 1);
        this.mRotationVizMaterial.disableLighting = true;
        //this.mRotationVizMaterial.disableDepthWrite = true;

        this.mTranslationVizMaterial = new BABYLON.StandardMaterial("locVisMaterial", this.mUtilityLayer.utilityLayerScene);
        this.mTranslationVizMaterial.emissiveColor = new BABYLON.Color3(1, 1, 0);
        this.mTranslationVizMaterial.disableLighting = true;;

        const camera = this.mUtilityLayer.utilityLayerScene.activeCamera;

        this.mUtilityLayer.utilityLayerScene.onBeforeRenderObservable.add(() => {
            const distance = BABYLON.Vector3.Distance(camera!.position, this.getAbsolutePosition());
            const scaleFactor = distance / 10;

            this.scaling = new BABYLON.Vector3(scaleFactor, scaleFactor, scaleFactor);
        })
    }
    
    createArrowhead(position: BABYLON.Vector3, direction: BABYLON.Vector3) {
        const arrowhead = BABYLON.MeshBuilder.CreateCylinder("arrowhead", {
            diameterTop: 0,
            diameterBottom: 0.2, // Adjust size as needed
            height: 0.2,
            tessellation:32,
            updatable: false
        }, this.mUtilityLayer.utilityLayerScene);

        // Position the arrowhead
        arrowhead.position = position;

        // Rotate the arrowhead to align with the direction
        const axis = new BABYLON.Vector3(0, 1, 0); // Assuming Y is up
        const angle = Math.acos(BABYLON.Vector3.Dot(axis, direction.normalize()));
        const cross = BABYLON.Vector3.Cross(axis, direction.normalize());
        arrowhead.rotationQuaternion = BABYLON.Quaternion.RotationAxis(cross, angle);

        return arrowhead;
    }
    
    createTranslationVisualizer(data: VisualizerData) {
        this.mTranslationVizRoot = new BABYLON.TransformNode('TranslationVizRoot', this.mUtilityLayer.utilityLayerScene);
        this.mTranslationVizRoot.parent = this;
        
        const { axis, min, max } = data;
        const scene = this.mUtilityLayer.utilityLayerScene;

        const adjustedMin = Math.min((min/10) - 0.5, -1);
        const adjustedMax = Math.max((max/10) + 0.5, 1);

        // Create a line to represent the axis of translation
        const line = BABYLON.MeshBuilder.CreateLines("translationLine", {
            points: [
                axis.scale(adjustedMin),
                axis.scale(adjustedMax)
            ]
        }, scene); 
        line.parent = this.mTranslationVizRoot;
        line.renderingGroupId = 1;
        line.color = new BABYLON.Color3(1, 1, 0);
        ///line.material = this.mTranslationVizMaterial;
        // Create arrowheads at the end of the line
        // Assuming you have a function to create arrowheads
        const arrowHead1 = this.createArrowhead(axis.scale(adjustedMin), axis.negate());
        const arrowHead2 = this.createArrowhead(axis.scale(adjustedMax), axis);

        arrowHead1.parent = this.mTranslationVizRoot;
        arrowHead2.parent = this.mTranslationVizRoot;

        arrowHead1.material = this.mTranslationVizMaterial;
        arrowHead2.material = this.mTranslationVizMaterial;

        arrowHead1.renderingGroupId = 1;
        arrowHead2.renderingGroupId = 1;

        

        const planeSize = 0.25; // Adjust based on visualizer scale
        const minPlane = BABYLON.MeshBuilder.CreatePlane("minPlane", { size: planeSize, sideOrientation: BABYLON.Mesh.DOUBLESIDE }, scene);
        const maxPlane = BABYLON.MeshBuilder.CreatePlane("maxPlane", { size: planeSize, sideOrientation: BABYLON.Mesh.DOUBLESIDE }, scene);

        minPlane.parent = this.mTranslationVizRoot;
        maxPlane.parent = this.mTranslationVizRoot;

        minPlane.renderingGroupId = 1;
        maxPlane.renderingGroupId = 1;

        minPlane.material = this.mTranslationVizMaterial;
        maxPlane.material = this.mTranslationVizMaterial;


        let normalToAxis = BABYLON.Vector3.Zero();
        if (!BABYLON.Vector3.Zero().equals(axis)) {
            // If the axis isn't zero, calculate a normal
            // This assumes that axis is already normalized
            normalToAxis = BABYLON.Vector3.Cross(axis, BABYLON.Vector3.Up()).normalize();
            if (normalToAxis.length() === 0) {
                // If the cross product is zero, the axis is parallel to Up vector.
                // Use another vector for the cross product.
                normalToAxis = BABYLON.Vector3.Cross(axis, BABYLON.Vector3.Right()).normalize();
            }
        }

        // Align the planes with the normal vector
        minPlane.lookAt(axis);
        maxPlane.lookAt(axis);

        // Position the limit planes
        minPlane.position = axis.scale(min/10);
        maxPlane.position = axis.scale(max/10);

        this.mVizElements.push(arrowHead1, arrowHead2, line, minPlane, maxPlane);

        return { arrowHead1, arrowHead2, line, minPlane, maxPlane };
    }

    createRotationVisualizer(data: VisualizerData) {
        if(data.max - data.min === 0){
            return
        }

        this.mRotationVizRoot = new BABYLON.TransformNode('RotationVizRoot', this.mUtilityLayer.utilityLayerScene);
        this.mRotationVizRoot.parent = this;       
        
        const { axis, min, max } = data;

        function degreesToRadians(degrees: number) {
            return degrees * (Math.PI / 180);
        }
        
        const globalState =  GlobalState.getInstance();
        const highlightLayer = globalState.highlightLayer;

        // Create arcs to represent the rotation angles
        const rotationDisc1 = BABYLON.MeshBuilder.CreateDisc("rotationDisc1", {
            radius: 2, // Set the desired radius
            tessellation: 32,
            arc: degreesToRadians(max) / (2 * Math.PI), // Arc is a fraction of the full circle
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false // Set to true if the disc will be updated dynamically
        }, this.mUtilityLayer.utilityLayerScene);
        
        this.mRotationVizRoot.lookAt(axis);
        rotationDisc1.material = this.mRotationVizMaterial;
        rotationDisc1.isPickable = false;
        rotationDisc1.renderingGroupId = 1;

        rotationDisc1.parent = this.mRotationVizRoot;

        this.mVizElements.push(rotationDisc1);
        highlightLayer.addExcludedMesh(rotationDisc1);

        const rotationDisc2 = BABYLON.MeshBuilder.CreateDisc("rotationDisc1", {
            radius: 2, // Set the desired radius
            tessellation: 32,
            arc: Math.abs(degreesToRadians(min)) / (2 * Math.PI), // Arc is a fraction of the full circle
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false // Set to true if the disc will be updated dynamically
        }, this.mUtilityLayer.utilityLayerScene);
        
        rotationDisc2.scaling = new BABYLON.Vector3(1,-1,1)
        rotationDisc2.material = this.mRotationVizMaterial;
        rotationDisc2.isPickable = false;
        rotationDisc2.renderingGroupId = 1;

        rotationDisc2.parent = this.mRotationVizRoot;

        this.mVizElements.push(rotationDisc2);
        highlightLayer.addExcludedMesh(rotationDisc2);

        return { rotationDisc1, rotationDisc2 };
    }

    updateVisualizer(data: VisualizerData, isRotation: boolean){
        this.mTranslationVizRoot?.dispose();
        this.mRotationVizRoot?.dispose();
        if(isRotation){
            //this.createRotationVisualizer(data);
            this.createTranslationVisualizer(data);
        } else {
            this.createTranslationVisualizer(data);
        }
    }

    dispose(): void {
        super.dispose();
        this.mRotationVizMaterial?.dispose();
        this.mTranslationVizMaterial?.dispose();
    }
}