import React from "react";
import "./ComponentEmptyProperties.scss";

interface ComponenentEmptyPropertiesProps {}

const ComponenentEmptyProperties: React.FC<ComponenentEmptyPropertiesProps> = ({}) => {
  return (
    <div className="component-empty">
      <h3>This interaction has no further definitions</h3>
    </div>
  );
};

export default ComponenentEmptyProperties;
