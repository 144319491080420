import React from "react";
import "./EditableDropdownField.scss";

interface EditableDropdownFieldProps {
  value: string;
  reference: string;
  options: { value: string; label: string }[];
  onSave: (value: any, reference: string) => void;
}

const EditableDropdownField: React.FC<EditableDropdownFieldProps> = ({ value, reference, options, onSave }) => {
  const valueExists = options.find((option) => option.value === value) !== undefined;

  if (!valueExists) {
    options = [{ value, label: value }, ...options];
  }

  return (
    <select
      title="some-name"
      className="custom-dropdown"
      value={value}

      onChange={(e) => onSave(e.target.value, reference)}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default EditableDropdownField;
