import { Component, ComponentType } from "../../interfaces/Component";
import { Device } from "../../interfaces/Device";
import { loadFromLocalStorage } from "../StorageHelper";
import * as MODULEEXPORT from "./ModuleExportHelper";
import * as BABYLON from "@babylonjs/core";
import * as PROJECTTEMPLATEHELPER from "../ProjectTemplateHelper"
import * as HIERARCHYHELPER from "../../components/DraggableHierarchy/helpers/DraggableHierarchyHelper";
import { InteractionData } from "../../components/storyline-builder/StorylineEditor/storyline-builder-steps/InteractionProperties";
import featureData from "../../components/storyline-builder/StorylineEditor/storyline-builder-steps/Interactions/Features.json"

export function createJSON() {
  return {
    project_name: exportName(),
    project_short_name: exportNameShort(),
    company: exportCompany(),
    company_short_name: exportCompanyShort(),
    description: exportDescription(),
    environment_imports: exportEnvironmentImport(),
    content_plugin_name: `C_${exportCompanyShort()}_${exportNameShort()}`,
    features: exportFeatures(),
    devices: exportDevices(),
    modules: MODULEEXPORT.exportModules(),
  };
}

const shortenString = (str: string) => {
  return str.replaceAll(/ /g, "");
};

const exportName = () => {
  return loadFromLocalStorage("projectName") || "Untitled Project";
};

const exportNameShort = () => {
  return shortenString(exportName()); // TODO: shorten
};

const exportCompany = () => {
  return loadFromLocalStorage("company");
};

const exportCompanyShort = () => {
  return PROJECTTEMPLATEHELPER.CompanyShortNameOf(exportCompany()); // TODO: get from config
};

const exportDescription = () => {
  return loadFromLocalStorage("description");
};

const exportEnvironmentImport = () => {
  // NOTE: this is a hardcoded import for the cleanroom environment for now
  return [
    "import rw1corebinaries/Plugins/RDK/Environments/E_CleanRoomEnv_1/... //ContentLibraries/cl-rdk-env-cleanroom-1/E_CleanRoomEnv_1/...",
  ];
};

const exportFeatures = () => {
  const features: Array<{ feature_name: string; plugin_name: string; import_paths: string[]; feature_type: number }> = [];
  const featureNames = new Set<string>();
  features.push({
    feature_name: "Linear Training",
    plugin_name: "T_LinearTraining",
    import_paths: [
      "import rw1corebinaries/Plugins/RDK/Templates/T_LinearTraining/... //ContentLibraries/cl-rdk-templates-lineartraining/T_LinearTraining/...",
    ],
    feature_type: 2, // 2 = template, 1 = plugin
  });
  featureNames.add("Linear Training Template");

  const storyline = loadFromLocalStorage("storylineNodes");
  if (!storyline) return features;


  const tree = HIERARCHYHELPER.constructTree(storyline);
  const root = tree[0];

  const uniqueInteractions = new Set<string>()
  root.children.forEach(module => {
      module.children.forEach(section => {
        section.children.forEach(step => {
          step.children.forEach(interaction => {
            const data = interaction.data as InteractionData;
            if (data.interactionType !== null) {
              uniqueInteractions.add(data.interactionType)
            }
          })
        })
      })
  });

  uniqueInteractions.forEach(interaction => {
    const feature = featureData.find(feature => feature.steps.find(step => step.context === interaction));
    if (feature !== undefined && !featureNames.has(feature.name)) {
      features.push({
        feature_name: feature.name,
        plugin_name: feature.plugin_name,
        import_paths: [feature.import_path],
        feature_type: 1
      });
      featureNames.add(feature.name);
    }
  });
  return features;
};

const exportDevices = () => {
  const devices = loadFromLocalStorage("devices");
  if (!devices) {
    return {};
  }

  const deviceObj: { [key: string]: {} } = {};

  Object.keys(devices).forEach((id) => {
    const device = devices[id].device;
    deviceObj[id] = {
      id: id,
      name: shortenString(device.name), // TODO: shorten?
      parts: getParts(device),
    };
  });

  return deviceObj;
};

const getParts = (device: Device) => {
  const allPartsOfDevice = [] as any[];

  const parseComponent = (component: Component, parent: Component | null) => {
    component.children.forEach((child) => {
      parseComponent(child, component);
    });
    
    if (component.value.component_class !== ComponentType.Part) {
      return;
    }

    if (!parent) {
      throw new Error("Part has no parent component");
    }

    const getComponentData = (component: Component) => {
      const compData = {
        value: {
          component_name: component.value.component_name,
          component_class: component.value.component_class !== ComponentType.InteractionRotateComponent ? component.value.component_class : ComponentType.InteractionMoveComponent,
          attach_socket: "",
          attach_offset: "()",
          component_data: component.value.component_data,
        },
        children: [],
      };
      
      if ("axis_of_rotation" in compData.value.component_data ) {
        const vector = compData.value.component_data.axis_of_rotation as BABYLON.Vector3;
        compData.value.component_data.axis_of_rotation = `(X=${vector._z},Y=${vector._x},Z=${-vector._y})`;
      }
  
      if ("axis_of_translation" in compData.value.component_data ) {
        const vector = compData.value.component_data.axis_of_translation as BABYLON.Vector3;
        compData.value.component_data.axis_of_translation = `(X=${vector._x},Y=${vector._z},Z=${-vector._y})`;
      }

      return compData;
    };

    allPartsOfDevice.push({
      parent_device_id: device.id,
      parent_device: null, // TODO: what
      id: "", // TODO: what
      name: shortenString(component.value.component_name),
      parent_component:
        parent.value.component_class === ComponentType.DefaultSceneRoot ? "DefaultSceneRoot" : shortenString(parent.value.component_name), // TODO
      has_custom_root: false,
      parent_class: "Actor",
      tag: `${shortenString(device.name)}.${shortenString(component.value.component_name)}`,
      class_data: {},
      component_hierarchy: {
        root: {
          value: {
            component_name: "DefaultSceneRoot",
            component_class: "DefaultSceneRoot",
            attach_socket: "",
            attach_offset: "()",
            component_data: [],
          },
          children: component.children.map((child) => {
            return getComponentData(child);
          }),
        },
      },
    });
  };

  parseComponent(device.component_hierachy.root, null);

  return allPartsOfDevice;
};
