import * as BABYLON from '@babylonjs/core';
import Entity from '../../babylon/classes/Entity';
import { Component, InteractionComponent } from '../../../interfaces/Component';

export default class PartTransformNode extends Entity{
    private mComponents: InteractionComponent[] = [];
    
    constructor(name: string){
        super(name);
    }

    begin(): void {
        
    }

    update(delta: number): void {
        
    }

    serialize() {
        super.serialize();
    }

    deserialize(data: any){
        super.deserialize(data);
    }

    getInteractionComponents(): InteractionComponent[] {
        return this.mComponents;
    }

    addInteractionComponent(component: InteractionComponent){
        this.mComponents.push(component);
    }

    removeInteractionComponent(component: InteractionComponent){
        this.mComponents = this.mComponents.filter((c) => c.id !== component.id);
    }
}
