import * as BABYLON from "@babylonjs/core"
import React, { Component } from 'react';
import Entity from './classes/Entity';

export type SceneEventArgs = {
    engine: BABYLON.Engine;
    scene: BABYLON.Scene;
    canvas: HTMLCanvasElement;
};

export type BabylonSceneProps = {
    engineOptions?: BABYLON.EngineOptions;
    adaptToDeviceRatio?: boolean;
    onSceneMount?: (args: SceneEventArgs) => void;
    onSceneTick: () => any;
    context: any;
    width?: number;
    height?: number;
};

export default class BabylonScene extends Component<BabylonSceneProps> {
    private _engine!: BABYLON.Engine;

    private _rootScene!: BABYLON.Scene;

    private _canvasRef: React.RefObject<HTMLCanvasElement>;

    private _canvasZoneRef: React.RefObject<HTMLDivElement>;

    public constructor(props: BabylonSceneProps) {
        super(props);
        this._canvasRef = React.createRef<HTMLCanvasElement>();
        this._canvasZoneRef = React.createRef<HTMLDivElement>();
    }

    private initEngine() {
        // Initialize engine, sets the canvas component and other options.
        this._engine = new BABYLON.Engine(
            this._canvasRef.current,
            true,
            this.props.engineOptions,
            true,
        );

        // Initialize the root scene.
        this._rootScene = new BABYLON.Scene(this._engine);

        // Resize the engine;
        window.addEventListener('resize', () => {
            this._engine.resize();
        });

        // Call the custom Scene function. If not available, throw error.
        if (typeof this.props.onSceneMount === 'function') {
            this.props.onSceneMount({
                scene: this._rootScene,
                engine: this._engine,
                canvas: this._canvasRef.current!,
            });
        } else {
            console.error('onSceneMount function not available');
        }

        // Start render loop.
        this._engine.runRenderLoop(() => {
            this._rootScene.getNodes().forEach((node) => {
                if (node instanceof Entity) {
                    const entity = node as Entity;
                    if (entity.isInitialized === false) {
                        entity._internalIsInitalized = true;
                        entity._internalScene = this._rootScene;
                        entity.begin();
                    }
                    if (entity.isUpdateEnabled) {
                        entity.update(this._engine.getDeltaTime());
                    }
                }
            });

            if (this._rootScene) {
                this._rootScene.render();
            }

            this._engine.setHardwareScalingLevel(1 / window.devicePixelRatio);

            const canvas = this._canvasRef.current;
            const zone = this._canvasZoneRef.current;
            if (canvas?.width !== zone?.clientWidth || canvas?.height !== zone?.clientHeight) {
                this._engine.resize();
            }
            this.props.onSceneTick();
        });
    }

    componentDidMount() {
        this.initEngine();
        // @IgorAPM - Refresh UI after mount component.
    }

    componentWillUnmount(): void {
        this._engine.dispose();
    }

    render() {
        return (
            <div
                ref={this._canvasZoneRef}
                style={{
                    width: '100%',
                    height: '100%',
                    padding: 0,
                    margin: 0,
                    overflow: 'unset',
                    border: 'none',
                    outline: 'none',
                    display: 'block',
                }}
            >
                <canvas
                    id="babylonjs"
                    width={this._canvasZoneRef.current?.clientWidth}
                    height={this._canvasZoneRef.current?.clientHeight}
                    ref={this._canvasRef}
                    style={{
                        width: '100%',
                        height: '100%',
                        padding: 0,
                        margin: 0,
                        overflow: 'unset',
                        border: 'none',
                        outline: 'none',
                        display: 'block',
                    }}
                />
            </div>
        )
    }
}