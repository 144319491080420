import React from "react";
import { IconArrowDown, IconArrowUp } from "../../../../icons/Icons";
import "./ComponentPropertyContainer.scss";

interface ComponenentPropertyContainerProps {
  label: string;
  onExpand?: () => void;
  onCollapse?: () => void;
  children?: React.ReactNode;
  allowExpanding?: boolean;
  disabled?: boolean;
}

const ComponenentPropertyContainer: React.FC<ComponenentPropertyContainerProps> = ({
  label,
  onCollapse,
  onExpand,
  children,
  allowExpanding,
  disabled,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleIsExpanded = () => {
    if (allowExpanding !== undefined && !allowExpanding) {
      if (isExpanded) {
        setIsExpanded(false);

        if (onCollapse) {
          onCollapse();
        }
      }

      return;
    }

    const willBeExpanded = !isExpanded;

    if (willBeExpanded && onExpand) {
      onExpand();
    }

    if (!willBeExpanded && onCollapse) {
      onCollapse();
    }

    setIsExpanded(willBeExpanded);
  };

  return (
    <div
      className="component-property-container"
      style={isExpanded ? { backgroundColor: "#f8f8fb" } : { backgroundColor: "transparent" }}
    >
      <div
        className="component-property-title"
        style={
          isExpanded
            ? { backgroundColor: "var(--light-primary-1-5)", color: "var(--light-secondary-l-white, #fff)" }
            : {}
        }
      >
        <div className="title">{label}</div>

        <button disabled={disabled} onClick={toggleIsExpanded}>
          {isExpanded ? (
            <IconArrowUp fill="var(--light-secondary-l-white, #fff)" />
          ) : (
            <IconArrowDown fill="var(--light-primary-1-5)" />
          )}
        </button>
      </div>

      {isExpanded && <div className="component-property-children">{children}</div>}
    </div>
  );
};

export default ComponenentPropertyContainer;
