import * as BABYLON from '@babylonjs/core';

export default class Entity extends BABYLON.TransformNode {
    /** @internal */
    public _internalIsInitalized: boolean;

    public get isInitialized(): boolean { return this._internalIsInitalized; }

    /** @internal */
    public _internalScene!: BABYLON.Scene;

    public get mScene(): BABYLON.Scene { return this._internalScene; }

    /** @internal */
    protected _isUpdateEnabled: boolean;

    public get isUpdateEnabled(): boolean { return this._isUpdateEnabled; }

    constructor(name: string) {
        super(name);
        this._internalIsInitalized = false;
        this._isUpdateEnabled = false;
    }

    begin(): void {
    }

    update(delta: number): void {

    }

    dispose(): void {
        super.dispose();
    }

    newEntity<T, Args extends any[]>(type: { new(...args: Args): T }, ...args: Args): T {
        const object = new type(...args);

        if (object instanceof Entity) {
            const entity = object as Entity;
            entity._internalScene = this.mScene;
        }
        return object;
    }

    serialize(): any{
        
    }

    deserialize<T extends any>(data: T){

    }
}