import React from "react";
import "./PropertyDetails.scss";
import { ComponentType, InteractionComponent, MoveComponent, RotateComponent } from "../../../interfaces/Component";
import { IconArrowLeft, IconBox, IconHand, IconMove, IconRotate } from "../../../icons/Icons";
import ComponentEmptyProperties from "./component-properties/ComponentEmptyProperties";
import MoveComponenentProperties from "./component-properties/MoveComponentProperties";
import RotateComponenentProperties from "./component-properties/RotateComponentProperties";
import PartTransformNode from "../../device-viewport/classes/PartTransforNode";
import GlobalState from "../../device-viewport/classes/GlobalState";

interface PropertyDetailsProps {
  part: PartTransformNode;
  component: InteractionComponent;
  setSelectedComponentId: (componentId: string | null) => void;
  updateCurrentDevice: () => void;
}

const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  part,
  component,
  setSelectedComponentId,
  updateCurrentDevice,
}) => {
  if (!component) {
    console.error("No component selected");
    return null;
  }
  return (
    <div className="property-details">
      <div className="title-container">
        <button
          onClick={() => {
            setSelectedComponentId(null);
          }}
        >
          <IconArrowLeft fill="var(--light-primary-1-5)" />
        </button>

        <div className="title">
          <div className="icon">
            {component.value.component_class === ComponentType.InteractionGrabComponent && (
              <>
                <IconHand fill="var(--light-primary-1-5)" />
                <h3>Gab-able</h3>
              </>
            )}
            {component.value.component_class === ComponentType.InteractionMoveComponent && (
              <>
                <IconMove fill="var(--light-primary-1-5)" />
                <h3>Move-able</h3>
              </>
            )}
            {component.value.component_class === ComponentType.InteractionRotateComponent && (
              <>
                <IconRotate fill="var(--light-primary-1-5)" />
                <h3>Rotate-able</h3>
              </>
            )}
          </div>
        </div>
      </div>

      {component.value.component_class === ComponentType.InteractionGrabComponent && <ComponentEmptyProperties />}
      {component.value.component_class === ComponentType.InteractionMoveComponent && (
        <MoveComponenentProperties
          part={part}
          component={component as MoveComponent}
          updateCurrentDevice={updateCurrentDevice}
        />
      )}
      {component.value.component_class === ComponentType.InteractionRotateComponent && (
        <RotateComponenentProperties
          part={part}
          component={component as RotateComponent}
          updateCurrentDevice={updateCurrentDevice}
        />
      )}
    </div>
  );
};

export default PropertyDetails;
