import { v4 as uuidv4 } from "uuid";

export interface HierarchyNode {
  id: string;
  parentId: string | null;
  childrenIds: string[];
  data: any;
  isCollapsed: boolean;
}

export interface DragData {
  hoveredNode: HierarchyNode | null;
  heightPercentage: number | null;
}

export function createNode(): HierarchyNode {
  return {
    id: uuidv4(),
    parentId: null,
    childrenIds: [],
    // level: 0,
    data: null,
    isCollapsed: false,
  };
}

export function addNode(
  parentId: string | null,
  newNode: HierarchyNode,
  nodes: Record<string, HierarchyNode>
): Record<string, HierarchyNode> {
  newNode.parentId = parentId;
  if (parentId) {
    nodes[parentId].childrenIds.push(newNode.id);
  }
  return { ...nodes, [newNode.id]: newNode };
}

export function deleteNode(nodeId: string, nodes: Record<string, HierarchyNode>): Record<string, HierarchyNode> {
  const updatedNodes = { ...nodes };

  // Recursive function to delete a node and its children
  const deleteNodeAndChildren = (id: string) => {
    // Delete children first
    updatedNodes[id].childrenIds.forEach(deleteNodeAndChildren);

    // Then delete the node
    delete updatedNodes[id];
  };

  // Find the parent
  const parentNodeId = nodes[nodeId].parentId;
  if (parentNodeId !== null) {
    // Remove the node id from the parent's childrenIds array
    updatedNodes[parentNodeId].childrenIds = updatedNodes[parentNodeId].childrenIds.filter((id) => id !== nodeId);
  }

  // Delete the node and its children
  deleteNodeAndChildren(nodeId);

  return updatedNodes;
}

// Utility function to find the parent node of a given node
export const findParent = (nodeId: string, nodes: HierarchyNode[]) => {
  return Object.values(nodes).find((node) => node.childrenIds.includes(nodeId));
};

// Function to find the position of a given node
export const findPosition = (node: HierarchyNode, nodes: HierarchyNode[]) => {
  let position = "";
  let currentNode = node;

  while (currentNode) {
    const parent = findParent(currentNode.id, nodes);
    if (!parent) break; // We've reached the root node

    const index = parent.childrenIds.indexOf(currentNode.id);
    position = index + 1 + (position ? "." + position : "");

    currentNode = parent;
  }

  return position;
};

export interface TreeNode extends HierarchyNode {
  children: TreeNode[];
}

export const constructTree = (nodes: { [id: string]: HierarchyNode }): TreeNode[] => {
  let tree: TreeNode[] = [];
  let nodeMap: { [id: string]: TreeNode } = {};

  // First map the nodes by their ID
  Object.values(nodes).forEach((node) => {
    nodeMap[node.id] = { ...node, children: [] } as TreeNode;
  });

  // Then construct the tree by pushing each node to its parent's children array
  Object.values(nodes).forEach((node) => {
    if (node.parentId) {
      nodeMap[node.parentId].children.push(nodeMap[node.id]);
    } else {
      tree.push(nodeMap[node.id]);
    }
  });

  return tree;
};

export const getSuccessors = (node: HierarchyNode, nodes: { [id: string]: HierarchyNode }): HierarchyNode[] => {
  let successors: HierarchyNode[] = [];

  const addSuccessors = (node: HierarchyNode) => {
    successors.push(node);
    node.childrenIds.forEach((childId) => addSuccessors(nodes[childId]));
  };

  addSuccessors(node);

  return successors;
};

export const updateNodes = (
  nodes: { [id: string]: HierarchyNode },
  newNode: HierarchyNode
): { [id: string]: HierarchyNode } => {
  return { ...nodes, [newNode.id]: newNode };
};

export const getNodeDepth = (node: HierarchyNode, nodes: { [id: string]: HierarchyNode }): number => {
  let depth = 0;
  let currentNode = node;

  while (currentNode.parentId) {
    currentNode = nodes[currentNode.parentId];
    depth++;
  }

  return depth;
};