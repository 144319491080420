import React from "react";
import EditableField from "../../../input/EditableField";
import { NodeType, StorylineData, createStorylineShortName } from "./StorylineDataProperties";
import { HierarchyNode } from "../../../DraggableHierarchy/helpers/DraggableHierarchyHelper";

export interface Module extends StorylineData {
  displayInfo: string;
  tags: string[];
}

export const getDefaultModule = (title: string): Module => ({
  title: title,
  type: NodeType.Module,
  shortName: createStorylineShortName(title), // default short name
  displayInfo: "Information about new module",
  tags: [],
});

interface ModulePropertiesProps {
  node: HierarchyNode;
  onSave: (property: string, newValue: any) => void;
}

const ModuleProperties: React.FC<ModulePropertiesProps> = ({ node, onSave }) => {
  const handleAdd = () => {
    let newItems = (node.data as Module).tags;
    if (newItems === undefined) {
      newItems = [];
    }
    const newItem = `Entry ${newItems.length + 1}`;
    newItems.push(newItem);
    (node.data as Module).tags = [...newItems];
    onSave("tags", newItems);
  };

  const handleRemove = (index: number) => {
    const newItems = (node.data as Module).tags;
    if (newItems === undefined) return;
    newItems.splice(index, 1);
    (node.data as Module).tags = [...newItems];
    onSave("tags", newItems);
  };

  const handleEdit = (index: number, newValue: any) => {
    const newItems = (node.data as Module).tags;
    newItems[index] = newValue;
    (node.data as Module).tags = [...newItems];
    onSave("tags", newItems);
  };

  return (
    <div>
      <h4>Title:</h4>
      <EditableField multiple={false} value={(node.data as Module).title} reference="" onSave={(newValue) => onSave("title", newValue)} />
      <h4>Short Name (optional):</h4>
      <EditableField multiple={false} value={(node.data as Module).shortName} reference="" onSave={(newValue) => onSave("shortName", newValue)} />
      <h4>Description:</h4>
      <EditableField multiple={false} value={(node.data as Module).displayInfo} reference="" onSave={(newValue) => onSave("displayInfo", newValue)} />
      <h4>Tags:</h4>
      <div className="input-wrapper">
        {(node.data as Module).tags.map((item: string, index: number) => (
          <div key={`${item}-${index}`}>
            <EditableField multiple={false} value={item} reference="" onSave={(value, reference) => handleEdit(index, value)} />
            <button onClick={() => handleRemove(index)}>Remove</button>
          </div>
        ))}
        <button onClick={() => handleAdd()}>Add Item</button>
      </div>
    </div>
  );
};

export default ModuleProperties;
