import React, { useEffect, useState } from "react";
import Slider from "react-input-slider";
import EditableDropdownField from "../../../input/EditableDropdownField";
import { MoveComponent, TranslationDirection } from "../../../../interfaces/Component";
import "./MoveComponentProperties.scss";
import ComponenentPropertyContainer from "./ComponentPropertyContainer";
import PartTransformNode from "../../../device-viewport/classes/PartTransforNode";
import * as BABYLON from "@babylonjs/core";
import GlobalState from "../../../device-viewport/classes/GlobalState";
import { VisualizerData } from "../../../device-viewport/classes/AxisVisualizer";
import { GizmoType } from "../../../device-viewport/classes/GizmoController";
import MeshUtils from "../../../device-viewport/classes/MeshUtils";
import EditableField from "../../../input/EditableField";
import * as MATHHELPER from "../../../../helpers/MathExtension";

interface MoveComponenentPropertiesProps {
  part: PartTransformNode;
  component: MoveComponent;
  updateCurrentDevice: () => void;
}

const MoveComponenentProperties: React.FC<MoveComponenentPropertiesProps> = ({
  part,
  component,
  updateCurrentDevice,
}) => {
  const data = component.value.component_data;

  const [neutralPosition, setNeutralPosition] = useState<BABYLON.Vector3 | null>(null);
  const [limitSliderX, setLimitSliderX] = useState(0);

  const showGizmo = () => {
    setNeutralPosition(part.getAbsolutePosition().clone());

    const globalState = GlobalState.getInstance();
    globalState.onAttachGizmoRequested.emit({ node: part, gizmoType: GizmoType.Position });
    updateGizmoFromUi();
  };

  const hideGizmo = () => {
    setNeutralPosition(null);

    const globalState = GlobalState.getInstance();
    globalState.onAttachGizmoRequested.emit({ node: null, gizmoType: GizmoType.Position });
  };

  const updateGizmoFromUi = () => {
    const visualizerData: VisualizerData = {
      axis: data.axis_of_translation,
      min: data.min_translation,
      max: data.max_translation,
    };
    GlobalState.getInstance().onMoveRotatePropertyChanged.emit({ data: visualizerData, isRot: false });
  };

  const updateMinTranslation = (value: string) => {
    data.min_translation = parseFloat(value);
    updateGizmoFromUi();
    updateCurrentDevice();
  };

  const updateMaxTranslation = (value: string) => {
    data.max_translation = parseFloat(value);
    updateGizmoFromUi();
    updateCurrentDevice();
  };

  const numberToString = (value: number): string => {
    return value.toPrecision(3);
  };

  const stringToNumber = (value: string): number => {
    return parseFloat(value);
  };

  useEffect(() => {
    const onMouseMoved = (event: MouseEvent) => {
      showGizmo();
      document.removeEventListener("mousemove", onMouseMoved);
    };
    document.addEventListener("mousemove", onMouseMoved);
  }, []);

  useEffect(() => {
    return () => {
      hideGizmo();
    };
  }, []);

  useEffect(() => {
    const updateUiFrom3D = () => {
      if (!neutralPosition) {
        return;
      }

      const newPosition = part.getAbsolutePosition();
      data.axis_of_translation = MeshUtils.getMovementAxis(neutralPosition, newPosition);
      updateCurrentDevice();
      updateGizmoFromUi();
    };

    const globalState = GlobalState.getInstance();
    globalState.onGizmoUpdate.on("MoveComponentPropertyOnGizmoUpdate", updateUiFrom3D);

    return () => {
      globalState.onGizmoUpdate.off("MoveComponentPropertyOnGizmoUpdate");
    };
  }, [neutralPosition]);

  const remapLimit = (value: number): number => {
    return MATHHELPER.remap(-1, 1, data.min_translation, data.max_translation, value)
  };

  useEffect(() => {
    if (!neutralPosition) {
      return;
    }

    const newPosition = neutralPosition.add(data.axis_of_translation.scale(remapLimit(limitSliderX)));
    part.setAbsolutePosition(newPosition);
  }, [limitSliderX]);

  return (
    <div className="property-container">
      <ComponenentPropertyContainer label="Axis of Movement">
        Defines along which axis the part can be moved.
        <div className="direction-vector">
          x:
          <EditableField
          multiple={false} 
            value={numberToString(data.axis_of_translation.x)}
            reference=""
            onSave={(value) => {
              data.axis_of_translation.x = stringToNumber(value);
              updateGizmoFromUi();
              updateCurrentDevice();
            }}
          />
          y:
          <EditableField
          multiple={false} 
            value={numberToString(data.axis_of_translation.y)}
            reference=""
            onSave={(value) => {
              data.axis_of_translation.y = stringToNumber(value);
              updateGizmoFromUi();
              updateCurrentDevice();
            }}
          />
          z:
          <EditableField
          multiple={false} 
            value={numberToString(data.axis_of_translation.z)}
            reference=""
            onSave={(value) => {
              data.axis_of_translation.z = stringToNumber(value);
              updateGizmoFromUi();
              updateCurrentDevice();
            }}
          />
        </div>
      </ComponenentPropertyContainer>

      <ComponenentPropertyContainer
        label="Movement Limits"
        disabled={data.axis_of_translation === BABYLON.Vector3.Zero()}
      >
        <div className="limit-checkbox-container">
          Limit Movement
          <input
            type="checkbox"
            defaultChecked={data.is_translation_limited}
            onClick={() => {
              data.is_translation_limited = !data.is_translation_limited;
              updateCurrentDevice();
            }}
          />
        </div>

        {data.is_translation_limited && (
          <div className="limit-values">
            <div className="limit-value">
              Min
              <EditableField multiple={false} reference="" value={numberToString(data.min_translation)} onSave={updateMinTranslation} />
            </div>
            <div className="limit-value">
              {remapLimit(limitSliderX).toPrecision(3)}
              <Slider
                axis="x"
                x={limitSliderX}
                onChange={({ x }) => setLimitSliderX(x)}
                xmin={-1}
                xmax={1}
                xstep={0.01}
              />
            </div>
            <div className="limit-value">
              Max
              <EditableField multiple={false} reference="" value={numberToString(data.max_translation)} onSave={updateMaxTranslation} />
            </div>
          </div>
        )}
      </ComponenentPropertyContainer>

      <ComponenentPropertyContainer
        label="Allowed movement direction"
        disabled={data.axis_of_translation === BABYLON.Vector3.Zero()}
      >
        Can the part only be moved forward, backward or both?
        <EditableDropdownField
          value={component.value.component_data.translation_direction}
          reference=""
          options={Object.values(TranslationDirection).map((value) => ({
            value,
            label: value,
          }))}
          onSave={(newValue) => {
            component.value.component_data.translation_direction = newValue as TranslationDirection;
            updateCurrentDevice();
          }}
        />
      </ComponenentPropertyContainer>
    </div>
  );
};

export default MoveComponenentProperties;
