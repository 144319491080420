import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as STORAGE from "../helpers/StorageHelper";
import { DragData, HierarchyNode } from "../components/DraggableHierarchy/helpers/DraggableHierarchyHelper";
import * as DRAGHIERARCHYHELPER from "../components/DraggableHierarchy/helpers/DraggableHierarchyHelper";
import "./StorylineBuilder.scss";
import DraggableHierarchy from "../components/DraggableHierarchy/DraggableHierarchy";
import {
  NodeType,
  StorylineData,
} from "../components/storyline-builder/StorylineEditor/storyline-builder-steps/StorylineDataProperties";
import { getDefaultModule } from "../components/storyline-builder/StorylineEditor/storyline-builder-steps/ModuleProperties";
import { getDefaultSection } from "../components/storyline-builder/StorylineEditor/storyline-builder-steps/SectionProperties";
import { getDefaultStep } from "../components/storyline-builder/StorylineEditor/storyline-builder-steps/StepProperties";
import StorylineEditor from "../components/storyline-builder/StorylineEditor/StorylineEditor";
import StorylineHierarchyNode from "../components/storyline-builder/StorylineHierarchyNode";
import { DeviceContext } from "../App";
import { getDefaultInteraction } from "../components/storyline-builder/StorylineEditor/storyline-builder-steps/InteractionProperties";
import * as SCENEHELPER from "../components/part-editor/scene-hierarchy/SceneHierarchyHelper";

interface StorylineBuilderProps {
  devices: { [id: string]: DeviceContext };
}

const StorylineBuilder: React.FC<StorylineBuilderProps> = ({ devices }) => {
  /**
   * The currently selected node in the hierarchy.
   */
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);

  /**
   * The currently hovered node in the hierarchy.
   */
  const [hoveredNodeId, setHoveredNodeId] = useState<string | null>(null);

  /**
   * The nodes in the storyline hierarchy.
   */
  const [storylineNodes, setStorylineNodes] = useState(() => {
    // load storyline if one is saved
    const savedData = STORAGE.loadFromLocalStorage("storylineNodes");
    if (savedData) {
      return savedData;
    }

    // no storyline saved. Create a new one
    const root = DRAGHIERARCHYHELPER.createNode();
    const data: StorylineData = {
      title: "Root",
      shortName: "Root",
      type: NodeType.Root,
    };
    root.data = data;

    return { [root.id]: root };
  });

  // ====================================================================================================

  /**
   * Function to delete a node from the hierarchy.
   * @param node The node to delete.
   */
  const deleteNode = (node: HierarchyNode) => {
    if (selectedNodeIds.indexOf(node.id) !== -1) {
      setSelectedNodeIds([]); // or set to another valid id
    }

    setStorylineNodes((prevNodes: { [key: string]: HierarchyNode }) =>
      DRAGHIERARCHYHELPER.deleteNode(node.id, prevNodes)
    );
  };

  /**
   * Function to update a node in the hierarchy.
   * @param updatedNode The updated node.
   */
  const updateNode = (updatedNode: HierarchyNode) => {
    setStorylineNodes((prevNodes: { [key: string]: HierarchyNode }) => {
      return {
        ...prevNodes,
        [updatedNode.id]: updatedNode, // update the node with the new data
      };
    });
  };

  const getNewUniqueName = (prefix: string): string => {
    return SCENEHELPER.findNewUniqueName(prefix, Object.values(storylineNodes).map((node) => ((node as HierarchyNode).data as StorylineData).title));
  }

  /**
   * Functin to add a new child to a node in the hierarchy.
   * @param parentNode The parent node to add a child to.
   */
  const addChild = (parentNode: HierarchyNode) => {
    const createChildData = (parentNode: HierarchyNode): StorylineData => {
      switch ((parentNode.data as StorylineData).type) {
        case NodeType.Root:
          return getDefaultModule(getNewUniqueName("New Module"));
        case NodeType.Module:
          return getDefaultSection(getNewUniqueName("New Section"));
        case NodeType.Section:
          return getDefaultStep(getNewUniqueName("New Step"));
        case NodeType.Step:
          return getDefaultInteraction(getNewUniqueName("New Interaction"));
        default:
          throw new Error("Invalid node type");
      }
    };

    let newNode: HierarchyNode = {
      id: uuidv4(),
      parentId: parentNode.id,
      childrenIds: [],
      data: createChildData(parentNode),
      isCollapsed: false,
    };

    setStorylineNodes((prevNodes: { [key: string]: HierarchyNode }) =>
      DRAGHIERARCHYHELPER.addNode(parentNode.id, newNode, prevNodes)
    );
  };

  /**
   * Function to determine if a node can be dropped on another node.
   * @param draggedNode The node being dragged.
   * @param dragData The drag data containing the hovered node and heightpercentage.
   * @returns True if the node can be dropped on the hovered node. False otherwise.
   */
  const canDrop = (draggedNode: HierarchyNode | null, dragData: DragData): boolean => {
    const dropNode = dragData.hoveredNode;
    const heightPercentage = dragData.heightPercentage;

    if (!draggedNode || !dropNode || !heightPercentage) {
      return false;
    }

    const draggedType = (draggedNode.data as StorylineData).type;
    const dropType = (dropNode.data as StorylineData).type;

    // dropping on siblings
    if (draggedType === dropType) {
      // above or below is fine
      if (heightPercentage < 30 || heightPercentage > 70) {
        return true;
      }

      // dont allow dropping on siblings directly
      return false;
    }

    if (heightPercentage >= 30 && heightPercentage <= 70) {
      if (draggedType === NodeType.Interaction && dropType === NodeType.Step) {
        return true;
      }

      if (draggedType === NodeType.Step && dropType === NodeType.Section) {
        return true;
      }

      if (draggedType === NodeType.Section && dropType === NodeType.Module) {
        return true;
      }
    }

    return false;
  };

  // ====================================================================================================

  /**
   * Whenever the storyline nodes change, save them to local storage.
   */
  useEffect(() => {
    STORAGE.saveToLocalStorage("storylineNodes", storylineNodes);
  }, [storylineNodes]);

  // ====================================================================================================

  return (
    <div className="storyline-builder">
      <div className="storyline-builder-side-panel">
        <DraggableHierarchy
          nodes={storylineNodes}
          setNodes={setStorylineNodes}
          selectedNodeIds={selectedNodeIds}
          setSelectedNodeIds={setSelectedNodeIds}
          hoveredNodeId={hoveredNodeId}
          setHoveredNodeId={setHoveredNodeId}
          canDrop={canDrop}
          nodeContentRender={(childNode) => {
            return (
              <StorylineHierarchyNode
                node={childNode}
                /* Storyline node should not allow for multiple selected noded */
                selectedNodeIds={selectedNodeIds}
                hoveredNodeId={hoveredNodeId}
                addChild={addChild}
                deleteNode={deleteNode}
              />
            );
          }}
          allowMultiSelect={false}
        />
      </div>

      <div className="storyline-builder-main-panel">
        {selectedNodeIds.length > 0 && (
          <StorylineEditor
            node={storylineNodes[selectedNodeIds[0]]}
            nodes={storylineNodes}
            devices={devices}
            updateNode={updateNode}
          />
        )}
      </div>
    </div>
  );
};

export default StorylineBuilder;
