import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { IconPartEditor, IconStorylineBuilder } from "./icons/Icons";
import "./App.scss";
import "./DefaultStyles.scss";
import * as STORAGE from "./helpers/StorageHelper";
import StorylineBuilder from "./pages/StorylineBuilder";
import HeaderContextMenu from "./components/HeaderContextMenu";
import Playground from "./pages/Playground";
import SceneEditorPage from "./pages/SceneEditorPage";
import { Device } from "./interfaces/Device";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import MicrosoftSignIn from "./components/microsoft-sign-in/microsoft-sign-in.component";
import GlobalState from "./components/device-viewport/classes/GlobalState";

export interface DeviceContext {
  name: string;
  fileUrl: string;
  fileName: string;
  device: Device;
  isPlaceholder: boolean;
}

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);

  const [devices, setDevices] = useState<{ [id: string]: DeviceContext }>(() => {
    const savedData = STORAGE.loadFromLocalStorage("devices");
    return savedData ? savedData : {};
  });

  const [rerenderKey, setRerenderKey] = useState<boolean>(false);

  const addDevice = (id: string, deviceContext: DeviceContext) => {
    setDevices((prev) => {
      const newDevices = { ...prev };
      newDevices[id] = deviceContext;
      return newDevices;
    });
  };

  const updateDevice = (id: string, deviceContext: DeviceContext) => {
    setDevices((prev) => {
      const newDevices = { ...prev };
      newDevices[id] = deviceContext;
      return newDevices;
    });
  };

  const removeDevice = (id: string) => {
    URL.revokeObjectURL(devices[id].fileUrl);
    setDevices((prev) => {
      const newDevices = { ...prev };
      delete newDevices[id];
      return newDevices;
    });
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    STORAGE.saveToLocalStorage("devices", devices);
  }, [devices]);

  useEffect(() => {
    const globalState = GlobalState.getInstance();

    globalState.onProjectLoaded.on("AppOnProjectLoaded", () => {
      // reload devices from local storage
      setDevices(STORAGE.loadFromLocalStorage("devices"));
      setRerenderKey((prev) => !prev);
    });

    return () => {
      globalState.onProjectLoaded.off("AppOnProjectLoaded");
    };
  }, []);

  // ====================================================================================================

  return (
    <Router>
      {!user && <MicrosoftSignIn />}
      {user && (
        <header className="navigation">
          <HeaderContextMenu key={rerenderKey.toString()} /> {/* component is re-rendered when key changes */}
          <nav>
            <ul className="navigation-ul">
              <li className="navigation-li">
                <Link to="/part-editor">
                  <IconPartEditor fill="var(--light-primary-1-5)" />
                  Part Editor
                </Link>
              </li>
              <li className="navigation-li">
                <Link to="/storyline-builder">
                  <IconStorylineBuilder fill="var(--light-primary-1-5)" />
                  Storyline Builder
                </Link>
              </li>
            </ul>
          </nav>
        </header>
      )}

      {user && (
        <Routes>
          <Route
            path="*"
            element={
              <SceneEditorPage
                devices={devices}
                addDevice={addDevice}
                updateDevice={updateDevice}
                removeDevice={removeDevice}
              />
            }
          />
          <Route
            path="/part-editor"
            element={
              <SceneEditorPage
                devices={devices}
                addDevice={addDevice}
                updateDevice={updateDevice}
                removeDevice={removeDevice}
              />
            }
          />
          <Route path="/storyline-builder" element={<StorylineBuilder devices={devices} />} />
          <Route path="/playground" element={<Playground />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
