import React, { useState } from "react";
import "./ProjectConfiguration.scss";
import ReactDOM from "react-dom";
import { saveToLocalStorage, loadFromLocalStorage } from "../helpers/StorageHelper";
import * as PROJECTTEMPLATEHELPER from "../helpers/ProjectTemplateHelper";

// TODO: fetch from file
enum ProductType {
  LINEAR = "Linear Training",
}

interface ProjectConfigurationProps {
  onConfirm: () => void;
}

const portalRoot = document.getElementById("root");

const ProjectConfiguration: React.FC<ProjectConfigurationProps> = ({ onConfirm }) => {
  const [selectedProjectType, setSelectedProjectType] = useState<ProductType>(() => {
    return loadFromLocalStorage("product") || ProductType.LINEAR;
  });
  const [selectedCompany, setSelectedCompany] = useState<PROJECTTEMPLATEHELPER.CompanyKey>(() => {
    return loadFromLocalStorage("company") || PROJECTTEMPLATEHELPER.AllCompanies()[25]; // default: RW1
  });
  const [description, setDescription] = useState<string>(() => {
    return loadFromLocalStorage("description") || "Default Description";
  });
  const [environment, setEnvironment] = useState<string>(() => {
    return loadFromLocalStorage("environment_import") || "Default Environment";
  });

  return (
    portalRoot &&
    ReactDOM.createPortal(
      <div className="config-dialog-backdrop">
        <div className="config-dialog-overlay">
          <h1>Project Configuration</h1>
          <p>
            Customize your project settings, select the product type, associate it with the desired company, and more.
          </p>

          <div className="config-container">
            <div className="config-item">
              <label>Product Type</label>
              <select
                className="config-input"
                value={selectedProjectType}
                onChange={(e) => setSelectedProjectType(e.target.value as ProductType)}
              >
                {Object.values(ProductType).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            <div className="config-item">
              <label>Company</label>
              <select
                className="config-input"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value as PROJECTTEMPLATEHELPER.CompanyKey)}
              >
                {(PROJECTTEMPLATEHELPER.AllCompanies()).map((company) => (
                  <option key={company} value={company}>
                    {company}
                  </option>
                ))}
              </select>
            </div>

            <div className="config-item">
              <label>Description</label>
              <input
                type="text"
                className="config-input"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="config-item">
              <label>Environment</label>
              <input
                type="text"
                className="config-input"
                value={environment}
                onChange={(e) => setEnvironment(e.target.value)}
              />
            </div>
          </div>

          <div className="config-dialog-buttons">
            <button
              className="primary"
              onClick={() => {
                saveToLocalStorage("product", selectedProjectType);
                saveToLocalStorage("company", selectedCompany);
                saveToLocalStorage("description", description);
                saveToLocalStorage("environment_import", environment);

                onConfirm();
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>,
      portalRoot
    )
  );
};

export default ProjectConfiguration;
