import * as BABYLON from '@babylonjs/core';
import Entity from '../../babylon/classes/Entity';
import GlobalState from "./GlobalState";
import MeshUtils from './MeshUtils';

export default class CameraController extends Entity{
    private mCanvas: HTMLCanvasElement;

    private mCamera!: BABYLON.ArcRotateCamera;

    private mTargetSize: number = 0;
    private mTargetPosition: BABYLON.Vector3 = BABYLON.Vector3.Zero();

    constructor(canvas: HTMLCanvasElement) {
        super('CameraController');
        this.mCanvas = canvas;
        this._isUpdateEnabled = true;
    }

    begin() {
        super.begin();
        const globalState = GlobalState.getInstance();

        this.createDefaultCamera();
        this.mCamera.attachControl(this.mCanvas, true);

        this.mCanvas.onwheel = (e) => {
            e.preventDefault();
            // const factor = e.deltaY < 0 ? 0.8 : 1.25;
        };

        globalState.onViewportResized.on('CameraControllerOnMeshLoaded', ()=>{
            this.createDefaultCamera();
            //this.focusOnParts([node])
        });

        globalState.onPartSelected.on('CameraControllerOnPartSelected', (parts: BABYLON.TransformNode[])=>{
            this.focusOnParts(parts);
        });
    }

    update(delta: number): void {
        super.update(delta);
        const distance = this.mCamera.radius;
        this.mCamera.panningSensibility = (this.mTargetSize * 5000)/distance;
    }

    createDefaultCamera() {
        this.getScene().createDefaultCameraOrLight(true, true, true);
        this.mCamera = this.getScene().activeCamera as BABYLON.ArcRotateCamera;
        this.mCamera.alpha = 0.8;
        this.mCamera.beta = 1.3;
        this.mTargetSize = this.mCamera.radius;
        this.mTargetPosition = this.mCamera.target;
    }

    focusOnParts(parts: BABYLON.TransformNode[]){

        let target = this.mTargetPosition;
        let radius = this.mTargetSize;

        if(parts.length > 0){
            const boudingInfo =  MeshUtils.getMeshSizeAndCenter(parts);
            const endPosition = boudingInfo.center;
            target = endPosition;
            radius =  boudingInfo.radius * 5;
        }
        let alpha = 0.8;
        let beta = 1.3;

        this.animateCameraToTarget({
            target,
            radius,
            alpha,
            beta
        }, 0.5);
        
    }

    animateCameraToTarget(focusParams: { target: BABYLON.Vector3, radius: number, alpha: number, beta: number }, animTimeSeconds: number = 1) {
        const { target, radius, alpha, beta } = focusParams;
        const framRate = 60;
        const totalFrames =  animTimeSeconds * framRate;
    
        const easingFunction = new BABYLON.ExponentialEase();
        easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    
        const animations = [];
    
        // Target animation
        const targetAnimation = new BABYLON.Animation(
            "targetAnimation",
            "target",
            60,
            BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        targetAnimation.setKeys([
            { frame: 0, value: this.mCamera.target },
            { frame: totalFrames, value: target }
        ]);
        targetAnimation.setEasingFunction(easingFunction);
        animations.push(targetAnimation);
    
        // Radius animation
        const radiusAnimation = new BABYLON.Animation(
            "radiusAnimation",
            "radius",
            60,
            BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        radiusAnimation.setKeys([
            { frame: 0, value: this.mCamera.radius },
            { frame: totalFrames, value: radius }
        ]);
        radiusAnimation.setEasingFunction(easingFunction);
        animations.push(radiusAnimation);
    
        // Begin animation
        this.getScene().beginDirectAnimation(this.mCamera, animations, 0, totalFrames, false);
    }

    dispose(): void {
        super.dispose();
        const globalState = GlobalState.getInstance();
        globalState.onViewportResized.off('CameraControllerOnMeshLoaded');
        globalState.onPartSelected.off('CameraControllerOnPartSelected');
    }
}