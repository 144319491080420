import Entity from "../../babylon/classes/Entity";
import * as BABYLON from '@babylonjs/core';
import GlobalState from "./GlobalState";

export default class SelectionController extends Entity {
    private mCanvas: HTMLCanvasElement;

    private mHighlightLayer!: BABYLON.HighlightLayer;

    private mGlobalState: GlobalState;

    private mSelectedParts: BABYLON.TransformNode[] = [];

    private mMouseMovementThreshold: number;

    private mMouseStartPosition!: BABYLON.Vector2;

    private mMouseEndPosition!: BABYLON.Vector2;

    private mIsMouseDown!: boolean;

    constructor(canvas: HTMLCanvasElement) {
        super('SelectionManager');
        this.mCanvas = canvas;
        this.mGlobalState = GlobalState.getInstance();
        this.mMouseMovementThreshold = 5;
        this.mMouseStartPosition = BABYLON.Vector2.Zero();
        this.mMouseEndPosition = BABYLON.Vector2.Zero();
    }

    begin(): void {
        super.begin();
        this.mHighlightLayer = new BABYLON.HighlightLayer('Highlight', this.getScene());

        this.mGlobalState.highlightLayer = this.mHighlightLayer;

        this.getScene().onPointerObservable.add((pointerInfo) => {
            if (pointerInfo.event instanceof PointerEvent) {
                switch (pointerInfo.type) {
                    case BABYLON.PointerEventTypes.POINTERDOWN:
                        this.onPointerDown(pointerInfo.event);
                        break;
                    case BABYLON.PointerEventTypes.POINTERUP:
                        this.onPointerUp(pointerInfo.event);
                        break;
                    case BABYLON.PointerEventTypes.POINTERMOVE:
                        this.onPointerMove(pointerInfo.event);
                        break;
                }
            }
        });

        this.mGlobalState.onUIPartsSelected.on('SelectionControllerOnUIPartSelected', (parts) => {
            this.mSelectedParts = parts;
            this.updateHighlightLayer();
            this.mGlobalState.onPartSelected.emit(this.mSelectedParts);
        });
    }

    private onPointerDown(evt: PointerEvent) {
        this.mIsMouseDown = true;
        this.mMouseStartPosition.copyFromFloats(evt.clientX, evt.clientY);
    }

    private onPointerMove(evt: PointerEvent) {
        if (this.mIsMouseDown) {
            // Update the end position as the mouse moves
            this.mMouseEndPosition.copyFromFloats(evt.clientX, evt.clientY);
        }
    }

    private onPointerUp(evt: PointerEvent) {
        if (this.mIsMouseDown) {
            this.mIsMouseDown = false;
            this.mMouseEndPosition.copyFromFloats(evt.clientX, evt.clientY);

            const isShiftPressed = evt.shiftKey;

            // Calculate the distance the mouse moved
            let distance = BABYLON.Vector2.Distance(this.mMouseStartPosition, this.mMouseEndPosition);

            // If the distance moved is less than the threshold, trigger the event
            if (distance < this.mMouseMovementThreshold) {

                this.pickAndHighlight(isShiftPressed);
            }
        }
    }


    private pickAndHighlight(isMultiSelect: boolean) {
        const hitResult = this.getScene().pick(this.getScene().pointerX, this.getScene().pointerY);
    
        if (hitResult.hit) {
            const rootNode = this.getScene().getNodeByName("__root__");
    
            if (rootNode && hitResult.pickedMesh && hitResult.pickedMesh.isDescendantOf(rootNode) && hitResult.pickedMesh instanceof BABYLON.Mesh) {
                if (isMultiSelect) {
                    const index = this.mSelectedParts.indexOf(hitResult.pickedMesh);
                    if (index === -1) {
                        this.mSelectedParts.push(hitResult.pickedMesh);
                    } else {
                        this.mSelectedParts.splice(index, 1);
                    }
                } else {
                    this.mSelectedParts = [hitResult.pickedMesh];
                }

                this.mGlobalState.onPartSelected.emit(this.mSelectedParts);
                this.updateHighlightLayer();
            }
        } else {
            this.mSelectedParts = [];
            this.mGlobalState.onPartSelected.emit(this.mSelectedParts);
            this.updateHighlightLayer();
        }
    }

    private updateHighlightLayer() {
        this.mHighlightLayer.removeAllMeshes();
        this.mSelectedParts.forEach(mesh => {
            if (mesh instanceof BABYLON.Mesh) {
                this.mHighlightLayer.addMesh(mesh, BABYLON.Color3.FromHexString('#128bff'));
            }
        });
    }

    public static setupMesheOverlay(nodes: BABYLON.TransformNode[], shouldOverlay: boolean = true){
        nodes.forEach(node => {
            if (node instanceof BABYLON.AbstractMesh) {
                node.renderOverlay = shouldOverlay;
            }
        }); 
    }

    dispose(): void {
        super.dispose();
        this.mGlobalState.onUIPartsSelected.off('SelectionControllerOnUIPartSelected')
    }

}