import JSZip from "jszip";
import * as JSONHELPER from "./JsonExportHelper";
import * as STORAGE from "../StorageHelper";
import { DeviceContext } from "../../App";
import GlobalState from "../../components/device-viewport/classes/GlobalState";
import * as BABYLON from "@babylonjs/core";

export function saveProject() {
  const save: { [key: string]: any } = {};
  Object.keys(window.localStorage).forEach((key) => {
    save[key] = STORAGE.loadFromLocalStorage(key);
  });

  const zip = new JSZip();
  zip.file("localStorage.json", JSON.stringify(save));

  const devices = STORAGE.loadFromLocalStorage("devices") as { [id: string]: DeviceContext };
  Promise.all(addDevicesToZip(zip, devices))
    .then(() => zip.generateAsync({ type: "blob" }))
    .then((content) => download(content, "ctproj"));
}

const readLocalStorageJson = (zip: JSZip): Promise<void> => {
  const localStorageFile = zip.file("localStorage.json");
  if (!localStorageFile) {
    throw "localStorage.json file not found";
  }

  return localStorageFile.async("string").then((localStorageJson) => {
    const localStorage = JSON.parse(localStorageJson);

    Object.keys(localStorage).forEach((key) => {
      STORAGE.saveToLocalStorage(key, localStorage[key]);
    });
  });
};

const readModelFiles = (zip: JSZip) => {
  // get a list of all files in the models folder within the zip
  const devices = STORAGE.loadFromLocalStorage("devices");

  const relevantFiles = Object.keys(zip.files).filter(fileName => 
    fileName.startsWith("models/") && fileName.endsWith(".glb")
  );

  const promises = relevantFiles.map((fileName) => {
    const file = zip.files[fileName];
    return file.async("blob").then((blob) => {
      const deviceUrl = URL.createObjectURL(blob);
      const deviceName = fileName.split(".")[0].split("/")[1];

      // find the deviceContext with the same name
      let deviceId: string | undefined;
      let deviceContext: DeviceContext | undefined;
      Object.keys(devices).forEach((id) => {
        const dc = devices[id];
        if (dc.name.replaceAll(' ', '') === deviceName) {
          deviceId = id;
          deviceContext = dc;
        }
      });

      if (deviceContext === undefined) {
        throw `deviceContext not found for ${deviceName}`;
      }

      deviceContext.fileUrl = deviceUrl;
    }).catch((error) => {
      throw error;
    });
  });

  Promise.all(promises).then(() => {
    // get first device id
    const firstId = Object.keys(devices)[0];

    STORAGE.saveToLocalStorage("devices", devices);
    STORAGE.saveToLocalStorage("active_device_id", firstId);

    GlobalState.getInstance().onProjectLoaded.emit(null);
  }).catch((error) => {
    throw error;
  });
};

export function openProject(file: any) {
  // clear all blobs from memory
  const devices = STORAGE.loadFromLocalStorage("devices") as { [id: string]: DeviceContext };
  Object.keys(devices).forEach((id: string) => {
    const context = devices[id];
    URL.revokeObjectURL(context.fileUrl);
  });

  // clear localStorage
  window.localStorage.clear();

  // read the file and open as a zip
  const reader = new FileReader();

  reader.onload = (event) => {
    const arrayBuffer = event.target?.result;
    if (!arrayBuffer) {
      return;
    }

    const zip = new JSZip();
    zip.loadAsync(arrayBuffer).then((zip) => {
      // read the localStorage.json file
      readLocalStorageJson(zip).then(() => {        
        // read the other files
        readModelFiles(zip);
      });
    });
  };

  reader.readAsArrayBuffer(file);
}

const addDevicesToZip = (zip: JSZip, devices: { [id: string]: DeviceContext }) => {
  return Object.keys(devices).map((id) => {
    const device = devices[id];
    return fetch(device.fileUrl)
      .then((res) => res.blob())
      .then((blob) => {
        zip.file(`models/${device.name.replaceAll(' ', '')}.glb`, blob);
      });
  });
};

const download = (content: any, fileEnding: string) => {
  const data = new Blob([content]);
  const csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  const fileName = window.localStorage.getItem("projectName");
  tempLink.setAttribute("download", `${fileName ? fileName.replaceAll('"', '') : "untitled_project"}.${fileEnding}`);
  tempLink.click();
};

export function exportProject() {
  const zip = new JSZip();

  // zip the ue import json declaring the project
  zip.file("ue_import.json", JSON.stringify(JSONHELPER.createJSON()));

  const devices = STORAGE.loadFromLocalStorage("devices") as { [id: string]: DeviceContext };

  // add models to zip
  Promise.all(addDevicesToZip(zip, devices))
    .then(() => zip.generateAsync({ type: "blob" }))
    .then((content) => download(content, "ctexport"));

  return null;
}
