import React, { useEffect } from "react";
import * as HIERARCHYHELPER from "./helpers/DraggableHierarchyHelper";
import { IconArrowDown, IconArrowRight, IconDot } from "../../icons/Icons";
import "./HierarchyEntry.scss";

interface HierarchyEntryProps {
  node: HIERARCHYHELPER.HierarchyNode;
  setNodes: (nodes: { [id: string]: HIERARCHYHELPER.HierarchyNode }) => void;
  selectedNodeIds: string[];
  setSelectedNodeIds: (nodeId: string[]) => void;
  hoveredNodeId: string | null;
  setHoveredNodeId: (nodeId: string | null) => void;
  nodes: { [id: string]: HIERARCHYHELPER.HierarchyNode };
  draggedNode: HIERARCHYHELPER.HierarchyNode | null;
  dragData: HIERARCHYHELPER.DragData;
  handleNodeMouseDown: (event: React.MouseEvent, node: HIERARCHYHELPER.HierarchyNode) => void;
  handleNodeMouseMove: (event: React.MouseEvent, node: HIERARCHYHELPER.HierarchyNode) => void;
  canDrop: (draggedNode: HIERARCHYHELPER.HierarchyNode | null, hoverData: HIERARCHYHELPER.DragData) => boolean;
  nodeContentRender?: (node: HIERARCHYHELPER.HierarchyNode) => JSX.Element;
  leafIconOverride?: (node: HIERARCHYHELPER.HierarchyNode) => JSX.Element;
  allowMultiSelect: boolean;
}

const HierarchyEntry: React.FC<HierarchyEntryProps> = ({
  node,
  setNodes,
  selectedNodeIds,
  setSelectedNodeIds,
  hoveredNodeId,
  setHoveredNodeId,
  nodes,
  draggedNode,
  dragData,
  handleNodeMouseDown,
  handleNodeMouseMove,
  canDrop,
  nodeContentRender,
  leafIconOverride,
  allowMultiSelect,
}) => {
  const [isHoldingShift, setIsHoldingShift] = React.useState<boolean>(false);

  const handleNodeClick = (node: HIERARCHYHELPER.HierarchyNode) => {
    //const successors = HIERARCHYHELPER.getSuccessors(node, nodes).map((node) => node.id);
    const alreadySelected = selectedNodeIds.indexOf(node.id) !== -1;
    const nodeId = node.id;

    if (!isHoldingShift) {
      if (alreadySelected) {
        setSelectedNodeIds([]);
      } else {
        // setSelectedNodeIds(successors);
        setSelectedNodeIds([nodeId]);
      }
      return;
    }

    if (alreadySelected) {
      // deselect all nodes that are selected and are children of the clicked node
      //const newSelectedNodeIds = selectedNodeIds.filter((id) => !successors.includes(id));
      const newSelectedNodeIds = selectedNodeIds.filter((id) => id !== nodeId);
      setSelectedNodeIds(newSelectedNodeIds);
    } else {
      // only add ids that are not already selected
      //const newSuccessors = successors.filter((id) => !selectedNodeIds.includes(id));
      //setSelectedNodeIds([...selectedNodeIds, ...newSuccessors]);
      setSelectedNodeIds([...selectedNodeIds, nodeId]);
    }
  };

  const handleCollapseExpand = (node: HIERARCHYHELPER.HierarchyNode) => {
    node.isCollapsed = !node.isCollapsed;
    setNodes({ ...nodes, [node.id]: node });
  };

  const canDropInternal = () => {
    // cannot drop a node into one of its children
    if (draggedNode?.childrenIds.includes(node.id)) {
      return false;
    }

    return canDrop(draggedNode, dragData);
  };

  const evaluateDragStyle = () => {
    if (
      !dragData ||
      !dragData.heightPercentage ||
      dragData.hoveredNode?.id !== node.id ||
      draggedNode?.id === node.id
    ) {
      if (draggedNode?.id === node.id) return { opacity: 0.25 };
      return {};
    }

    if (dragData.heightPercentage > 70) {
      return {
        borderBottom: `2px solid ${canDropInternal() ? "var(--light-primary-1-5)" : "red"}`,
      };
    }

    if (dragData.heightPercentage < 30) {
      return {
        borderTop: `2px solid ${canDropInternal() ? "var(--light-primary-1-5)" : "red"}`,
      };
    }

    return {
      border: `2px solid ${canDropInternal() ? "var(--light-primary-1-5)" : "red"}`,
    };
  };

  /**
   * Handle whenever the user presses the shift key.
   */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsHoldingShift(allowMultiSelect);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsHoldingShift(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <div className="hierarchy-entry"
      style={{
        ...(selectedNodeIds.indexOf(node.id) !== -1 ? { backgroundColor: "#DEEFFF" } : {}),
      }}
    >
      <div>
        <div
          className="entry-node"
          onMouseEnter={() => setHoveredNodeId(node.id)}
          onMouseLeave={() => setHoveredNodeId(null)}
          onMouseDown={(event) => handleNodeMouseDown(event, node)}
          onMouseMove={(event) => handleNodeMouseMove(event, node)}
          style={{
            ...evaluateDragStyle(),
            ...(hoveredNodeId === node.id ? { backgroundColor: "var(--light-secondary-l-1)" } : {}),
          }}
          onClick={(event) => {
            handleNodeClick(node);
            event.stopPropagation();
          }}
        >
          <div className="icon">
            {node.childrenIds.length === 0 ? (
              leafIconOverride ? (
                leafIconOverride(node)
              ) : (
                <IconDot fill="var(--light-text-light-theme-0)" />
              )
            ) : (
              <button
                onClick={(event) => {
                  handleCollapseExpand(node);
                  event.stopPropagation();
                }}
              >
                {!node.isCollapsed ? (
                  <IconArrowDown fill="var(--light-text-light-theme-0)" />
                ) : (
                  <IconArrowRight fill="var(--light-text-light-theme-0)" />
                )}
              </button>
            )}
          </div>

          {nodeContentRender ? nodeContentRender(node) : node.id}
        </div>
      </div>

      {!node.isCollapsed && (
        <div className="children">
          {node.childrenIds.map((childNodeId) => (
            <HierarchyEntry
              key={childNodeId}
              node={nodes[childNodeId]}
              setNodes={setNodes}
              selectedNodeIds={selectedNodeIds}
              setSelectedNodeIds={setSelectedNodeIds}
              setHoveredNodeId={setHoveredNodeId}
              hoveredNodeId={hoveredNodeId}
              nodes={nodes}
              draggedNode={draggedNode}
              dragData={dragData}
              handleNodeMouseDown={handleNodeMouseDown}
              handleNodeMouseMove={handleNodeMouseMove}
              canDrop={canDrop}
              nodeContentRender={nodeContentRender}
              leafIconOverride={leafIconOverride}
              allowMultiSelect={allowMultiSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default HierarchyEntry;
