import * as BABYLON from "@babylonjs/core";
import Entity from "../../babylon/classes/Entity";
import GlobalState from "./GlobalState";


export default class MeshGhost extends Entity {

    private mGhostMaterial!: BABYLON.StandardMaterial;
    private mGhostTarget: BABYLON.TransformNode[] = [];
    private mGhostParts: BABYLON.TransformNode[] = [];

    constructor(ghostTarget: BABYLON.TransformNode[]) {
        super('MeshGhostManager')
        this.mGhostTarget = ghostTarget;
    }

    begin(): void {
        const globalState =  GlobalState.getInstance();
        const highlightLayer = globalState.highlightLayer;

        this.mGhostMaterial = new BABYLON.StandardMaterial('ghost_material', this.getScene());
        this.mGhostMaterial.alpha = 0.5;
        this.mGhostMaterial.diffuseColor = new BABYLON.Color3(0.5, 0.5, 1); // Adjust RGB for desired blue tint
        this.mGhostMaterial.specularColor = new BABYLON.Color3(0.5, 0.5, 1);
        this.mGhostMaterial.emissiveColor = new BABYLON.Color3(0.5, 0.5, 2);
        this.mGhostMaterial.disableDepthWrite = true;

        if (this.mGhostTarget.length > 0) {
            this.mGhostTarget.forEach((part) => {
                
                const ghost = part.clone(`${part.name}_ghost_mesh`, null, true)

                if (ghost) {
                    ghost.setAbsolutePosition(part.getAbsolutePosition());
                    ghost.rotationQuaternion = part.rotationQuaternion?.clone() as BABYLON.Quaternion;           

                    if (ghost instanceof BABYLON.AbstractMesh) {
                        ghost.material = this.mGhostMaterial
                        ghost.isPickable = false;
                        ghost.setParent(null);
                        highlightLayer.addExcludedMesh(ghost as BABYLON.Mesh);
                    }

                    this.mGhostParts.push(ghost);    

                } else {
                    throw new Error("Failed to clone mesh");
                }
            })
        }
    }

    update(delta: number): void {

    }

    cleanUpGhost(): void {
        this.mGhostParts.forEach((part) => {
            part.dispose();
        })

        if (this.mGhostMaterial) {
            this.mGhostMaterial.dispose();
        }   
    }
}