import * as BABYLON from '@babylonjs/core';

export default class MeshUtils {

    static getMeshSizeAndCenter(inspectedMeshes: BABYLON.TransformNode[]) {
        /**
         * @IgorAPM
         * Calculates the center of mass and bound size of a mesh given their root
         */

        let childMeshes: BABYLON.AbstractMesh[] = [];

        inspectedMeshes.forEach((node: BABYLON.TransformNode) => {
            if(node instanceof BABYLON.Mesh && !childMeshes.includes(node)){
                childMeshes.push(node);
            }
            childMeshes.push(...node.getChildMeshes());
        })

        let center = new BABYLON.Vector3();

        let meshSize = new BABYLON.Vector3(50, 50, 50);
        let min = new BABYLON.Vector3(0, 0, 0);
        let max = new BABYLON.Vector3(0, 0, 0);
        // console.log(childMeshes);
        if (childMeshes && childMeshes.length > 0) {
            min = childMeshes[0].getBoundingInfo().boundingBox.minimumWorld;
            max = childMeshes[0].getBoundingInfo().boundingBox.maximumWorld;

            for (let i = 0; i < childMeshes.length; i++) {
                const meshMin = childMeshes[i].getBoundingInfo().boundingBox.minimumWorld;
                const meshMax = childMeshes[i].getBoundingInfo().boundingBox.maximumWorld;

                min = BABYLON.Vector3.Minimize(min, meshMin);
                max = BABYLON.Vector3.Maximize(max, meshMax);
            }

            meshSize = new BABYLON.Vector3(max.x - min.x, max.y - min.y, max.z - min.z);
            center = min.add(meshSize.clone().divide(new BABYLON.Vector3(2, 2, 2)));
        }
        // console.log({ meshSize, center });

        const diagonal =  max.clone().subtract(min.clone());
        const radius = diagonal.length() * 0.5;

        return {
            meshSize, radius, center, min, max,
        };
    }

    static hasParentOfID(node: BABYLON.Nullable<BABYLON.Node>, targetNodeId: string) {
        while (node) {
            if (node.id === targetNodeId) {
                return true;
            }
            node = node.parent;
        }
        return false;
    }

    static getMovementAxis(start: BABYLON.Vector3, end: BABYLON.Vector3): BABYLON.Vector3 {
        return end.subtract(start).normalize();
    }

    static getRotationAxis(startRotation: BABYLON.Vector3, endRotation: BABYLON.Vector3): BABYLON.Vector3 {
        // Calculate the relative rotation (delta) from startRotation to endRotation
        const startQuat = startRotation.toQuaternion();
        const endQuat = endRotation.toQuaternion();
        
        const deltaRotation = startQuat.invert().multiply(endQuat);
    
        // Normalize the delta quaternion
        deltaRotation.normalize();
    
        // Extract the rotation axis
        return deltaRotation.toEulerAngles().normalize();
    }

    static generateFullPathID(node: BABYLON.Node, highestParent: BABYLON.Nullable<BABYLON.TransformNode>): string {
        let current: BABYLON.Node = node;
        let path = this.sanitizeNodeName(current.name);
    
        while (current.parent && current.parent !== highestParent) {
            current = current.parent;
            path = this.sanitizeNodeName(current.name) + '/' + path; // Prepend parent name
        }
    
        return path;
    }

    static sanitizeNodeName(name: string): string {
        // Normalize and remove accents/diacritics
        let sanitized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
        // Replace problematic characters with underscores or similar
        // Adjust the regex according to your needs
        sanitized = sanitized.replace(/[\/\\: *?"<>|]/g, '_');
    
        return sanitized;
    }
}